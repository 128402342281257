import { Autocomplete, Box, Button, CircularProgress, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, tableCellClasses } from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import styled from "@emotion/styled";
import { transactions_method_en, transactions_method_fr } from "../../components/data/transactionsColumns";
import { useEffect, useState } from "react";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ContentBox from "../../components/ui/ContentBox";
import ButtonSquare from "../../components/ui/ButtonSquare";
import StatusComponent from "../../components/ui/StatusComponent";
import moment from "moment";
import TableShowUser from "../../components/ui/TableShowUser";
import { modalStyleOverflow } from "../../theme/ModalStyle";
const Transactions = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      paddingTop: "8px",
      paddingBottom: "8px",
      letterSpacing: "1px",
      color: `rgba(75,70,92,0.8)`,
      fontSize: 15,
      fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
      background: "white",
      fontSize: 15,
      color: `rgba(75,70,92,0.8)`,
      fontWeight: "500",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    ":hover": {
      filter: "brightness(0.95)",
    },
    cursor: "pointer",
  }));

  const StyledTable = styled(Table)({
    borderRadius: "6px",
  });
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const lang = localStorage.getItem("i18nextLng");
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const [openDetails, setOpenDetails] = useState({ open: false, data: null });
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState(lang === "fr" ? transactions_method_fr : transactions_method_en);
  const [transactions, setTransactions] = useState([]);
  const { t } = useTranslation();
  const pageSize = 30;
  //get transactions
  const [pagesList, setPagesList] = useState([]);

  useEffect(() => {
    Api.get(Urls.GET_ALL_TRANSACTIONS, {
      headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        page: page,
        perPage: pageSize,
      },
    })
      .then((res) => {
        setTransactions(res?.data?.data);
        setData(res?.data);
        if (pagesList?.length === 0) {
          let result = res?.data?.pagination?.total / pageSize;
          let array = [];
          if (result <= 1) {
            setPagesList([1]);
          } else {
            if (Number?.isInteger(result)) {
              for (let index = 1; index <= result; index++) {
                array.push(index);
              }
            } else {
              for (let index = 0; index <= result; index++) {
                array.push(index + 1);
              }
            }
            setPagesList(array);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response.data?.message);
        setLoading(false);
      });
  }, [page]);

  //handlers
  const handleCloseDetails = () => {
    setOpenDetails({ open: false, data: null });
  };
  const handleClickRow = (data) => {
    setOpenDetails({ open: true, data: data });
  };
  return (
    <Box>
      <ToastContainer />
      <Box sx={{ maxWidth: "100%" }} display="flex" flexDirection="column" gap="24px">
        <Box>
          <Typography variant="text_md" color="rgba(0,0,0,0.5)">
            {t("sharedLayout.navigations.transactions")}
          </Typography>
        </Box>
        <Box>
          <ContentBox>
            <Box display="flex" flexDirection="column" gap="16px">
              <Box>
                <Typography variant="text_18" color="rgba(75, 70, 92 , 0.9)">
                  {t("pages.titles.list_transactions")}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="flex-end" flexWrap="wrap" gap="16px"></Box>
              {loading && (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              )}
              {!loading && (
                <Box sx={{ overflow: "hidden", maxWidth: "100%" }}>
                  <TableContainer sx={{ borderRadius: "6px", border: `1px solid #DBDADE` }}>
                    <StyledTable aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          {columns.map((col, index) => (
                            <StyledTableCell key={index} width={col.width} align={col.align}>
                              {col.name}
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {transactions?.map((doc) => (
                          <StyledTableRow onClick={() => handleClickRow(doc)} key={doc?.id}>
                            <StyledTableCell align="left">
                              <Typography fontWeight="400" color="#3890E2" fontSize="15px">
                                #{doc?.id}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <TableShowUser full_name={doc?.user?.first_name + " " + doc?.user?.last_name} url_photo={doc?.user?.file_url} />
                            </StyledTableCell>
                            <StyledTableCell align="left">{doc?.subject_display}</StyledTableCell>

                            <StyledTableCell align="left">{doc?.type}</StyledTableCell>
                            <StyledTableCell align="left">{doc?.amount}</StyledTableCell>

                            <StyledTableCell align="center">
                              <StatusComponent text={doc?.status_display} name={doc?.status} />
                            </StyledTableCell>
                            <StyledTableCell align="center">{moment(doc?.created_at).format("DD MMMM yyyy HH:mm")}</StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </StyledTable>
                  </TableContainer>
                </Box>
              )}
              {transactions?.length === 0 && !loading && <Typography textAlign="center">{t("pages.errors.no_transactions")}</Typography>}
              {transactions.length > 0 && (
                <Box justifyContent="flex-end" display="flex" gap="4px">
                  {page > 1 && (
                    <ButtonSquare
                      onClick={() => {
                        setPage(page - 1);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      action="Previous"
                    />
                  )}
                  <Box display="flex" gap="4px" justifyContent="center">
                    {pagesList.map((p) => (
                      <ButtonSquare
                        onClick={() => {
                          setPage(p);
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                        action={p === page ? "number-active" : "number"}
                        number={p}
                      />
                    ))}
                  </Box>
                  {data?.pagination?.total - pageSize * page > 0 && (
                    <ButtonSquare
                      onClick={() => {
                        setPage(page + 1);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      action="Next"
                    />
                  )}
                </Box>
              )}
            </Box>
          </ContentBox>
          <Modal open={openDetails?.open} onClose={handleCloseDetails}>
            <Box sx={modalStyleOverflow}>
              <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                <Typography color="#000000" fontSize="24px" fontWeight="600">
                  {t("pages.titles.transaction_details")}
                </Typography>
                <Box onClick={handleCloseDetails}>
                  <ButtonSquare action="close" />
                </Box>
              </Box>
              <Box>{JSON.stringify(openDetails?.data?.details, null, 2)}</Box>
            </Box>
          </Modal>
        </Box>
      </Box>
    </Box>
  );
};
export default Transactions;
