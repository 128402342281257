import { Avatar, Box, Button, IconButton, Modal, Rating, Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import Api from "../../api/Api.js";
import { Urls } from "../../api/Urls.js";
import flightIcon from "../../imgs/iconFlight.svg";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { modalStyleOverflowDashboard } from "../../theme/ModalStyle.js";
import CardShippingComponent from "../../components/cards/CardShippingComponent.js";
import moment from "moment/moment";
import CircularProgress from "@mui/material/CircularProgress";
import { faClose } from "@fortawesome/free-solid-svg-icons/faClose";
import { colors } from "../../theme/colors.js";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import noPackagesIcon from "../../imgs/NoPackagesIcon.png";
import VerifiedIcon from "@mui/icons-material/Verified";
import ButtonSquare from "../../components/ui/ButtonSquare.js";
import ImagesCarousel from "../../components/imagesCarousel/ImagesCarousel.js";
import { useTranslation } from "react-i18next";
import { showDate } from "../../utils/utils.js";
import { useAuthContext } from "../../hooks/useAuthContext.js";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ContentBox from "../../components/ui/ContentBox.js";

function Shippings({ dashboard, reception_request_id, trip_id }) {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [pagesList, setPagesList] = useState([]);
  const pageSize = 5;
  const { t } = useTranslation();
  const [openViewImages, setOpenViewImages] = useState({
    open: false,
    selectedImage: null,
    images: null,
  });
  const [openDetails, setOpenDetails] = useState({
    open: false,
    data: null,
  });

  const [loadingDetails, setLoadingDetails] = useState(false);
  const [shippings, setShippings] = useState([]);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    setLoading(true);
    console.log(access_token);

    Api.get(trip_id ? Urls.GetShippingsByTripID(trip_id) : reception_request_id ? Urls.GetShippingsByReceptionReqID(reception_request_id) : Urls?.GetShipping, {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${access_token}`,
        "accept-language": localStorage.getItem("i18nextLng"),
      },
      params: {
        pageSize: dashboard ? 5 : pageSize,
        page: page,
      },
    })
      .then((res) => {
        setShippings(() => {
          return res?.data?.data;
        });
        setData(res?.data);
        if (pagesList?.length === 0) {
          let result = res?.data?.pagination?.total / pageSize;
          let array = [];
          if (result <= 1) {
            setPagesList([1]);
          } else {
            if (Number?.isInteger(result)) {
              for (let index = 1; index <= result; index++) {
                array.push(index);
              }
            } else {
              for (let index = 0; index <= result; index++) {
                array.push(index + 1);
              }
            }
            setPagesList(array);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  }, [page, update]);
  return (
    <ContentBox>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <Typography sx={{ fontSize: "24px", fontWeight: "500", color: "black" }}>{t("pages.titles.list_exp")}</Typography>
        {loading && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            {shippings?.map((card, index) => (
              <Box
                key={index}
                sx={{
                  boxShadow: "0px 4px 18px 0px rgba(75, 70, 92, 0.25)",
                  borderRadius: "12px",
                }}
              >
                <CardShippingComponent kyc_verified={card?.trip?.user?.identity_kyc_status === "verified" && true} status={card?.status + "_shipping"} card={card} status_display={card?.status_display} setOpenDetails={setOpenDetails} departure={card?.trip?.departure?.name_en} showUser={true} arrival_hour={moment(card?.trip?.arrival_time).format()} predicted_time={card?.trip?.duration} destination={card?.trip?.destination?.name_en} departure_hour={moment(card?.trip?.departure_time).format()} available_weight={card?.trip?.max_weight} flight_date={moment(card?.trip?.departure_time).format()} first_name={card?.trip?.user?.first_name} last_name={card?.trip?.user?.last_name} image_url={card?.trip?.user?.file_url} note={card?.trip?.user?.rating === 0 && card?.trip?.user?.number_of_ratings === 0 ? "No rating" : card?.trip?.user?.rating} />
              </Box>
            ))}
          </Box>
        )}
        <Modal
          open={openDetails?.open}
          onClose={() => {
            setOpenDetails({
              open: false,
              data: null,
            });
          }}
        >
          <Box sx={modalStyleOverflowDashboard} minHeight={"50vh"}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                minHeight: "50vh",
              }}
            >
              {!loadingDetails && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    minHeight: "50vh",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <IconButton
                      onClick={() => {
                        setOpenDetails({
                          open: false,
                          data: null,
                        });
                      }}
                      sx={{ padding: "5px !important" }}
                    >
                      <FontAwesomeIcon icon={faClose} size={"1x"} color={"black"} />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>{openDetails?.data?.package?.title}</Typography>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "10px",
                        gap: "16px",
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                    >
                      <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
                        {openDetails?.data?.trip?.pricing} {openDetails?.data?.trip?.currency?.toUpperCase()}
                      </Typography>
                      <ButtonSquare action={openDetails?.data?.status + "_shipping"} text={openDetails?.data?.status_display} />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "start",
                      gap: "30px",
                      flexDirection: {
                        xs: "column",
                        sm: "column",
                        md: "row",
                        lg: "row",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "20px",
                        flex: 1,
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <Typography sx={{ fontSize: "20px", fontWeight: "700" }}>{t("pages.dashboard.sender")} :</Typography>
                        <Box sx={{ position: "relative" }}>
                          {openDetails?.data?.sender?.identity_kyc_status === "verified" && (
                            <Box
                              sx={{
                                position: "absolute",
                                bottom: "0px",
                                zIndex: "999",
                                left: {
                                  xs: "130px",
                                  sm: "130px",
                                  md: "130px",
                                  lg: "130px",
                                },
                              }}
                            >
                              <VerifiedIcon
                                sx={{
                                  color: colors.primary,
                                  fontSize: "40px",
                                }}
                              />
                            </Box>
                          )}
                          <Avatar
                            src={openDetails?.data?.sender?.file_url}
                            variant={"circular"}
                            sx={{
                              height: "160px",
                              width: "160px",
                              border: "4px solid " + colors.primary,
                            }}
                          />
                        </Box>

                        <Typography
                          sx={{
                            fontSize: "16px",
                            textAlign: "center",
                            fontWeight: "400",
                            maxWidth: "160px",
                          }}
                        >
                          Member since {moment(openDetails?.data?.sender.created_at).format("MMMM, YYYY")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "24px", fontWeight: "700" }}>
                          {openDetails?.data?.sender.first_name} {openDetails?.data?.sender.last_name}
                        </Typography>
                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>{openDetails?.data?.sender?.phone_number}</Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          {openDetails?.data?.sender?.rating === 0 && openDetails?.data?.sender?.number_of_ratings === 0 ? <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>No rating</Typography> : <Rating name="size-large" defaultValue={openDetails?.data?.rated_users?.sender ? openDetails?.data?.rated_users?.sender : openDetails?.data?.sender?.rating} readOnly precision={0.1} size="large" />}
                        </Box>
                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>{openDetails?.data?.sender?.bio ? openDetails?.data?.sender.bio : "-"}</Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "20px",
                        flex: 1,
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <Typography sx={{ fontSize: "20px", fontWeight: "700" }}>{t("pages.dashboard.traveler")} :</Typography>
                        <Box sx={{ position: "relative" }}>
                          {openDetails?.data?.trip?.user?.identity_kyc_status === "verified" && (
                            <Box
                              sx={{
                                position: "absolute",
                                bottom: "0px",
                                zIndex: "999",
                                left: {
                                  xs: "130px",
                                  sm: "130px",
                                  md: "130px",
                                  lg: "130px",
                                },
                              }}
                            >
                              <VerifiedIcon
                                sx={{
                                  color: colors.primary,
                                  fontSize: "40px",
                                }}
                              />
                            </Box>
                          )}
                          <Avatar
                            src={openDetails?.data?.trip?.user?.file_url}
                            variant={"circular"}
                            sx={{
                              height: "160px",
                              width: "160px",
                              border: "4px solid " + colors.primary,
                            }}
                          />
                        </Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            textAlign: "center",
                            fontWeight: "400",
                            maxWidth: "160px",
                          }}
                        >
                          Member since {moment(openDetails?.data?.trip?.user?.created_at).format("MMMM, YYYY")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "24px", fontWeight: "700" }}>
                          {openDetails?.data?.trip?.user?.first_name} {openDetails?.data?.trip?.user?.last_name}
                        </Typography>
                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>{openDetails?.data?.trip?.user?.phone_number}</Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            gap: "10px",
                          }}
                        >
                          {openDetails?.data?.trip?.user?.rating === 0 && openDetails?.data?.trip?.user?.number_of_ratings === 0 ? <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>No rating</Typography> : <Rating name="size-large" defaultValue={openDetails?.data?.rated_users?.traveler ? openDetails?.data?.rated_users?.traveler : openDetails?.data?.trip?.user?.rating} readOnly precision={0.1} size="large" />}
                        </Box>
                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>{openDetails?.data?.trip?.user?.bio ? openDetails?.data?.trip?.user?.bio : "-"}</Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "20px",
                        flex: 1,
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <Typography sx={{ fontSize: "20px", fontWeight: "700" }}>{t("pages.dashboard.receiver")} :</Typography>
                        <Box sx={{ position: "relative" }}>
                          {openDetails?.data?.receiver?.identity_kyc_status === "verified" && (
                            <Box
                              sx={{
                                position: "absolute",
                                bottom: "0px",
                                zIndex: "999",
                                left: {
                                  xs: "130px",
                                  sm: "130px",
                                  md: "130px",
                                  lg: "130px",
                                },
                              }}
                            >
                              <VerifiedIcon
                                sx={{
                                  color: colors.primary,
                                  fontSize: "40px",
                                }}
                              />
                            </Box>
                          )}
                          <Avatar
                            src={openDetails?.data?.receiver.file_url}
                            variant={"circular"}
                            sx={{
                              height: "160px",
                              width: "160px",
                              border: "4px solid " + colors.primary,
                            }}
                          />
                        </Box>

                        <Typography
                          sx={{
                            fontSize: "16px",
                            textAlign: "center",
                            fontWeight: "400",
                            maxWidth: "160px",
                          }}
                        >
                          Member since {moment(openDetails?.data?.receiver.created_at).format("MMMM, YYYY")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "24px", fontWeight: "700" }}>
                          {openDetails?.data?.receiver?.first_name} {openDetails?.data?.receiver?.last_name}
                        </Typography>
                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>{openDetails?.data?.receiver?.phone_number}</Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          {openDetails?.data?.receiver?.rating === 0 && openDetails?.data?.receiver?.number_of_ratings === 0 ? <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>No rating</Typography> : <Rating name="size-large" defaultValue={openDetails?.data?.rated_user?.receiver ? openDetails?.data?.receiver?.rated_user?.receiver : openDetails?.data?.receiver?.rating} precision={0.1} readOnly size="large" />}
                        </Box>
                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>{openDetails?.data?.receiver?.bio ? openDetails?.data?.receiver?.bio : "-"}</Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: "70px",
                      border: "2px solid " + colors?.primary,
                    }}
                  ></Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "16px",
                      flexDirection: {
                        xs: "column-reverse",
                        sm: "column-reverse",
                        md: "column-reverse",
                        lg: "row",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flex: "1",
                        flexDirection: "column",
                        gap: "16px",
                      }}
                    >
                      <Box>
                        <Typography sx={{ fontSize: "24px", fontWeight: "700" }}>{t("pages.onboarding.description")}</Typography>
                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>{openDetails?.data?.package?.description ? openDetails?.data?.package?.description : "-"}</Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "70px",
                          border: "2px solid " + colors?.primary,
                        }}
                      ></Box>
                      <Box>
                        <Typography sx={{ fontSize: "24px", fontWeight: "700" }}>{t("pages.onboarding.details")}</Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            flexWrap: "wrap",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#A7A9B7",
                            }}
                          >
                            {openDetails?.data?.package?.content_type?.type_name}
                          </Typography>
                          <Box
                            sx={{
                              height: "20px",
                              borderLeft: "2px solid #A7A9B7",
                            }}
                          ></Box>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#A7A9B7",
                            }}
                          >
                            {parseFloat(openDetails?.data?.package?.weight).toFixed(1)} kg
                          </Typography>{" "}
                          <Box
                            sx={{
                              height: "20px",
                              borderLeft: "2px solid #A7A9B7",
                            }}
                          ></Box>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#A7A9B7",
                            }}
                          >
                            W {openDetails?.data?.package?.dimensions?.width} cm /
                          </Typography>{" "}
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#A7A9B7",
                            }}
                          >
                            L {openDetails?.data?.package?.dimensions?.length} cm /
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#A7A9B7",
                            }}
                          >
                            H {openDetails?.data?.package?.dimensions?.height} cm
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", gap: "24px" }}>
                        <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                          {openDetails?.data?.package?.file_urls?.map((file, index) => (
                            <Box key={index}>
                              <img
                                onClick={() => {
                                  setOpenViewImages({
                                    open: true,
                                    images: openDetails?.data?.package?.file_urls,
                                    selectedImage: index,
                                  });
                                }}
                                src={file}
                                alt={"package"}
                                style={{
                                  height: "200px",
                                  width: "150px",
                                  objectFit: "cover",

                                  borderRadius: "18px",
                                }}
                              />
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ flex: "1" }}>
                      <Box
                        sx={{
                          padding: "12px",
                          border: "1px solid gray",
                          borderRadius: "12px",
                          flexWrap: "wrap",
                          display: "flex",
                          gap: "16px",
                        }}
                      >
                        <Box>
                          <img src={flightIcon} alt={"flight"} />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                          }}
                        >
                          <Box>
                            <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                              {openDetails?.data?.trip?.departure?.name_en} to {openDetails?.data?.trip?.destination?.name_en}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              backgroundColor: colors.primary,
                              padding: "5px 16px",
                              borderRadius: "16px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "white",
                              }}
                            >
                              {showDate(openDetails?.data?.trip?.departure_time)} - {showDate(openDetails?.data?.trip?.arrival_time)}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "16px",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "24px",
                                fontWeight: "500",
                                color: colors?.primary,
                              }}
                            >
                              {moment.utc(openDetails?.data?.trip?.departure_time).format("HH:mm")}
                            </Typography>
                            <FontAwesomeIcon icon={faChevronRight} color={colors?.primary} size={"2x"} />
                            <Typography
                              sx={{
                                fontSize: "24px",
                                fontWeight: "500",
                                color: colors?.primary,
                              }}
                            >
                              {moment.utc(openDetails?.data?.trip?.arrival_time).format("HH:mm")}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Modal>
        {shippings?.length === 0 && !loading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "46px", fontWeight: "700", color: "black" }}>{t("pages.dashboard.nothing")}</Typography>
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "400",
                color: "#858585",
              }}
            >
              {t("pages.dashboard.no_results")}
            </Typography>
            <Box
              sx={{
                maxHeight: "45vh",
                aspectRatio: "2/1.5",
              }}
            >
              <img
                src={noPackagesIcon}
                alt={"coming-soon"}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
          </Box>
        )}{" "}
        {dashboard && (
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <IconButton onClick={() => navigate("/admin/shippings")}>
              <Add />
            </IconButton>
          </Box>
        )}
        {shippings.length > 0 && !dashboard && (
          <Box justifyContent="flex-end" display="flex" gap="4px">
            {page > 1 && (
              <ButtonSquare
                onClick={() => {
                  setPage(page - 1);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                action="Previous"
              />
            )}
            <Box display="flex" gap="4px" justifyContent="center">
              {pagesList.map((p) => (
                <ButtonSquare
                  onClick={() => {
                    setPage(p);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  action={p === page ? "number-active" : "number"}
                  number={p}
                />
              ))}
            </Box>
            {data?.pagination?.total - pageSize * page > 0 && (
              <ButtonSquare
                onClick={() => {
                  setPage(page + 1);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                action="Next"
              />
            )}
          </Box>
        )}
        {openViewImages?.open && <ImagesCarousel open={openViewImages} setOpen={setOpenViewImages} />}
      </Box>
    </ContentBox>
  );
}
export default Shippings;
