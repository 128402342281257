import React, { useState } from "react";
import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const Chart = ({ data }) => {
  const { t } = useTranslation();
  const dataSeriesIn = data?.in?.map((d) => {
    return { x: d?.created_at, y: d?.sum_in };
  });
  const dataSeriesOut = data?.out?.map((d) => {
    return { x: d?.created_at, y: d?.sum_out };
  });
  const [state, setState] = useState({
    series: [
      {
        name: t("pages.data.in"),
        data: dataSeriesIn,
      },
      {
        name: t("pages.data.out"),
        data: dataSeriesOut,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      colors: ["#66DA26", "#E91E63"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={state.options} series={state.series} type="area" height={400} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default Chart;
