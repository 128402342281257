import React, { useEffect, useState } from "react";
import ContentBox from "../ui/ContentBox";
import { Autocomplete, Avatar, Box, Button, CircularProgress, IconButton, Modal, TextField, Typography, useScrollTrigger } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import positionIcon from "../../imgs/positionIcon.svg";
import calendarIcon from "../../imgs/calendarIcon.svg";
import CustomButton from "../ui/CustomButton";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import uploadIcon from "../../imgs/upload-icon.svg";
import editIcon from "../../imgs/editIcon.svg";
import { modalStyle, modalStyleOverflow } from "../../theme/ModalStyle";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormikErrorText from "../ui/FormikErrorText";
import ButtonSquare from "../ui/ButtonSquare";
import * as yup from "yup";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
function UserCard({ userData, updateInfos, setUpdateInfos }) {
  const { t } = useTranslation();
  const [openEditProfile, setOpenEditProfile] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const genders = ["MALE", "FEMALE"];
  const [isAdminUpdated, setIsAdminUpdated] = useState(false);
  const [drag, setDrag] = useState(false);
  const [file, setFile] = useState(null);
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const trans = {
    active: "Actif",
    incative: "Inactif",
  };
  const user_id = user?.data?.id;
  const { id } = useParams();
  const [error, setError] = useState("");
  const [isFileVerified, setIsFileVerified] = useState(false);
  const [openChangePic, setOpenChangePic] = useState(false);

  const maxSize = 5 * 1024 * 1024;

  const handleCloseEdit = () => {
    setOpenEditProfile(false);
  };

  const handleOpenEdit = () => {
    setOpenEditProfile(true);
  };
  const initialValues = {
    first_name: userData?.profile?.first_name,
    last_name: userData?.profile?.last_name,
    contact: userData?.profile?.phone_number,
    email: userData?.email,
    password: "",
    status: trans[`${userData?.status}`],
    bio: userData?.profile?.bio,
  };

  const validationSchema = yup.object({
    first_name: yup.string().required(t("forms.validations.required")).min(3, t("forms.validations.3_char_min")),
    last_name: yup.string().required(t("forms.validations.required")).min(3, t("forms.validations.3_char_min")),
    contact: yup.string().required(t("forms.validations.required")).min(8, t("forms.validations.8_char_min")).max(15, t("forms.validations.15_char_man")),
    email: yup.string().email(t("forms.validations.invalid_value")).required(t("forms.validations.required")),
    status: yup.string().required(t("forms.validations.required")),
  });

  const handleClickAvatar = () => {
    setOpenChangePic(true);
  };
  const handleCloseEditPic = () => {
    setOpenChangePic(false);
    setFile(null);
    setError("");
  };
  const handleDrop = () => {
    setDrag(false);
  };
  const handleDragOver = () => {
    setDrag(true);
  };
  const handleDragLeave = () => {
    setDrag(false);
  };
  const VerifyFile = (e) => {
    if (e.target?.files[0]) {
      if (!e.target?.files[0].size <= maxSize) {
        setError("taille du fichier > 5MB");
        setIsFileVerified(false);
      }
      if (e.target?.files[0].size <= maxSize) {
        setError("");
        setIsFileVerified(true);
        setFile(e.target?.files[0]);
      }
    } else {
      setFile(null);
    }
  };
  const [progressUpdatePic, setProgressUpdatePic] = useState(0);
  var configsPic = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
      "accept-language": localStorage.getItem("i18nextLng"),
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressUpdatePic(percentCompleted);
    },
  };
  const SubmitUpdatePic = () => {
    Api.post(
      Urls?.UpdateUser(id),
      {
        image: file,
      },
      configsPic
    )
      .then((res) => {
        setUpdateInfos(!updateInfos);
        setProgressUpdatePic(0);
        setOpenChangePic(false);
        setFile(null);
        toast.success(res?.data?.message);
        console.log(id, user_id, "ids");
        if (id == user_id) {
          setIsAdminUpdated(!isAdminUpdated);
          console.log("update admin");
        }
      })
      .catch((err) => {
        console.log(err);
        setProgressUpdatePic(0);
        toast?.error(err?.response?.data?.message);
      });
  };
  const handleUpdatePassword = (password) => {
    Api.patch(
      Urls.UpdateUser(id),
      {
        password: password,
      },
      { headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` }, "accept-language": localStorage.getItem("i18nextLng") }
    )
      .then((res) => {
        setUpdateInfos(!updateInfos);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        toast?.error(err?.response?.data?.message);
      });
  };
  const handleUpdateInfos = (values) => {
    Api.patch(
      Urls.UpdateUser(id),
      {
        ...(values?.first_name && { first_name: values?.first_name }),
        ...(values?.last_name && { last_name: values?.last_name }),
        ...(values?.contact && { phone_number: values?.contact }),
        ...(values?.bio && { bio: values?.bio }),
        ...(values?.status && { status: trans[`${values?.status}`] }),
      },
      { headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}`, "accept-language": localStorage.getItem("i18nextLng") } }
    )
      .then((res) => {
        setUpdateInfos(!updateInfos);
        setOpenEditProfile(false);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        toast?.error(err?.response?.data?.message);
      });
  };

  return (
    <Box>
      <ContentBox>
        <Box display="flex" sx={{ flexDirection: { xs: "column", lg: "row", sm: "row", md: "row" } }} gap="26px">
          <Box>
            <Avatar onClick={isHover ? handleClickAvatar : null} onMouseOver={() => setIsHover(true)} onMouseOut={() => setIsHover(false)} variant="square" sx={{ borderRadius: "6px", height: "100px", width: "100px", filter: `${isHover && "brightness(0.5)"}`, objectFit: "cover", transition: "0.5s" }}>
              {!userData?.profile?.file_url && <PersonIcon sx={{ height: "100px", width: "100px" }} />}
              {userData?.profile?.file_url && <img src={userData?.profile?.file_url} alt="profile-pic" height="100px" width="100px" />}
              {isHover ? <CameraAltIcon fontSize="large" sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "black", transition: "0.5s" }} /> : null}
            </Avatar>
          </Box>
          <Box display="flex" alignItems="end" width="100%">
            <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between" flexWrap="wrap">
              <Box display="flex" gap="6px" sx={{ flexDirection: { xs: "column", lg: "row", md: "row", sm: "row" }, alignItems: { xs: "start", sm: "center", md: "center", lg: "center" } }} justifyContent="space-between">
                <Box display="flex" flexDirection="column" gap="10px">
                  <Typography fontSize="18px" lineHeight="24px" fontWeight="500" color="#4B465C">
                    {userData?.profile?.first_name + " " + userData?.profile?.last_name}
                  </Typography>
                  <Box display="flex" alignItems="center" gap="10px">
                    <Box display="flex" gap="6px" alignItems="start">
                      <img src={calendarIcon} alt="calendar" />
                      <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                        {userData?.created_at ? t("pages.data.subscribed") + " " + moment(userData?.created_at).format("MMMM YYYY") : "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box>
                <CustomButton onClick={handleOpenEdit} startIcon={editIcon} variant="primary" text={t("buttons.edit_profile")} active={true} />
              </Box>
            </Box>
          </Box>
        </Box>
      </ContentBox>
      <Modal open={openEditProfile} onClose={handleCloseEdit}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handleCloseEdit} />
          </Box>
          <Box>
            <Formik onSubmit={handleUpdateInfos} validationSchema={validationSchema} initialValues={initialValues} enableReinitialize={true}>
              {({ errors, setFieldValue, values }) => (
                <Form>
                  <Box marginBottom="26px">
                    <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                      {t("pages.titles.update_profile")}
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box display="flex" sx={{ gap: { xs: "8px", sm: "8px", md: "16px", lg: "26px" } }}>
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          {t("forms.first_name")}
                        </Typography>
                        <Field style={{ width: "100%" }} name="first_name" type="input" id="first_name" as={TextField} autoComplete="off" placeholder="John" />
                        <ErrorMessage component={FormikErrorText} name="first_name" />
                      </Box>
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          {t("forms.last_name")}
                        </Typography>
                        <Field style={{ width: "100%" }} name="last_name" type="input" id="last_name" as={TextField} autoComplete="off" placeholder="Doe" />
                        <ErrorMessage component={FormikErrorText} name="last_name" />
                      </Box>
                    </Box>
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        {t("forms.status")}
                      </Typography>
                      <Autocomplete
                        sx={{ minWidth: "200px" }}
                        value={values?.status}
                        options={[t("options.active"), t("options.inactive")]}
                        getOptionLabel={(e) => e}
                        onChange={(event, value) => {
                          if (value !== null) {
                            setFieldValue("status", value);
                          } else {
                            setFieldValue("status", null);
                          }
                        }}
                        renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Statut" />}
                      />
                    </Box>
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        {t("forms.phone_number")}{" "}
                      </Typography>
                      <Field style={{ width: "100%" }} name="contact" type="input" id="contact" as={TextField} autoComplete="off" placeholder="(123) 456-7890" />
                      <ErrorMessage component={FormikErrorText} name="contact" />
                    </Box>
                    <Box display="flex" flexDirection="row" gap="26px">
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#C7C7C7">
                          {t("forms.email")}{" "}
                        </Typography>
                        <Field style={{ width: "100%" }} name="email" type="email" id="email" as={TextField} autoComplete="off" placeholder="john@doe.com" />
                        <ErrorMessage component={FormikErrorText} name="email" />
                      </Box>
                    </Box>
                    <Box flex={1} display="flex" alignItems="end" gap="10px">
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          {t("forms.password")}
                        </Typography>
                        <Field style={{ width: "100%" }} name="password" type="password" id="password" as={TextField} autoComplete="off" placeholder="*********" />
                      </Box>
                      <Box>
                        <Button disabled={values?.password?.length < 7 && true} variant="primary" onClick={() => handleUpdatePassword(values?.password)}>
                          {t("buttons.save")}
                        </Button>
                      </Box>
                    </Box>
                    <Box width="100%" display="flex" flexDirection="row" gap="26px">
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          {t("forms.bio")}{" "}
                        </Typography>
                        <TextField
                          onChange={(e) => {
                            setFieldValue("bio", e.target?.value);
                          }}
                          value={values.bio}
                          placeholder="biography..."
                          name="bio"
                          type="input"
                          id="bio"
                          multiline
                          fullWidth
                          columns={10}
                          rows={5}
                          autoComplete="off"
                        />{" "}
                        <ErrorMessage component={FormikErrorText} name="biography" />
                      </Box>
                    </Box>
                    <Box marginTop="10px" justifyContent="center" display="flex" gap="16px">
                      <Button type="submit" variant="primary">
                        {t("buttons.save")}
                      </Button>
                      <Button onClick={handleCloseEdit} variant="primary_inactive">
                        {t("buttons.cancel")}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>

      <Modal open={openChangePic} onClose={handleCloseEditPic}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handleCloseEditPic} />
          </Box>
          <Box marginBottom="26px">
            <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
              {t("pages.titles.pic_update")}
            </Typography>
          </Box>
          <Box sx={{ cursor: "pointer", minWidth: "200px" }} position="relative">
            <input onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} accept="image/jpeg , image/png , image/jpg" onChange={(e) => VerifyFile(e)} style={{ opacity: "0", zIndex: "0", height: "100%", width: "100%", position: "absolute" }} id="avatar-input" type="file" />
            <Box justifyContent="center" display="flex">
              <Box sx={{ width: "100%", transition: "0.2s", background: `${drag ? "rgba(75, 70, 92, 0.1)" : "white"}` }} gap="10px" display="flex" flexDirection="column" alignItems="center" padding="40px 50px" border="1px dashed #DBDADE">
                <img src={uploadIcon} alt="upload" />
                <Typography textAlign="center" fontSize="22px" lineHeight="30px" fontWeight="500" color="#4B465C">
                  {!file && t("forms.files")}
                  {file && file.name}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" marginTop="16px" justifyContent="center">
            {error && <FormikErrorText>{error}</FormikErrorText>}
          </Box>
          {progressUpdatePic !== 0 && (
            <Box marginTop="16px" display="flex" gap="16px" alignItems="center" justifyContent="center">
              <CircularProgress />
              <Typography fontSize="22px" lineHeight="30px" fontWeight="500" color="#4B465C">
                {progressUpdatePic}%
              </Typography>
            </Box>
          )}
          <Box marginTop="26px" display="flex" justifyContent="center" gap="16px">
            <Button onClick={SubmitUpdatePic} disabled={!isFileVerified} variant="primary">
              {t("buttons.save")}
            </Button>
            <Button variant="primary_inactive" onClick={handleCloseEditPic}>
              {t("buttons.cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default UserCard;
