import moment from "moment";
import { useMemo } from "react";
const lang = localStorage.getItem("i18nextLng");
export const showDate = (date) => {
  if (lang === "en") {
    return moment(date)?.format("MMMM DD, yyyy");
  } else {
    return moment(date)?.format("DD MMMM yyyy");
  }
};
export const usePagination = (totalItems, itemsPerPage, currentPage, siblingCount) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const range = (start, end) => {
    return Array.from({ length: end - start + 1 }, (_, idx) => start + idx);
  };

  const paginationRange = useMemo(() => {
    const totalPageNumbers = siblingCount + 5;

    if (totalPages <= totalPageNumbers) {
      return range(1, totalPages);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPages);

    const showLeftDots = leftSiblingIndex > 2;
    const showRightDots = rightSiblingIndex < totalPages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPages;

    if (!showLeftDots && showRightDots) {
      let leftRange = range(1, 3 + 2 * siblingCount);
      return [...leftRange, "...", totalPages];
    }

    if (showLeftDots && !showRightDots) {
      let rightRange = range(totalPages - (2 + 2 * siblingCount), totalPages);
      return [firstPageIndex, "...", ...rightRange];
    }

    if (showLeftDots && showRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, "...", ...middleRange, "...", lastPageIndex];
    }

    return [];
  }, [totalItems, itemsPerPage, currentPage, siblingCount]);

  return paginationRange;
};
