import { Box, Button, Modal, Typography } from "@mui/material";
import Api from "../api/Api";
import { useAuthContext } from "../hooks/useAuthContext";
import { useTranslation } from "react-i18next";
import ButtonSquare from "../components/ui/ButtonSquare";
import { modalStyleOverflow } from "../theme/ModalStyle";
import { toast } from "react-toastify";
const DeleteComponent = ({ url, open, setOpen, setUpdate }) => {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const { t } = useTranslation();
  const deleteItem = (url) => {
    Api.delete(url, {
      headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        toast.success(res?.data?.message);
        setUpdate((update) => !update);
        setOpen({ open: false, id: null });
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
      });
  };
  return (
    <Box>
      <Modal open={open?.open} onClose={() => setOpen({ id: null, open: false })}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={() => setOpen({ id: null, open: false })} />
          </Box>
          <Box>
            <Box marginBottom="26px">
              <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                {t("pages.messages.delete_title")}
              </Typography>
            </Box>
            <Box textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
              <Typography>{t("pages.messages.delete")}</Typography>
            </Box>
            <Box marginTop="10px" justifyContent="center" display="flex" gap="16px">
              <Button type="submit" onClick={() => deleteItem(url)} variant="primary">
                {t("buttons.save")}
              </Button>
              <Button onClick={() => setOpen({ id: null, open: false })} variant="primary_inactive">
                {t("buttons.cancel")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default DeleteComponent;
