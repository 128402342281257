import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, tableCellClasses } from "@mui/material";
import ContentBox from "../../../components/ui/ContentBox";
import { colors } from "../../../theme/colors";
import dollarIcon from "../../../imgs/dollarIcon.svg";
import calendarIcon from "../../../imgs/calendarIcon.svg";
import styled from "@emotion/styled";
import { wallet_columns_fr } from "../../../components/data/walletColumns";
import { wallet_columns_en } from "../../../components/data/walletColumns";

import ButtonSquare from "../../../components/ui/ButtonSquare";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Transactions from "./Transactions";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    paddingTop: "8px",
    paddingBottom: "8px",
    letterSpacing: "1px",
    color: `rgba(75,70,92,0.8)`,
    fontSize: 15,
    fontWeight: 500,
  },
  [`&.${tableCellClasses.body}`]: {
    background: "white",
    fontSize: 15,
    color: `rgba(75,70,92,0.8)`,
    fontWeight: "500",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  ":hover": {
    filter: "brightness(0.95)",
  },
  cursor: "pointer",
}));

const StyledTable = styled(Table)({
  borderRadius: "6px",
});
const Wallet = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [wallets, setWallets] = useState([]);
  //get wallets
  useEffect(() => {
    Api.get(Urls.GetWalletsByUser(id), {
      headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setWallets(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response.data?.message);
      });
  }, []);
  return (
    <Box display="flex" flexDirection="column" gap="26px">
      <ContentBox>
        <Box display="flex" flexDirection="column" gap="17px">
          <Typography fontWeight="400" fontSize="13px" lineHeight="20px" color="#4B465C">
            {t("pages.titles.wallet_informations")}
          </Typography>
          <Box sx={{ display: "flex", gap: "24px", flexWrap: "wrap" }}>
            {wallets?.map((wallet) => (
              <Box display="flex" flexDirection="column" gap="14px">
                <Box display="flex" gap="6px" alignItems="start">
                  <img src={dollarIcon} alt="position" />
                  <Typography fontSize="15px" lineHeight="22px" fontWeight="600" color="#4B465C">
                    {t("pages.data.total_wallet")}:
                  </Typography>
                  <Typography fontSize="15px" lineHeight="22px" fontWeight="600" color={colors.main}>
                    {wallet?.balance} {wallet?.currency?.code}
                  </Typography>
                </Box>
                <Box display="flex" gap="6px" alignItems="start">
                  <img src={calendarIcon} alt="position" />
                  <Typography fontSize="15px" lineHeight="22px" fontWeight="600" color="#4B465C">
                    {t("pages.data.last_checkout")} :
                  </Typography>
                  <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                    {moment(wallet?.updated_at).format("DD-MM-yyyy")}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </ContentBox>
      <Transactions />
    </Box>
  );
};
export default Wallet;
