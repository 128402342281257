import { Box, Typography } from "@mui/material";
import ContentBox from "../ui/ContentBox";
import { useTranslation } from "react-i18next";

const TripsInformation = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <ContentBox>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <Typography sx={{ fontSize: "24px", color: "black", fontWeight: "500" }}>{t("pages.titles.shippings_infos")}</Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
              <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <Box sx={{ fontSize: "18px", color: "white", backgroundColor: "#68CF96", borderRadius: "8px", padding: "5px 25px" }}>{data?.delivered?.value}</Box>
                <Typography>{data?.delivered?.status}</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <Box sx={{ fontSize: "18px", color: "white", backgroundColor: "#68CF96", borderRadius: "8px", padding: "5px 25px" }}>{data?.accepted?.value}</Box>
                <Typography>{data?.accepted?.status}</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <Box sx={{ color: "white", backgroundColor: "lightgray", borderRadius: "8px", padding: "5px 25px" }}>{data?.picked_up?.value}</Box>
                <Typography>{data?.picked_up?.status}</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <Box sx={{ color: "white", backgroundColor: "#FFC107", borderRadius: "8px", padding: "5px 25px" }}>{data?.pending?.value}</Box>
                <Typography>{data?.pending?.status}</Typography>
              </Box>
              {/*<Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <Box sx={{ color: "white", backgroundColor: "#E91E63", borderRadius: "8px", padding: "5px 25px" }}>{data?.canceled?.value}</Box>
                <Typography>{data?.canceled?.status}</Typography>
              </Box>*/}
            </Box>
          </Box>
        </Box>
      </ContentBox>
    </Box>
  );
};
export default TripsInformation;
