import React from "react";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
function StatusComponent({ name, text }) {
  const { t } = useTranslation();
  switch (name) {
    case "pending":
      return (
        <Chip
          size="medium"
          label={text ? text : t("options.pending")}
          variant="outlined"
          sx={{
            border: 2,
            borderRadius: "6px",
            borderColor: "transparent",
            padding: "0px 10px",
            backgroundColor: "rgba(168 , 170 , 174 , 0.16)",
            "& .MuiChip-label": {
              color: `#A8AAAE`,
              fontSize: "13px",
            },
          }}
        />
      );
    case "rejected":
      return (
        <Chip
          size="medium"
          label={text ? text : t("options.refused")}
          variant="outlined"
          sx={{
            borderRadius: "6px",
            border: 2,
            padding: "0px 10px",
            borderColor: "transparent",
            backgroundColor: "rgba(234 , 84 , 85 , 0.16)",
            "& .MuiChip-label": {
              color: `#EA5455`,
              fontSize: "13px",
            },
          }}
        />
      );
    case "refused":
      return (
        <Chip
          size="medium"
          label={text ? text : t("options.refused")}
          variant="outlined"
          sx={{
            borderRadius: "6px",
            border: 2,
            padding: "0px 10px",
            borderColor: "transparent",
            backgroundColor: "rgba(234 , 84 , 85 , 0.16)",
            "& .MuiChip-label": {
              color: `#EA5455`,
              fontSize: "13px",
            },
          }}
        />
      );
    case "Nexpedié":
      return (
        <Chip
          size="medium"
          label="Non expédié"
          variant="outlined"
          sx={{
            borderRadius: "6px",
            border: 2,
            padding: "0px 10px",
            borderColor: "transparent",
            backgroundColor: "rgba(234 , 84 , 85 , 0.16)",
            "& .MuiChip-label": {
              color: `#EA5455`,
              fontSize: "13px",
            },
          }}
        />
      );
    case "verified":
      return (
        <Chip
          size="medium"
          label={text ? text : t("options.accepted")}
          variant="outlined"
          sx={{
            border: 2,
            borderRadius: "6px",
            padding: "0px 10px",
            borderColor: "transparent",
            backgroundColor: "rgba(40 , 199 , 111 , 0.16)",
            "& .MuiChip-label": {
              color: `#28C76F`,
              fontSize: "13px",
            },
          }}
        />
      );
    case "picked_up":
      return (
        <Chip
          size="medium"
          label={text ? text : t("options.accepted")}
          variant="outlined"
          sx={{
            border: 2,
            borderRadius: "6px",
            padding: "0px 10px",
            borderColor: "transparent",
            backgroundColor: "rgba(40 , 199 , 111 , 0.16)",
            "& .MuiChip-label": {
              color: `#28C76F`,
              fontSize: "13px",
            },
          }}
        />
      );
    case "delivered":
      return (
        <Chip
          size="medium"
          label={text ? text : t("options.accepted")}
          variant="outlined"
          sx={{
            border: 2,
            borderRadius: "6px",
            padding: "0px 10px",
            borderColor: "transparent",
            backgroundColor: "rgba(40 , 199 , 111 , 0.16)",
            "& .MuiChip-label": {
              color: `#28C76F`,
              fontSize: "13px",
            },
          }}
        />
      );
    case "expédié":
      return (
        <Chip
          size="medium"
          label="Expédié"
          variant="outlined"
          sx={{
            border: 2,
            borderRadius: "6px",
            padding: "0px 10px",
            borderColor: "transparent",
            backgroundColor: "rgba(40 , 199 , 111 , 0.16)",
            "& .MuiChip-label": {
              color: `#28C76F`,
              fontSize: "13px",
            },
          }}
        />
      );
    case "completed":
      return (
        <Chip
          size="medium"
          label={text ? text : t("options.completed")}
          variant="outlined"
          sx={{
            border: 2,
            borderRadius: "6px",
            padding: "0px 10px",
            borderColor: "transparent",
            backgroundColor: "rgba(40 , 199 , 111 , 0.16)",
            "& .MuiChip-label": {
              color: `#28C76F`,
              fontSize: "13px",
            },
          }}
        />
      );
    case "approved":
      return (
        <Chip
          size="medium"
          label={text ? text : t("options.accepted")}
          variant="outlined"
          sx={{
            border: 2,
            borderRadius: "6px",
            padding: "0px 10px",
            borderColor: "transparent",
            backgroundColor: "rgba(40 , 199 , 111 , 0.16)",
            "& .MuiChip-label": {
              color: `#28C76F`,
              fontSize: "13px",
            },
          }}
        />
      );

    case "failed":
      return (
        <Chip
          size="medium"
          label={text ? text : t("options.refused")}
          variant="outlined"
          sx={{
            borderRadius: "6px",
            border: 2,
            padding: "0px 10px",
            borderColor: "transparent",
            backgroundColor: "rgba(234 , 84 , 85 , 0.16)",
            "& .MuiChip-label": {
              color: `#EA5455`,
              fontSize: "13px",
            },
          }}
        />
      );
    case "cancelled":
      return (
        <Chip
          size="medium"
          label={text ? text : t("options.refused")}
          variant="outlined"
          sx={{
            borderRadius: "6px",
            border: 2,
            padding: "0px 10px",
            borderColor: "transparent",
            backgroundColor: "rgba(234 , 84 , 85 , 0.16)",
            "& .MuiChip-label": {
              color: `#EA5455`,
              fontSize: "13px",
            },
          }}
        />
      );
    default:
      return (
        <Chip
          size="medium"
          label={text}
          variant="outlined"
          sx={{
            border: 2,
            borderRadius: "6px",
            padding: "0px 10px",
            borderColor: "transparent",
            backgroundColor: "rgba(40 , 199 , 111 , 0.16)",
            "& .MuiChip-label": {
              color: `#28C76F`,
              fontSize: "13px",
            },
          }}
        />
      );
  }
}

export default StatusComponent;
