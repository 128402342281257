export const Urls = {
  //login
  AUTH_ADMIN: "/admin/login",
  //logout
  LOGOUT_ADMIN: "/auth/logout",
  //reset password
  PASSWORD_RESET_OTP: "/auth/reset",
  RESET_PASSWORD: "/auth/password",
  //gey kyc requests
  GET_KYC_REQUESTS: "/admin/kyc-requests",
  //reject kyc request
  RejectKycRequest: (id) => `/admin/kyc-requests/reject/${id}`,
  //validate kyc request
  ValidateKycRequest: (id) => `/admin/kyc-requests/validate/${id}`,
  //get request
  GetRequest: (id) => `/admin/kyc-requests/${id}`,
  //create users
  CREATE_USERS: "/admin/users",
  //get users
  GET_USERS: "/admin/users",
  // UPDATE user
  UpdateUser: (id) => `/admin/users/${id}`,
  // get user
  GetUser: (id) => `/users/${id}`,
  //get all transations
  GET_ALL_TRANSACTIONS: "/admin/transactions",
  //get transactions by user
  GetTransactionsByUser: (id) => `/admin/users/${id}/transactions`,
  //get wallets
  GetWalletsByUser: (id) => `/admin/users/${id}/wallets`,
  //shipments
  GetShipmentsByUser: (id) => `/admin/users/${id}/shippings`,

  GetWithdrawRequests: `/admin/withdraw-requests`,
  GetWithdrawRequestsByID: (id) => `/admin/users/${id}/withdraw-requests`,

  //get all packages
  GET_ALL_PACKAGES: "/admin/packages",
  //GET CONTENT TYPES,
  GET_CONTENT_TYPES: "/admin/content-types",
  //add package type
  ADD_PACKAGE_TYPE: "/admin/content-types",
  //delete pckage by id
  DeletePackageByID: (id) => `/admin/content-types/${id}`,
  UpdatePackageByID: (id) => `/admin/content-types/${id}`,
  ADD_PACKAGE: "/admin/packages/",
  DeletePackageByID: (id) => `/admin/packages/${id}`,
  GetPackagesByUserID: (id) => `/admin/users/${id}/packages`,
  GetTripsByUserID: (id) => `/admin/users/${id}/trips`,
  GET_ALL_TRIPS: "/admin/trips",
  DeleteTripByID: (id) => `/admin/trips/${id}`,
  GetPackageByID: (id) => `/admin/packages/${id}`,
  GetTripByID: (id) => `/admin/trips/${id}`,
  ADD_TRIP: "/admin/trips",
  //get wallet by id
  GetWalletByUserID: (id) => `/wallets/${id}`,
  //GET TRANSACTIONS PER USER
  GetTransactionsByUserID: (id) => `/admin/users/${id}/transactions`,
  //search cities
  SearchCities: "/cities/search",
  getSettings: "/admin/settings",
  //get all reception requests
  GetAllReceptionRequestsByPackageID: (id) => `/admin/packages/${id}/reception-requests`,
  GetReceptionRequestByID: (id) => `/admin/reception-requests/${id}`,
  //update settings
  updateInfosSettings: "/admin/settings",
  //update exchange rate
  UpdateExchangeRate: (id) => `/admin/settings/exchange-rate/${id}`,
  //currencies
  GetCurrencies: "/currencies",
  //accept or refuse withdraw request
  AcceptOrRefuseWithdrawRequest: (withdrawRequest, action) => `/admin/withdraw-requests/${withdrawRequest}/${action}`,
  //test route
  TestRouteGetDashboardData: "/admin/dashboard",
  GetDashboardChart: "/admin/dashboard/chart",
  GetShipping: "/admin/shippings",
  GetShippingsByTripID: (tripID) => `/admin/trips/${tripID}/shipping-requests`,
  GetShippingsByReceptionReqID: (receptionID) => `/admin/reception-requests/${receptionID}/shipping-requests`,
};
