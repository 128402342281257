import React, { useEffect, useState } from "react";
import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import ContentBox from "../../components/ui/ContentBox";
import TotalTripsIcon from "../../imgs/totalTrips.svg";
import usersStats from "../../imgs/usersIconStats.svg";
import packageStats from "../../imgs/packageStats.svg";
import { useAuthContext } from "../../hooks/useAuthContext";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Statistics({ packages, trips, users }) {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [booking, setBooking] = useState(0);
  const [players, setPlayers] = useState(0);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box height="100%">
      <ContentBox height="100%">
        <Box display="flex" flexDirection="column" gap="51px">
          <Box>
            <Typography fontSize="18px" fontWeight="500" lineHeight="22px" color="#4B465C">
              {t("pages.titles.stats")}
            </Typography>
          </Box>
          {loading && (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          {!loading && (
            <Box display="flex" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, alignItems: { xs: "start", sm: "start", md: "center", lg: "center" } }}>
              <Box display="flex" gap="16px" flex={1} alignItems="center">
                <Avatar sx={{ borderRadius: "30px", backgroundColor: "rgba(159, 49, 186, 0.16)", padding: "9px" }}>
                  <img style={{ margin: "9px" }} src={TotalTripsIcon} alt="booking" />
                </Avatar>
                <Box>
                  <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                    {trips}
                  </Typography>
                  <Typography fontSize="13px" lineHeight="28px" fontWeight="400" color="#4B465C">
                    {t("pages.data.total_trips")}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ cursor: "pointer" }} onClick={() => navigate("/admin/users")} display="flex" gap="16px" flex={1} alignItems="center">
                <Avatar sx={{ borderRadius: "30px", backgroundColor: "rgba(0, 207, 232, 0.16)", padding: "9px" }}>
                  <img style={{ margin: "9px" }} src={usersStats} alt="booking" />
                </Avatar>
                <Box>
                  <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                    {users}
                  </Typography>
                  <Typography fontSize="13px" lineHeight="28px" fontWeight="400" color="#4B465C">
                    {t("pages.data.users")}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ cursor: "pointer" }} onClick={() => navigate("/admin/players")} display="flex" gap="16px" flex={1} alignItems="center">
                <Avatar sx={{ borderRadius: "30px", backgroundColor: "rgba(234, 84, 85, 0.16)", padding: "9px" }}>
                  <img style={{ margin: "9px" }} src={packageStats} alt="booking" />
                </Avatar>
                <Box>
                  <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                    {packages}
                  </Typography>
                  <Typography fontSize="13px" lineHeight="28px" fontWeight="400" color="#4B465C">
                    {t("pages.data.pack_delivered")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </ContentBox>
    </Box>
  );
}

export default Statistics;
