import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlaneDeparture } from "@fortawesome/free-solid-svg-icons/faPlaneDeparture";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import moment from "moment";
import ArrivalArrowIcon from "../../imgs/ArrivalIcon.svg";
import DepartureArrowIcon from "../../imgs/startDepartureIcon.svg";
import "./Style.scss";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { colors } from "../../theme/colors.js";
import VerifiedIcon from "@mui/icons-material/Verified";
import ButtonSquare from "../ui/ButtonSquare.js";
import { useTranslation } from "react-i18next";
import { showDate } from "../../utils/utils.js";
function CardShippingComponent({ departure, destination, departure_hour, arrival_hour, predicted_time, image_url, note, setOpenChat, first_name, last_name, flight_date, showUser, kyc_verified, setOpenDetails, status_display, card, status, available_weight }) {
  const { t } = useTranslation();
  return (
    <Box
      onClick={() => {
        setOpenDetails({
          open: true,
          data: card,
        });
      }}
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "12px",
        backgroundColor: "white",
        gap: "15px",
        cursor: "pointer",
        ":hover": {
          filter: "brightness(0.95)",
        },
      }}
    >
      <Box
        onClick={() => {
          setOpenDetails({
            open: true,
            data: card,
          });
        }}
        sx={{
          backgroundColor: colors?.primary,
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          flexWrap: "wrap",
          padding: "16px",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
        }}
      >
        <Typography sx={{ fontSize: "18px", fontWeight: "600", color: "white" }}>{card?.package?.content_type?.type_name}</Typography>
        <Box sx={{ height: "20px", borderLeft: "2px solid white" }}></Box>
        <Typography sx={{ fontSize: "18px", fontWeight: "600", color: "white" }}>{card?.package?.weight} Kg</Typography>{" "}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Box sx={{ height: "20px", borderLeft: "2px solid white" }}></Box>
          <Typography sx={{ fontSize: "18px", fontWeight: "600", color: "white" }}>W {card?.package?.dimensions.width}cm /</Typography> <Typography sx={{ fontSize: "18px", fontWeight: "600", color: "white" }}>L {card?.package?.dimensions?.length}cm /</Typography>
          <Typography sx={{ fontSize: "18px", fontWeight: "600", color: "white" }}>H {card?.package?.dimensions.height}cm</Typography>
        </Box>
      </Box>
      <Box
        onClick={() => {
          setOpenDetails({
            open: true,
            data: card,
          });
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px 24px",
        }}
      >
        <Typography
          sx={{
            textDecoration: "underline",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          {card?.package?.title}
        </Typography>
        {status && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
              gap: "16px",
              flexWrap: "wrap",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
              {card?.trip?.pricing} {card?.trip?.currency?.toUpperCase()}
            </Typography>
            <ButtonSquare action={status} text={status_display} />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
          flexDirection: "column",
          alignItems: "end",
          width: "100%",
        }}
        onClick={() => {
          setOpenDetails({
            open: true,
            data: card,
          });
        }}
      >
        <Box
          sx={{
            padding: "10px",
            backgroundColor: "#52C1FF",
            borderTopRightRadius: "12px",
            borderBottomLeftRadius: "12px",
            display: { xs: "inherit", sm: "inherit", md: "none", lg: "none" },
          }}
          onClick={() => {
            setOpenDetails({
              open: true,
              data: card,
            });
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "white" }}>
            {showDate(flight_date)} / {parseFloat(available_weight !== undefined ? available_weight?.toString() : "").toFixed(1)} kg available
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: { xs: "5px", sm: "5px", md: "30px", lg: "30px" },
          padding: {
            xs: "0px 0px 0px 10px",
            sm: "0px 0px 0px 10px",
            md: "10px 30px 0px 20px",
            lg: "10px 30px 0px 20px",
          },
          justifyContent: "space-between",
        }}
      >
        <Box
          onClick={() => {
            setOpenDetails({
              open: true,
              data: card,
            });
          }}
          sx={{ display: "flex", gap: "16px", width: "70%" }}
        >
          {showUser && (
            <Box
              onClick={() => {
                setOpenDetails({
                  open: true,
                  data: card,
                });
              }}
              sx={{ marginRight: "16px" }}
            >
              <Box sx={{ position: "relative" }}>
                {kyc_verified && (
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "-5px",
                      zIndex: "999",
                      left: {
                        xs: "72px",
                        sm: "72px",
                        md: "62px",
                        lg: "62px",
                      },
                    }}
                  ></Box>
                )}
                <Avatar
                  sx={{
                    border: "4px solid " + colors.primary,
                    height: { xs: "90px", sm: "90px", md: "74px", lg: "74px" },
                    width: { xs: "90px", sm: "90px", md: "74px", lg: "74px" },
                  }}
                  src={image_url}
                  variant={"circular"}
                />
              </Box>
            </Box>
          )}
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
              alignItems: "center",
              gap: "17px",
              minWidth: { xs: "0", sm: "0", md: "300px", lg: "600px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "18px", sm: "18px", md: "22px", lg: "22px" },
                  fontWeight: "500",
                  color: "#1A2E35",
                }}
              >
                {departure}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "13px", sm: "13px", md: "16px", lg: "16px" },
                  fontWeight: "700",
                  color: "#52C1FF",
                }}
              >
                {moment.utc(departure_hour).format("HH:mm")}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Box>
                <img src={DepartureArrowIcon} alt={"departure"} />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "13px",
                      sm: "13px",
                      md: "16px",
                      lg: "16px",
                    },
                    fontWeight: "700",
                    color: "#8C8C8C",
                  }}
                >
                  {predicted_time?.days === 0 ? null : predicted_time?.days + " " + t("pages.dashboard.days")} {predicted_time?.hours} {t("pages.dashboard.hours")} {predicted_time?.minutes} {t("pages.dashboard.minutes")}{" "}
                </Typography>
              </Box>

              <Box>
                <img src={ArrivalArrowIcon} alt={"departure"} />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "18px", sm: "18px", md: "22px", lg: "22px" },
                  fontWeight: "500",
                  color: "#1A2E35",
                }}
              >
                {destination}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "13px", sm: "13px", md: "16px", lg: "16px" },
                  fontWeight: "700",
                  color: "#52C1FF",
                }}
              >
                {moment.utc(arrival_hour).format("HH:mm")}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              lg: "fit-content",
              md: "fit-content",
            },
            alignItems: { xs: "end", sm: "end", md: "inherit", lg: "inherit" },
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
          alignItems: "center",
          justifyContent: "center",
          gap: "17px",
          padding: { xs: "5px", sm: "5px", lg: "0", md: "0" },
        }}
        onClick={() => {
          setOpenDetails({
            open: true,
            data: card,
          });
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
          onClick={() => {
            setOpenDetails({
              open: true,
              data: card,
            });
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "18px", sm: "18px", md: "22px", lg: "22px" },
              fontWeight: "500",
              color: "#1A2E35",
            }}
          >
            {departure}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "13px", sm: "13px", md: "16px", lg: "16px" },
              fontWeight: "700",
              color: "#52C1FF",
            }}
          >
            {moment.utc(departure_hour).format("HH:mm")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <Box>
            <img className={"arrows"} src={DepartureArrowIcon} alt={"departure"} />
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: { xs: "13px", sm: "13px", md: "16px", lg: "16px" },
                fontWeight: "700",
                color: "#8C8C8C",
              }}
            >
              {predicted_time?.days === 0 ? null : predicted_time?.days + " " + t("pages.dashboard.days")} {predicted_time?.hours} {t("pages.dashboard.hours")} {predicted_time?.minutes} {t("pages.dashboard.minutes")}{" "}
            </Typography>
          </Box>
          <Box>
            <img className={"arrows"} src={ArrivalArrowIcon} alt={"departure"} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "18px", sm: "18px", md: "22px", lg: "22px" },
              fontWeight: "500",
              color: "#1A2E35",
            }}
          >
            {destination}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "13px", sm: "13px", md: "16px", lg: "16px" },
              fontWeight: "700",
              color: "#52C1FF",
            }}
          >
            {moment.utc(arrival_hour).format("HH:mm")}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: "20px",
          borderTop: "1px solid #D9D9D9",
        }}
        onClick={() => {
          setOpenDetails({
            open: true,
            data: card,
          });
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "30px",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faPlaneDeparture} color={"#1A2E35"} size={"1x"} />
          <Typography sx={{ fontSize: "15px", fontWeight: "700", color: "#1A2E35" }}>
            {first_name} {last_name}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
            <Typography sx={{ color: "#1A2E35" }}>{note}</Typography>
            {note !== "No rating" && (
              <Box sx={{ marginBottom: "4px" }}>
                <FontAwesomeIcon icon={faStar} color={"#1A2E35"} size={"1x"} />
              </Box>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            padding: "10px",
            backgroundColor: "#52C1FF",
            borderBottomRightRadius: "12px",
            display: { xs: "none", sm: "none", md: "inherit", lg: "inherit" },
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "white" }}>
            {showDate(flight_date)} / {parseFloat(available_weight !== undefined ? available_weight?.toString() : "").toFixed(1)} kg available
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
export default CardShippingComponent;
