import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

function TableShowUser({ url_photo, full_name, email }) {
  return (
    <Box display="flex" gap="10px" alignItems="center">
      <Avatar variant="circular" sx={{ height: "38px", width: "38px" }}>
        {url_photo ? <img src={url_photo} alt="profil pic" height="38px" width="38px" style={{ borderRadius: "50%" }} /> : <PersonIcon sx={{ height: "30px", width: "30px" }} />}
      </Avatar>
      <Box>
        <Typography fontWeight="600" fontSize="15px" lineHeight="22px" color="#4B465C">
          {full_name}
        </Typography>
        {email && (
          <Typography fontSize="13px" fontWeight="400" color="#4B465C" lineHeight="20px">
            {email}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default TableShowUser;
