import styled from "@emotion/styled";
import { Autocomplete, Box, Button, CircularProgress, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, tableCellClasses } from "@mui/material";
import ContentBox from "../../components/ui/ContentBox";
import { ToastContainer, toast } from "react-toastify";
import { useAuthContext } from "../../hooks/useAuthContext";
import React, { useEffect, useState } from "react";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import editIcon from "../../imgs/editIcon.svg";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import uploadIcon from "../../imgs/upload-icon.svg";
import ButtonSquare from "../../components/ui/ButtonSquare";
import { user_columns_fr } from "../../components/data/usersColumns";
import { user_columns_en } from "../../components/data/usersColumns";
import { trans } from "../../components/data/translationOptions";
import { debounce } from "lodash";
import TableShowUser from "../../components/ui/TableShowUser";
import StatusComponent from "../../components/ui/StatusComponent";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import CustomButton from "../../components/ui/CustomButton";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormikErrorText from "../../components/ui/FormikErrorText";
import { modalStyleOverflow } from "../../theme/ModalStyle";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { colors } from "../../theme/colors";
import { useTranslation } from "react-i18next";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    paddingTop: "8px",
    paddingBottom: "8px",
    letterSpacing: "1px",
    color: `rgba(75,70,92,0.8)`,
    fontSize: 15,
    fontWeight: 500,
  },
  [`&.${tableCellClasses.body}`]: {
    background: "white",
    fontSize: 15,
    color: `rgba(75,70,92,0.8)`,
    fontWeight: "500",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  ":hover": {
    filter: "brightness(0.95)",
  },
  cursor: "pointer",
}));
const StyledTable = styled(Table)({
  borderRadius: "6px",
});
const ListeUtilisateur = () => {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [role, setRole] = useState("");
  const [statusKyc, setStatusKyc] = useState("");
  const [loading, setLoading] = useState(true);
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [statut, setStatut] = useState("");
  const [type, setType] = useState("");
  const [maxDate, setMaxDate] = useState();
  const navigate = useNavigate();
  const [drag, setDrag] = useState(false);
  const [file, setFile] = useState(null);
  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [data, setData] = useState({});
  const lang = localStorage.getItem("i18nextLng");
  const [columns, setColumns] = useState(lang === "fr" ? user_columns_fr : user_columns_en);
  const initialValues = {
    first_name: "",
    last_name: "",
    contact: "",
    email: "",
    password: "",
    status: "",
    file: "",
    bio: "",
    birthday: "",
    role: "",
  };

  const validationSchema = yup.object({
    first_name: yup.string().required(t("forms.validations.required")).min(3, t("forms.validations.3_char_min")),
    last_name: yup.string().required(t("forms.validations.required")).min(3, t("forms.validations.3_char_min")),
    contact: yup.string().required(t("forms.validations.required")).min(8, t("forms.validations.8_char_min")).max(15, t("forms.validations.15_char_max")),
    email: yup.string().email(t("forms.validations.invalid_value")).required(t("forms.validations.required")),
    status: yup.string().required(t("forms.validations.required")),
    role: yup.string().required(t("forms.validations.required")),
    birthday: yup.date().required(t("forms.validations.required")),
    bio: yup.string().required(t("forms.validations.required")),
    file: yup
      .mixed()
      .test("fileSize", t("forms.validations.file_size"), (value) => {
        if (!value) return true; // No file provided
        return value && value.size <= 5 * 1024 * 1024; // 5 MB in bytes
      })
      .test("fileType", t("forms.validations.file_type"), (value) => {
        if (!value) return true; // No file provided
        return ["image/jpeg", "image/png", "image/gif"].includes(value.type);
      }),
    password: yup
      .string()
      .required(t("forms.validations.required"))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/, t("forms.validations.password")),
  });
  //get users
  useEffect(() => {
    Api.get(Urls.GET_USERS, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(role && { role: trans[`${role}`] }),
        ...(statut && { status: trans[`${statut}`] }),
        ...(statusKyc && { kycStatus: trans[`${statusKyc}`] }),
        ...(search && { email: search }),
      },
    })
      .then((res) => {
        setUsers(res?.data?.data);
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast?.error(err?.response?.data?.message);
      });
  }, [role, statusKyc, statut, search]);
  //get year before 18 years
  useEffect(() => {
    // Obtenir la date d'aujourd'hui
    var aujourd_hui = new Date();

    // Soustraire deux ans
    aujourd_hui.setFullYear(aujourd_hui.getFullYear() - 18);

    // Obtenir les composants de la date
    var annee = aujourd_hui.getFullYear();
    var mois = aujourd_hui.getMonth() + 1; // Mois commence à 0, donc ajoutez 1
    var jour = aujourd_hui.getDate();

    // Formater la date au format "YYYY-MM-DD"
    var date = annee + "-" + (mois < 10 ? "0" : "") + mois + "-" + (jour < 10 ? "0" : "") + jour;
    setMaxDate(date);
  }, []);

  //handle Pagination
  const handlePagination = (link) => {
    //console.log("getting data");
    Api.get(`${link}`, {
      headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setData(res?.data);
        setUsers(res.data?.data);
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
        setUsers([]);
      });
  };
  //handle click table row
  const handleClickTableRow = (id) => {
    navigate("/admin/users/" + id);
  };
  //search user
  const searchUser = (type) => {
    setSearch(type);
  };
  //debounced search
  const debouncedSearch = React.useMemo(() => {
    return debounce((type) => {
      searchUser(type);
    }, 700);
  }, []);
  useEffect(() => {
    debouncedSearch(type);
  }, [type]);

  //create user
  const [progress, setProgress] = useState(0);
  var configsPic = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
      "accept-language": localStorage.getItem("i18nextLng"),
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(percentCompleted);
    },
  };
  const handleCreateUser = (values) => {
    Api.post(
      Urls.CREATE_USERS,
      {
        email: values?.email,
        first_name: values?.first_name,
        last_name: values?.last_name,
        phone_number: values?.contact,
        bio: values?.bio,
        status: trans[`${values?.status}`],
        role: trans[`${values?.role}`],
        password: values?.password,
        birthday: values?.birthday,
        file: values?.file,
      },
      configsPic
    )
      .then((res) => {
        setOpenCreateUser(false);
        setProgress(0);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        setProgress(0);
        toast?.error(err?.response?.data?.message);
      });
  };
  //handle drag files
  const handleDrop = () => {
    setDrag(false);
  };
  const handleDragOver = () => {
    setDrag(true);
  };
  const handleDragLeave = () => {
    setDrag(false);
  };
  return (
    <Box>
      <ToastContainer />
      <Box sx={{ maxWidth: "100%" }} display="flex" flexDirection="column" gap="24px">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="text_md" color="rgba(0,0,0,0.5)">
            {t("sharedLayout.navigations.users")}
          </Typography>
          <CustomButton onClick={() => setOpenCreateUser(true)} startIcon={editIcon} text={t("buttons.create_user")} variant="primary" />
        </Box>
        <Box>
          <ContentBox>
            <Box display="flex" flexDirection="column" gap="16px">
              <Box>
                <Typography variant="text_18" color="rgba(75, 70, 92 , 0.9)">
                  {t("pages.titles.users_list")}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="flex-end" flexWrap="wrap" gap="16px">
                <Autocomplete
                  sx={{ minWidth: "200px" }}
                  value={statut}
                  options={[t("options.active"), t("options.inactive")]}
                  getOptionLabel={(e) => e}
                  onChange={(event, value) => {
                    console.log(value, "value change");
                    if (value !== null) {
                      setStatut(value);
                    } else {
                      setStatut(null);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} name="autocomplete" placeholder={t("forms.placeholders.status")} />}
                />
                <Autocomplete
                  sx={{ minWidth: "200px" }}
                  value={statusKyc}
                  options={[t("options.pending"), t("options.accepted"), t("options.refused")]}
                  getOptionLabel={(e) => e}
                  onChange={(event, value) => {
                    if (value !== null) {
                      setStatusKyc(value);
                    } else {
                      setStatusKyc(null);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} name="autocomplete" placeholder={t("forms.placeholders.kyc_status")} />}
                />
                <Autocomplete
                  sx={{ minWidth: "200px" }}
                  value={role}
                  options={["Super admin", "Admin", t("options.user")]}
                  getOptionLabel={(e) => e}
                  onChange={(event, value) => {
                    if (value !== null) {
                      setRole(value);
                    } else {
                      setRole(null);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} name="autocomplete" placeholder={t("forms.placeholders.user_type")} />}
                />
                <TextField placeholder={t("forms.placeholders.email_search")} type="input" value={type} onChange={(e) => setType(e.target.value)} />
              </Box>
              {loading && (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              )}
              {!loading && (
                <Box sx={{ maxWidth: "100%" }}>
                  <TableContainer sx={{ borderRadius: "6px", border: `1px solid #DBDADE` }}>
                    <StyledTable aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          {columns.map((col, index) => (
                            <StyledTableCell key={index} width={col.width} align={col.align}>
                              {col.name}
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {users?.map((user) => (
                          <StyledTableRow key={user?.id}>
                            <StyledTableCell onClick={() => handleClickTableRow(user?.id)} align="left">
                              <Typography fontWeight="400" color="#3890E2" fontSize="15px">
                                #{user?.id}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell onClick={() => handleClickTableRow(user?.id)} align="left">
                              <TableShowUser full_name={user?.profile?.first_name + " " + user?.profile.last_name} email={user?.email} url_photo={user?.profile?.file_url} />
                            </StyledTableCell>
                            <StyledTableCell onClick={() => handleClickTableRow(user?.id)} align="left">
                              {user?.email ? user?.email : "-"}
                            </StyledTableCell>
                            <StyledTableCell onClick={() => handleClickTableRow(user?.id)} align="center">
                              {user?.profile?.phone_number ? user?.profile?.phone_number : "-"}
                            </StyledTableCell>
                            <StyledTableCell onClick={() => handleClickTableRow(user?.id)} align="center">
                              {user?.identity_kyc_status ? <StatusComponent name={user?.identity_kyc_status} /> : "-"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <ButtonSquare onClick={() => handleClickTableRow(user?.id)} action="show_outlined" />
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </StyledTable>
                  </TableContainer>
                </Box>
              )}
              {users?.length === 0 && !loading && <Typography textAlign="center">{t("pages.errors.no_users")}</Typography>}
              {users.length > 0 && (
                <Box justifyContent="flex-end" display="flex" gap="4px">
                  {data?.links?.prev && (
                    <ButtonSquare
                      onClick={() => {
                        handlePagination(data?.links?.prev);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      action="Previous"
                    />
                  )}
                  <Box display="flex" gap="4px" justifyContent="center">
                    {data?.meta?.links?.map((link) => (
                      <>
                        {link?.label !== "Suivant &raquo;" && link?.label !== "&laquo; Pr\u00e9c\u00e9dent" && (
                          <ButtonSquare
                            onClick={() => {
                              handlePagination(link.url);
                              window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                              });
                            }}
                            action={link?.active ? "number-active" : "number"}
                            number={link.label}
                          />
                        )}
                      </>
                    ))}
                  </Box>
                  {data?.links?.next && (
                    <ButtonSquare
                      onClick={() => {
                        handlePagination(data?.links?.next);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      action="Next"
                    />
                  )}
                </Box>
              )}
            </Box>
          </ContentBox>
          <Modal open={openCreateUser} onClose={() => setOpenCreateUser(false)}>
            <Box sx={modalStyleOverflow}>
              <Box position="absolute" top="2px" right="2px">
                <ButtonSquare action="close" onClick={() => setOpenCreateUser(false)} />
              </Box>
              <Box>
                <Formik onSubmit={handleCreateUser} validationSchema={validationSchema} initialValues={initialValues} enableReinitialize={true}>
                  {({ errors, setFieldValue, values }) => (
                    <Form>
                      <Box marginBottom="26px">
                        <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                          {t("pages.titles.create_user")}
                        </Typography>
                      </Box>
                      <Box display="flex" flexDirection="column" gap="16px">
                        <Box display="flex" sx={{ gap: { xs: "8px", sm: "8px", md: "16px", lg: "26px" } }}>
                          <Box flex={1}>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              {t("forms.first_name")}
                            </Typography>
                            <Field style={{ width: "100%" }} name="first_name" type="input" id="first_name" as={TextField} autoComplete="off" placeholder="John" />
                            <ErrorMessage component={FormikErrorText} name="first_name" />
                          </Box>
                          <Box flex={1}>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              {t("forms.last_name")}
                            </Typography>
                            <Field style={{ width: "100%" }} name="last_name" type="input" id="last_name" as={TextField} autoComplete="off" placeholder="Doe" />
                            <ErrorMessage component={FormikErrorText} name="last_name" />
                          </Box>
                        </Box>
                        <Box>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            {t("forms.role")}
                          </Typography>
                          <Autocomplete
                            sx={{ minWidth: "200px" }}
                            value={values?.role}
                            options={["Super admin", "Admin", t("options.user")]}
                            getOptionLabel={(e) => e}
                            onChange={(event, value) => {
                              if (value !== null) {
                                setFieldValue("role", value);
                              } else {
                                setFieldValue("role", null);
                              }
                            }}
                            renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Admin ..." />}
                          />
                          <ErrorMessage component={FormikErrorText} name="role" />
                        </Box>
                        <Box>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            {t("forms.status")}
                          </Typography>
                          <Autocomplete
                            sx={{ minWidth: "200px" }}
                            value={values?.status}
                            options={[t("options.active"), t("options.inactive")]}
                            getOptionLabel={(e) => e}
                            onChange={(event, value) => {
                              if (value !== null) {
                                setFieldValue("status", value);
                              } else {
                                setFieldValue("status", null);
                              }
                            }}
                            renderInput={(params) => <TextField {...params} name="autocomplete" placeholder={t("forms.placeholders.status")} />}
                          />
                          <ErrorMessage component={FormikErrorText} name="status" />
                        </Box>
                        <Box>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            {t("forms.phone_number")}{" "}
                          </Typography>
                          <Field style={{ width: "100%" }} name="contact" type="input" id="contact" as={TextField} autoComplete="off" placeholder="(123) 456-7890" />
                          <ErrorMessage component={FormikErrorText} name="contact" />
                        </Box>
                        <Box>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            {t("forms.birthday")}{" "}
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              inputFormat="dd-MM-yyyy"
                              maxDate={maxDate}
                              value={values?.birthday}
                              onChange={(e) => {
                                setFieldValue("birthday", moment(e).format("yyyy-MM-DD"));
                              }}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>{" "}
                          <ErrorMessage component={FormikErrorText} name="birthday" />
                        </Box>
                        <Box display="flex" flexDirection="row" gap="26px">
                          <Box flex={1}>
                            <Typography fontSize="13px" fontWeight="400" color="#C7C7C7">
                              {t("forms.email")}{" "}
                            </Typography>
                            <Field style={{ width: "100%" }} name="email" type="email" id="email" as={TextField} autoComplete="off" placeholder="john@doe.com" />
                            <ErrorMessage component={FormikErrorText} name="email" />
                          </Box>
                        </Box>
                        <Box flex={1}>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            {t("forms.password")}
                          </Typography>
                          <Field style={{ width: "100%" }} name="password" type="password" id="password" as={TextField} autoComplete="off" placeholder="*********" />
                          <ErrorMessage component={FormikErrorText} name="password" />
                        </Box>
                        <Box sx={{ cursor: "pointer", minWidth: "200px" }} position="relative">
                          <input onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} accept="image/jpeg , image/png , image/jpg" onChange={(e) => setFieldValue("file", e.target?.files[0])} style={{ opacity: "0", zIndex: "0", height: "100%", width: "100%", position: "absolute" }} id="avatar-input" type="file" />
                          <Box justifyContent="center" display="flex">
                            <Box sx={{ width: "100%", transition: "0.2s", background: `${drag ? "rgba(75, 70, 92, 0.1)" : "white"}` }} gap="10px" display="flex" flexDirection="column" alignItems="center" padding="40px 50px" border="1px dashed #DBDADE">
                              <img src={uploadIcon} alt="upload" />
                              <Typography textAlign="center" fontSize="22px" lineHeight="30px" fontWeight="500" color="#4B465C">
                                {!values?.file && t("forms.files")}
                                {values?.file && values?.file.name}
                              </Typography>
                            </Box>
                          </Box>
                          <ErrorMessage component={FormikErrorText} name="file" />
                        </Box>
                        <Box width="100%" display="flex" flexDirection="row" gap="26px">
                          <Box flex={1}>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              {t("forms.bio")}{" "}
                            </Typography>
                            <TextField
                              onChange={(e) => {
                                setFieldValue("bio", e.target?.value);
                              }}
                              value={values.bio}
                              placeholder="biography..."
                              name="bio"
                              type="input"
                              id="bio"
                              multiline
                              fullWidth
                              columns={10}
                              rows={5}
                              autoComplete="off"
                            />{" "}
                            <ErrorMessage component={FormikErrorText} name="bio" />
                          </Box>
                        </Box>
                        <Box marginTop="10px" justifyContent="center" display="flex" gap="16px">
                          <Button disabled={progress !== 0 && true} type="submit" variant="primary">
                            {t("buttons.save")}
                          </Button>
                          {progress > 0 && (
                            <Box display="flex" alignItems="center" gap="10px">
                              <CircularProgress />
                              <Typography color={colors?.main} fontSize="18px" fontWeight="600">
                                {progress}%
                              </Typography>
                            </Box>
                          )}
                          <Button onClick={() => setOpenCreateUser(false)} variant="primary_inactive">
                            {t("buttons.cancel")}
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>
    </Box>
  );
};
export default ListeUtilisateur;
