import React from "react";
import { Box, TextField, Button, Typography, Alert } from "@mui/material";
import { colors } from "../../theme/colors";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useLogin } from "../../hooks/useLogin";
import MzErrorText from "../../components/ui/MzErrorText";

import { ToastContainer, toast } from "react-toastify";
function Login() {
  const navigate = useNavigate();
  //initialize error, isLoading, and login function
  const { login, errorLogin, isLoading } = useLogin();

  // const handleClickVerifyOtp = async (errorLogin, isLoading) => {
  //   await login(formik.values.Email, formikotp.values.otp);
  // };
  // useEffect(() => {
  //   if (optSent) {
  //     setTimeout(() => {
  //       setOtpTimeOut(true);
  //     }, 40000);
  //   }
  // }, [optSent]);

  const onSubmitLogin = async (values, errorLogin, isLoading) => {
    await login(values.Email, values.password);
    toast.error(errorLogin);
  };

  const validationSchema = yup.object().shape({
    Email: yup.string().email("entrer un email valide").required("ce champ est obligatoire"),
    password: yup.string().required("ce champ est obligatoire"),
  });

  const formik = useFormik({
    initialValues: {
      Email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      onSubmitLogin(formik.values);
    },
  });
  // const formikotp = useFormik({
  //   initialValues: {
  //     otp: "",
  //   },
  //   validationSchema: validationSchemaOtp,
  //   onSubmit: () => {
  //     handleClickVerifyOtp();
  //   },
  // });
  return (
    <Box sx={{ minHeight: "100vh", backgroundColor: `${colors.dark}` }} display="flex" alignItems="center" justifyContent="center">
      <ToastContainer />

      <Box sx={{ boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.65)", padding: "2rem", background: "white", borderRadius: "15px", display: "flex", flexDirection: "column", gap: "0rem" }}>
        <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem">
          <Typography variant="text_md">Sendyo Board</Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap="2rem">
            <Box display="flex" flexDirection="column" gap="5px">
              <label>Email </label>
              <TextField type="email" name="Email" value={formik.values.Email} onChange={formik.handleChange} placeholder="Email" sx={{ width: { xs: "180px", lg: "300px", md: "300px", sm: "250px" } }} />
              {formik.errors.Email && formik.touched.Email && <MzErrorText>{formik.errors.Email}</MzErrorText>}
            </Box>
            <Box display="flex" flexDirection="column" gap="5px">
              <label>Mot de passe </label>
              <TextField placeholder="Mot de passe" name="password" value={formik.values.password} onChange={formik.handleChange} type="password" sx={{ width: { xs: "180px", lg: "300px", md: "300px", sm: "250px" } }} />
              {formik.errors.password && formik.touched.password && <MzErrorText>{formik.errors.password}</MzErrorText>}
            </Box>
          </Box>
          <Box onClick={() => navigate("/mot-de-passe-oublier")} marginBottom="1rem" display="flex" sx={{ justifyContent: "flex-end" }}>
            <Typography sx={{ cursor: "pointer", textDecoration: "underline", fontSize: "12px" }}>Mot de passe oublier</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button type="submit" disabled={isLoading} variant="primary">
              Se connecter
            </Button>
          </Box>
        </form>
        {errorLogin && (
          <Alert sx={{ marginTop: "1rem" }} severity="error">
            {errorLogin}
          </Alert>
        )}
      </Box>

      {/* {optSent && (
        <form onSubmit={formikotp.handleSubmit}>
          <Box sx={{ boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.65)", padding: "2rem", background: "white", borderRadius: "15px", display: "flex", flexDirection: "column", gap: "0rem" }}>
            <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem">
              <img src={sendyo} width={100} alt="logo" />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap="2rem">
              <Box maxWidth="350px" display="flex" flexDirection="column" gap="5px">
                <label>Code otp</label>
                <MuiOtpInput length={6} value={formikotp.values.otp} onChange={(value) => formikotp.setFieldValue("otp", value)} />
                {formikotp.errors.otp && formikotp.touched.otp && <MzErrorText>{formikotp.errors.otp}</MzErrorText>}
              </Box>
            </Box>
            {(errorLogin || otpTimeOut) && (
              <Box onClick={() => onSubmitLogin(formik.values)} display="flex" sx={{ justifyContent: "flex-end" }}>
                <Typography sx={{ cursor: "pointer", textDecoration: "underline", fontSize: "12px" }}>Renvoyer otp</Typography>
              </Box>
            )}
            <Box sx={{ marginTop: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Button onClick={handleClickVerifyOtp} type="submit" disabled={isLoading || formikotp.values.otp?.length < 6} variant="primary">
                Se connecter
              </Button>
            </Box>
            {errorLogin && (
              <Alert sx={{ marginTop: "1rem" }} severity="error">
                {errorLogin}
              </Alert>
            )}
          </Box>
        </form>
      )} */}
    </Box>
  );
}

export default Login;
