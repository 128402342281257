import { Autocomplete, Box, Button, CircularProgress, Divider, Grid, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, tableCellClasses } from "@mui/material";
import ContentBox from "../../components/ui/ContentBox";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { kyc_columns_fr } from "../../components/data/kycColumns";
import { kyc_columns_en } from "../../components/data/kycColumns";
import { trans } from "../../components/data/translationOptions";
import { useAuthContext } from "../../hooks/useAuthContext";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { ToastContainer, toast } from "react-toastify";
import DownloadIcon from "@mui/icons-material/Download";
import TableShowUser from "../../components/ui/TableShowUser";
import moment from "moment/moment";
import attentionIcon from "../../imgs/attentionIcon.svg";
import StatusComponent from "../../components/ui/StatusComponent";
import ButtonSquare from "../../components/ui/ButtonSquare";
import { modalStyle, modalStyleOverflow } from "../../theme/ModalStyle";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import FormikErrorText from "../../components/ui/FormikErrorText";
import { useTranslation } from "react-i18next";
import axios from "axios";

const KycDocuments = () => {
  const { t } = useTranslation();
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      paddingTop: "8px",
      paddingBottom: "8px",
      letterSpacing: "1px",
      color: `rgba(75,70,92,0.8)`,
      fontSize: 15,
      fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
      background: "white",
      fontSize: 15,
      color: `rgba(75,70,92,0.8)`,
      fontWeight: "500",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    ":hover": {
      filter: "brightness(0.95)",
    },
    cursor: "pointer",
  }));

  const StyledTable = styled(Table)({
    borderRadius: "6px",
  });
  //global states
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [statut, setStatut] = useState("");
  const [level, setLevel] = useState("");
  const [openAccept, setOpenAccept] = useState(false);
  const [page, setPage] = useState(1);
  const [openRefuse, setOpenRefuse] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(false);
  const [detailsUser, setDetailsUser] = useState({});
  const [data, setData] = useState({});
  const lang = localStorage.getItem("i18nextLng");
  const [columns, setColumns] = useState(lang === "fr" ? kyc_columns_fr : kyc_columns_en);
  const [loadingUserDetails, setLoadinUserDetails] = useState(false);
  const [openDocumentInfos, setOpenDocumentInfo] = useState({ open: false, id: null });
  const initialValues = {
    note: "",
  };
  const validationSchema = yup.object({
    note: yup.string().max(256, "Max 256 caractéres").required("Ce champ est obligatoire"),
  });
  //get kyc docs
  useEffect(() => {
    Api.get(`${Urls?.GET_KYC_REQUESTS}?page=${page}`, {
      headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(statut && { status: trans[`${statut}`] }),
        ...(level && { level: trans[`${level}`] }),
      },
    })
      .then((res) => {
        setDocuments(res?.data?.data);
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  }, [statut, level, change]);
  //handle Pagination
  const handlePagination = (link) => {
    //console.log("getting data");
    Api.get(`${link}`, {
      headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setData(res?.data);
        setDocuments(res.data?.data);
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
        setDocuments([]);
      });
  };
  //get documents infos
  useEffect(() => {
    if (openDocumentInfos?.open) {
      Api.get(Urls?.GetRequest(openDocumentInfos?.id), {
        headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          console.log(res?.data?.data);
          setDetailsUser(res?.data?.data[0]);
          setLoadinUserDetails(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.response?.data?.user);
          setLoadinUserDetails(false);
        });
    }
  }, [openDocumentInfos]);
  //handle click table row
  const handleClickTableRow = (id) => {
    setLoadinUserDetails(true);
    setOpenDocumentInfo({ open: true, id: id });
  };
  //handleClose open infos
  const handleCloseOpenInfos = () => {
    setOpenDocumentInfo({ open: false, id: null });
  };
  //handleClose accept
  const handleCloseAccept = () => {
    setOpenAccept(false);
  };
  //handleClose refuse
  const handleCloseRefuse = () => {
    setOpenRefuse(false);
  };
  //handle refuse kyc
  const handleRefuse = (values) => {
    const id = detailsUser?.user_profile?.user_id;
    Api.patch(
      Urls?.RejectKycRequest(id),
      {
        level: detailsUser?.level,
        note: values?.note,
      },
      {
        headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setChange(!change);
        handleCloseRefuse();
        handleCloseOpenInfos();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  //handle accept kyc
  const handleAccept = () => {
    const id = detailsUser?.user_profile?.user_id;
    Api.patch(
      Urls?.ValidateKycRequest(id),
      {
        level: detailsUser?.level,
      },
      {
        headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setChange(!change);
        handleCloseAccept();
        handleCloseOpenInfos();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const downloadImage = (url) => {
    window.open(url, "_blank");
  };
  return (
    <Box maxWidth="100%">
      <ToastContainer />
      <Box sx={{ maxWidth: "100%" }} display="flex" flexDirection="column" gap="24px">
        <Box>
          <Typography variant="text_md" color="rgba(0,0,0,0.5)">
            {t("sharedLayout.navigations.kyc_docs")}
          </Typography>
        </Box>
        <Box>
          <ContentBox>
            <Box display="flex" flexDirection="column" gap="16px">
              <Box>
                <Typography variant="text_18" color="rgba(75, 70, 92 , 0.9)">
                  {t("pages.titles.kyc_request_list")}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="flex-end" flexWrap="wrap" gap="16px">
                <Autocomplete
                  sx={{ minWidth: "200px" }}
                  value={level}
                  options={[t("options.identity")]}
                  getOptionLabel={(e) => e}
                  onChange={(event, value) => {
                    if (value !== null) {
                      setLevel(value);
                    } else {
                      setLevel(null);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} name="autocomplete" placeholder={t("forms.placeholders.level")} />}
                />
                <Autocomplete
                  sx={{ minWidth: "200px" }}
                  value={statut}
                  options={[t("options.pending"), t("options.accepted"), t("options.refused")]}
                  getOptionLabel={(e) => e}
                  onChange={(event, value) => {
                    if (value !== null) {
                      setStatut(value);
                    } else {
                      setStatut(null);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} name="autocomplete" placeholder={t("forms.placeholders.status")} />}
                />
              </Box>
              {loading && (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              )}
              {!loading && (
                <Box sx={{ overflow: "hidden", maxWidth: "100%" }}>
                  <TableContainer sx={{ borderRadius: "6px", border: `1px solid #DBDADE` }}>
                    <StyledTable aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          {columns.map((col, index) => (
                            <StyledTableCell key={index} width={col.width} align={col.align}>
                              {col.name}
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {documents?.map((doc) => (
                          <StyledTableRow key={doc?.id}>
                            <StyledTableCell onClick={() => handleClickTableRow(doc?.user_profile?.user_id)} align="left">
                              <Typography fontWeight="400" color="#3890E2" fontSize="15px">
                                #{doc?.id}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell onClick={() => handleClickTableRow(doc?.user_profile?.user_id)} align="left">
                              <TableShowUser full_name={doc?.user_profile?.first_name + " " + doc?.user_profile?.last_name} email={doc?.user_profile?.email} url_photo={doc?.user_profile?.profil_photo} />
                            </StyledTableCell>
                            <StyledTableCell onClick={() => handleClickTableRow(doc?.user_profile?.user_id)} align="left">
                              {doc?.level}
                            </StyledTableCell>
                            <StyledTableCell onClick={() => handleClickTableRow(doc?.user_profile?.user_id)} align="center">
                              {moment(doc?.updated_at).format("DD MMMM yyyy HH:mm")}
                            </StyledTableCell>
                            <StyledTableCell onClick={() => handleClickTableRow(doc?.user_profile?.user_id)} align="left">
                              <StatusComponent name={doc?.status} />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Box justifyContent="center" display="flex" gap="16px">
                                <Box>
                                  {doc?.status === "pending" && (
                                    <Button onClick={() => handleClickTableRow(doc?.user_profile?.user_id)} variant="primary">
                                      {t("buttons.perform")}
                                    </Button>
                                  )}
                                </Box>
                                <Box>
                                  <Button variant="primary">{t("buttons.all_levels")}</Button>
                                </Box>
                              </Box>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </StyledTable>
                  </TableContainer>
                </Box>
              )}
              {documents?.length === 0 && !loading && <Typography textAlign="center">{t("pages.errors.no_docs")}</Typography>}
              {documents.length > 0 && (
                <Box justifyContent="flex-end" display="flex" gap="4px">
                  {data?.links?.prev && (
                    <ButtonSquare
                      onClick={() => {
                        handlePagination(data?.links?.prev);
                        setPage(page - 1);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      action="Previous"
                    />
                  )}
                  <Box display="flex" gap="4px" justifyContent="center">
                    {data?.meta?.links?.map((link) => (
                      <>
                        {link?.label !== "Suivant &raquo;" && link?.label !== "&laquo; Pr\u00e9c\u00e9dent" && (
                          <ButtonSquare
                            onClick={() => {
                              handlePagination(link.url);
                              window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                              });
                            }}
                            action={link?.active ? "number-active" : "number"}
                            number={link.label}
                          />
                        )}
                      </>
                    ))}
                  </Box>
                  {data?.links?.next && (
                    <ButtonSquare
                      onClick={() => {
                        handlePagination(data?.links?.next);
                        setPage(page + 1);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      action="Next"
                    />
                  )}
                </Box>
              )}
            </Box>
          </ContentBox>
          <Modal open={openDocumentInfos?.open} onClose={handleCloseOpenInfos}>
            <Box sx={modalStyleOverflow}>
              <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                <Typography color="#000000" fontSize="24px" fontWeight="600">
                  {t("pages.titles.kyc_verification")}
                </Typography>
                <Box onClick={handleCloseOpenInfos}>
                  <ButtonSquare action="close" />
                </Box>
              </Box>
              {loadingUserDetails && (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              )}
              {!loadingUserDetails && (
                <Box display="flex" flexDirection="column" gap="16px">
                  <Box>
                    <Box display="flex" flexWrap="wrap" sx={{ gap: { sx: "5px", sm: "5px", md: "32px", lg: "32px" } }} alignItems="center">
                      <Typography variant="text_sm" color="rgba(0,0,0,0.5)">
                        {t("pages.data.user_verification")} <strong>#{detailsUser?.user_profile?.user_id}</strong>
                      </Typography>
                      <StatusComponent name={detailsUser?.status} />
                    </Box>
                    <Typography variant="text_sm" color="rgba(0,0,0,0.5)">
                      {t("pages.data.level")}: <strong>{detailsUser?.level}</strong>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="text_sm" color="rgba(0,0,0,0.5)">
                      <strong>
                        {t("pages.data.first_last_name")}: {detailsUser?.user_profile?.last_name} {detailsUser?.user_profile?.first_name}
                      </strong>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="text_sm" color="rgba(0,0,0,0.5)">
                      <strong>
                        {t("pages.data.email")}: {detailsUser?.user_profile?.email}
                      </strong>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="text_sm" color="rgba(0,0,0,0.5)">
                      <strong>Documents</strong>
                    </Typography>
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      {detailsUser?.kyc_documents?.map((doc) => (
                        <Grid id={doc?.id} key={doc?.id} item xs={12} lg={4} md={4} sm={6}>
                          <Box position="relative">
                            <IconButton onClick={() => downloadImage(doc?.file_url)} sx={{ ":hover": { background: "white" }, position: "absolute", top: "5px", right: "5px" }}>
                              <DownloadIcon />
                            </IconButton>
                            <img style={{ maxWidth: `100%` }} src={doc?.file_url} alt="" />
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  {detailsUser?.status === "rejected" && (
                    <Box>
                      {" "}
                      <Box display="flex" gap="5px" alignItems="center">
                        <img src={attentionIcon} alt="attention" />
                        <Box>
                          <Typography variant="text_sm" color="#000000" fontWeight="600">
                            Note
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography fontSize="13px" color="#EA5455">
                          {detailsUser?.note}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {detailsUser?.status === "pending" && (
                    <Box marginTop="10px" display="flex" gap="10px" justifyContent="end">
                      <Button onClick={() => setOpenRefuse(true)} variant="refuse">
                        {t("buttons.refuse")}
                      </Button>
                      <Button onClick={() => setOpenAccept(true)} variant="primary">
                        {t("buttons.accept")}
                      </Button>
                    </Box>
                  )}
                  <Divider />
                  <Box display="flex" gap="5px" alignItems="center">
                    <img src={attentionIcon} alt="attention" />
                    <Box>
                      <Typography variant="text_sm" color="#000000" fontWeight="600">
                        Attention
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography fontSize="13px" color="rgba(0,0,0,0.5)">
                      Please lorem ipsumipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Modal>
          <Modal open={openAccept} onClose={handleCloseAccept}>
            <Box sx={modalStyle}>
              <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                <Typography color="#000000" fontSize="24px" fontWeight="600">
                  {t("pages.titles.accept_demand")}
                </Typography>
                <Box onClick={handleCloseAccept}>
                  <ButtonSquare action="close" />
                </Box>
              </Box>
              <Box>
                <Typography>
                  {t("pages.titles.validation_accept")} : #{detailsUser?.user_profile?.user_id}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="end" gap="10px">
                <Button onClick={handleCloseAccept} variant="refuse">
                  {t("buttons.cancel")}
                </Button>
                <Button onClick={handleAccept} variant="primary">
                  {t("buttons.accept")}
                </Button>
              </Box>
            </Box>
          </Modal>
          <Modal open={openRefuse} onClose={handleCloseRefuse}>
            <Box sx={modalStyle}>
              <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                <Typography color="#000000" fontSize="24px" fontWeight="600">
                  {t("pages.titles.accept_demand")}
                </Typography>
                <Box onClick={handleCloseRefuse}>
                  <ButtonSquare action="close" />
                </Box>
              </Box>
              <Box marginBottom="16px">
                <Typography>
                  {t("pages.titles.validation_refuse")} : #{detailsUser?.user_profile?.user_id}
                </Typography>
              </Box>
              <Box>
                <Formik onSubmit={handleRefuse} initialValues={initialValues} validationSchema={validationSchema}>
                  {({ errors }) => (
                    <Form>
                      <Typography fontSize="15px" color="rgba(0,0,0,0.5)">
                        <strong>Note</strong>
                      </Typography>
                      <Field fullWidth rows={4} columns={10} name="note" placeholder="Cause du refus..." as={TextField} multiline />
                      <ErrorMessage component={FormikErrorText} name="note" />

                      <Box marginTop="16px" display="flex" justifyContent="end" gap="10px">
                        <Button onClick={handleCloseRefuse} variant="refuse">
                          {t("buttons.cancel")}
                        </Button>
                        <Button type="submit" variant="primary">
                          {t("buttons.refuse")}
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>
    </Box>
  );
};
export default KycDocuments;
