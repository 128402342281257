import { createTheme } from "@mui/material/styles";
import { colors } from "./colors";
import { frFR } from "@mui/material/locale";
//mui custom theme
export const theme = createTheme(
  {
    palette: {
      primary: {
        main: colors.main,
        secondary: colors.blue,
        white: "#FFFFF",
      },
      neutral: {
        main: "#FFFFF",
      },
    },
    typography: {
      fontFamily: ["Public sans"],
      button: {
        textTransform: "none",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            minHeight: "10px",
            minWidth: "10px",
          },
        },
        variants: [
          {
            props: {
              variant: "primary",
            },
            style: {
              background: colors?.main,
              color: "#4B465C",
              fontSize: "18px",
              padding: "5px 19px",
              borderRadius: "8px",
              "&:hover": {
                background: colors?.main,
                filter: "brightness(0.95)",
              },
            },
          },
          {
            props: {
              variant: "primary_red_decline",
            },
            style: {
              height: "48px",
              width: "fit-content",
              minWidth: "144px",
              backgroundColor: "#DA5151",
              fontSize: "16px",
              color: "white",
              fontWeight: "500",
              padding: "16px 32px",
              justifyContent: "center",
              alignItems: "center",
              gap: "12px",
              borderRadius: "72px",
              "&:hover": {
                backgroundColor: "#DA5151",
                filter: "brightness(0.95)",
              },
            },
          },
          {
            props: {
              variant: "primary_green",
            },
            style: {
              height: "48px",
              borderRadius: "72px",
              width: "fit-content",
              minWidth: "144px",
              backgroundColor: "#7ECB5A",
              fontSize: "16px",
              color: "white",
              fontWeight: "500",
              padding: "16px 32px",
              justifyContent: "center",
              alignItems: "center",
              gap: "12px",
              "&:hover": {
                backgroundColor: "#7ECB5A",
                filter: "brightness(0.95)",
              },
            },
          },
          {
            props: {
              variant: "primary_inactive",
            },
            style: {
              color: "#4B465C",
              fontSize: "18px",
              padding: "5px 19px",
              borderRadius: "8px",
              background: `transparent`,
              borderRadius: "8px",
              "&:hover": {
                background: `transparent`,
                filter: "brightness(0.95)",
                boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.2)",
              },
            },
          },
          {
            props: {
              variant: "refuse",
            },
            style: {
              background: "rgba(217, 18, 18, 0.15)",
              color: "#D91212",
              fontSize: "18px",
              padding: "5px 19px",
              borderRadius: "8px",
            },
          },
        ],
      },
      MuiTypography: {
        variants: [
          {
            props: {
              variant: "text_xs",
            },
            style: {
              fontSize: "11px",
              fontWeight: "400",
            },
          },
          {
            props: {
              variant: "text_sm",
            },
            style: {
              fontSize: "15px",
              fontWeight: "400",
            },
          },
          {
            props: {
              variant: "text_18",
            },
            style: {
              fontSize: "18px",
              fontWeight: "500",
            },
          },
          {
            props: {
              variant: "text_md",
            },
            style: {
              fontSize: "22px",
              fontWeight: "500",
            },
          },
        ],
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            color: colors.tFieldGray,
            "& .MuiInputBase-root": {
              minHeight: "38px",
              padding: "0px 10px",
            },
            "& .MuiAutocomplete-input": {
              padding: "0px 10px !important",
            },
            "& .css-gqvsnj-MuiAutocomplete-root": {
              padding: "0px 10px",
            },
            "& .css-wq7oss-MuiAutocomplete-root": {
              padding: "0px 10px",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            color: colors.tFieldGray,
            "& .MuiInputBase-root": {
              minHeight: "38px",
              padding: "0px 10px",
            },
            "& .MuiInputBase-input": {
              minHeight: "38px",
              padding: "0px 10px",
            },
            "& .MuiInputBase-inputMultiline": {
              borderRadius: "6px",
              fontSize: "14px",
              lineHeight: "22.4px",
              fontWeight: "400",
              padding: "10px 10px",
            },
            "& .MuiOutlinedInput-root": {
              minHeight: "38px",
              borderRadius: "6px",
              fontSize: "14px",
              lineHeight: "22.4px",
              fontWeight: "400",
              padding: "0px 10px",
              "& fieldset": {
                borderColor: "#DEDFE4",
                borderRadius: "6px",
                fontSize: "14px",
                lineHeight: "22.4px",
                fontWeight: "400",
                padding: "0px 10px",
              },
              "&:placeholder fieldset": {
                color: "#DEDFE4",
              },
              "&:hover fieldset": {
                border: `2px solid ${colors.main}`,
              },
              "&.Mui-focused fieldset": {
                border: `2px solid ${colors.main}`,
              },
            },
          },
        },
      },
    },
  },
  frFR
);
