export const trans = {
  Actif: "active",
  Active: "active",
  Inactif: "inactive",
  Inactive: "inactive",
  "En attente": "pending",
  Pending: "pending",
  Refusé: "rejected",
  Refused: "rejected",
  Accepté: "verified",
  Accepted: "verified",
  "Super admin": "super_admin",
  Admin: "admin",
  Utilisateur: "user",
  User: "user",
  Identité: "identity",
  Identity: "identity",
  "": "",
};
