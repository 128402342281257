export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  borderRadius: "6px",
  boxShadow: 24,
  padding: "36px",
  minWidth: { xs: "300px", sm: "400px", md: "600px", lg: "700px" },
  maxHeight: "95vh",
  maxWidth: "800px",

  PointerEvent: "auto",
  zIndex: 0,
};
export const modalStyleOverflow = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  borderRadius: "6px",
  overflowY: "auto",
  boxShadow: 24,
  padding: "36px",
  minWidth: { xs: "300px", sm: "500px", md: "700px", lg: "800px" },
  maxWidth: "800px",
  maxHeight: "95vh",
  PointerEvent: "auto",
  zIndex: 0,
};
export const modalStyleOverflowDetails = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  borderRadius: "18px",
  overflowY: "auto",
  boxShadow: 24,
  maxHeight: "90vh",
  width: { xs: "80%", sm: "80%", md: "50%", lg: "50%" },
  padding: "36px",
  PointerEvent: "auto",
  zIndex: 0,
};
export const modalStyleOverflowDashboard = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  borderRadius: "18px",
  overflowY: "auto",
  boxShadow: 24,
  maxHeight: "90vh",
  width: { xs: "80%", sm: "80%", md: "80%", lg: "80%" },
  padding: "36px",
  PointerEvent: "auto",
  zIndex: 0,
};
