import React from "react";
import { InputLabel, styled } from "@mui/material";

const ErrorText = styled(InputLabel)({
  marginTop: "0.3rem",
  color: "red",
  fontSize: "0.875rem",
});

function MzErrorText({ children }) {
  return <ErrorText>{children}</ErrorText>;
}

export default MzErrorText;
