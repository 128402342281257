import { Autocomplete, Avatar, Box, Button, CircularProgress, Grid, IconButton, InputAdornment, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, tableCellClasses } from "@mui/material";
import { useAuthContext } from "../../../hooks/useAuthContext";
import styled from "@emotion/styled";
import { travel_columns_en, travel_columns_fr } from "../../../components/data/travelColumns";
import React, { useEffect, useState } from "react";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { ToastContainer, toast } from "react-toastify";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import editIcon from "../../../imgs/editIcon.svg";
import { useTranslation } from "react-i18next";
import ContentBox from "../../../components/ui/ContentBox";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import StatusComponent from "../../../components/ui/StatusComponent";
import moment from "moment";
import { modalStyleOverflow } from "../../../theme/ModalStyle";
import uploadIcon from "../../../imgs/upload-icon.svg";

import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import { colors } from "../../../theme/colors";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import debounce from "lodash.debounce";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useParams } from "react-router-dom";
import CustomButton from "../../../components/ui/CustomButton";
import DeleteComponent from "../../../utils/DeleteHelper";
const Voyages = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      paddingTop: "8px",
      paddingBottom: "8px",
      letterSpacing: "1px",
      color: `rgba(75,70,92,0.8)`,
      fontSize: 15,
      fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
      background: "white",
      fontSize: 15,
      color: `rgba(75,70,92,0.8)`,
      fontWeight: "500",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    ":hover": {
      filter: "brightness(0.95)",
    },
    cursor: "pointer",
  }));

  const StyledTable = styled(Table)({
    borderRadius: "6px",
  });
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const lang = localStorage.getItem("i18nextLng");
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const [openCreateTrip, setOpenCreateTrip] = useState(false);
  const [trips, setTrips] = useState([]);
  const [drag, setDrag] = useState(false);
  const [contentTypes, setContentTypes] = useState([]);
  const [dataContent, setDataContent] = useState({});
  const [errorValidate, setErrorValidate] = useState("");
  const [update, setUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState({ open: false, id: null });
  const [openDocumentInfos, setOpenDocumentInfo] = useState({ open: false, data: null });
  const [loadingDoc, setLoadinDoc] = useState(false);
  const [TripInfos, setTripInfos] = useState({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  let pageSize = 10;
  const [pagesList, setPagesList] = useState([]);
  const [progress, setProgress] = useState(0);
  const [search, setSearch] = useState("");
  const [columns, setColumns] = useState(lang === "fr" ? travel_columns_fr : travel_columns_en);
  const { t } = useTranslation();
  const initialValues = {
    title: "",
    max_weight: "",
    content_type_id: { type_name: "", id: "" },
    destination_city: "",
    destination_country: "",
    departure_city: "",
    departure_country: "",
    departure_time: "",
    arrival_time: "",
    description: "",
    height: "",
    length: "",
    width: "",
    weight: "",
    image: [],
  };
  //handle drag files
  const handleDrop = () => {
    setDrag(false);
  };
  const handleDragOver = () => {
    setDrag(true);
  };
  const handleDragLeave = () => {
    setDrag(false);
  };
  async function validateImages(imageFiles) {
    console.log(imageFiles);
    const maxFileSize = 5 * 1024 * 1024;
    const allowedExtensions = [".jpg", ".jpeg", ".png", ".gif"];
    if (imageFiles?.length === 0) {
      setErrorValidate(t("forms.validations.required"));
      return false;
    }
    for (const file of imageFiles) {
      if (!file) {
        setErrorValidate(t("forms.validations.requied"));
        return false;
      }
      const extension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes("." + extension)) {
        setErrorValidate(t("forms.validations.file_type"));
        return false;
      }
      if (file.size > maxFileSize) {
        setErrorValidate(t("forms.validations.file_size"));
        return false;
      }
    }
    return true;
  }
  const validationSchema = yup.object().shape({
    content_type_id: yup.object({
      id: yup.string().required(t("forms.validations.required")),
    }),
    destination_city: yup.string().required(t("forms.validations.required")),
    title: yup.string().required(t("forms.validations.required")),
    destination_country: yup.string().required(t("forms.validations.required")),
    departure_city: yup.string().required(t("forms.validations.required")),
    departure_country: yup.string().required(t("forms.validations.required")),
    arrival_time: yup.date().required(t("forms.validations.required")),
    departure_time: yup.date().required(t("forms.validations.required")),
    height: yup.number(t("forms.validations.invalid_values")).min(1, t("forms.validations.invalid_values")).required(t("forms.validations.required")),
    length: yup.number(t("forms.validations.invalid_values")).min(1, t("forms.validations.invalid_values")).required(t("forms.validations.required")),
    width: yup.number(t("forms.validations.invalid_values")).min(1, t("forms.validations.invalid_values")).required(t("forms.validations.required")),
    max_weight: yup.number(t("forms.validations.invalid_values")).min(0, t("forms.validations.invalid_values")).required(t("forms.validations.required")),
  });
  useEffect(() => {
    Api.get(Urls?.GetTripsByUserID(id) + `?page=${page}`, {
      headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        pageSize: pageSize,
        query: search,
      },
    })
      .then((res) => {
        let co = 1;
        setTrips(res?.data?.data);
        setData(res?.data);
        if (pagesList?.length === 0) {
          let result = res?.data?.pagination?.total / pageSize;
          let array = [];
          if (result <= 1) {
            setPagesList([1]);
          } else {
            if (Number?.isInteger(result)) {
              for (let index = 1; index <= result; index++) {
                array.push(index);
              }
            } else {
              for (let index = 0; index <= result; index++) {
                array.push(index + 1);
              }
            }
            setPagesList(array);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  }, [update, page]);
  useEffect(() => {
    if (openDocumentInfos?.open) {
      Api.get(Urls.GetTripByID(openDocumentInfos?.data?.id), {
        headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          setTripInfos(res?.data?.data);
          setLoadinDoc(false);
        })
        .catch((err) => {
          toast?.error(err?.response?.data?.message);
          setLoadinDoc(false);
        });
    }
  }, [openDocumentInfos]);
  useEffect(() => {
    Api.get(Urls?.GET_CONTENT_TYPES, {
      headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setDataContent(res?.data);
        setContentTypes(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, []);

  const options = {
    headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-type": "multipart/form-data", authorization: `Bearer ${access_token}` },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(percentCompleted);
    },
  };
  const handleSubmitCreate = (values) => {
    setErrorValidate("");
    let error = !validateImages(values.image);
    if (!error) {
      let available_space = {
        width: values?.width,
        length: values?.length,
        height: values?.height,
      };
      Api.post(
        Urls?.ADD_TRIP,
        {
          user_id: id,
          content_type_id: values?.content_type_id?.id,
          available_space: JSON.stringify(available_space),
          images: values?.image,
          title: values?.title,
          departure_city: values?.departure_city,
          departure_country: values?.departure_country,
          destination_city: values?.destination_city,
          destination_country: values?.destination_country,
          arrival_time: values?.arrival_time,
          max_weight: values?.max_weight,
          departure_time: values?.departure_time,
        },
        options
      )
        .then((res) => {
          toast?.success(res?.data?.message);
          setOpenCreateTrip(false);
          setPage(1);
          setUpdate(!update);
          setProgress(0);
        })
        .catch((err) => {
          toast?.error(err?.response.data?.message);
          setProgress(0);
        });
    }
  };
  const searchInTrips = (search) => {
    Api.get(Urls?.GetTripsByUserID(id) + `?page=${page}`, {
      headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        query: search,
        pageSize: pageSize,
      },
    })
      .then((res) => {
        setTrips(res?.data?.data);
        setData(res?.data);
        let result = res?.data?.pagination?.total / pageSize;
        let array = [];
        if (result <= 1) {
          setPagesList([1]);
        } else {
          if (Number?.isInteger(result)) {
            for (let index = 1; index <= result; index++) {
              array.push(index);
            }
          } else {
            for (let index = 0; index <= result; index++) {
              array.push(index + 1);
            }
          }
          setPagesList(array);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  //debounced search
  const debouncedSearch = React.useMemo(() => {
    return debounce((search) => {
      searchInTrips(search);
    }, 700);
  }, []);
  useEffect(() => {
    setPage(1);
    debouncedSearch(search);
  }, [search]);
  const handleClickRow = (data) => {
    setLoadinDoc(true);
    setOpenDocumentInfo({ open: true, data: data });
  };
  return (
    <Box>
      <ToastContainer />
      <Box sx={{ maxWidth: "100%" }} display="flex" flexDirection="column" gap="24px">
        <Box>
          <ContentBox>
            <Box display="flex" flexDirection="column" gap="16px">
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="text_18" color="rgba(75, 70, 92 , 0.9)">
                  {t("pages.titles.list_travel")}
                </Typography>
                <CustomButton onClick={() => setOpenCreateTrip(true)} startIcon={editIcon} text={t("buttons.create_trip")} variant="primary" />
              </Box>
              <Box display="flex" alignItems="center" justifyContent="flex-end" flexWrap="wrap" gap="16px">
                <TextField onChange={(e) => setSearch(e.target?.value)} value={search} placeholder="Search" />
              </Box>
              {loading && (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              )}
              {!loading && (
                <>
                  <Box sx={{ overflow: "hidden", maxWidth: "100%" }}>
                    <TableContainer sx={{ borderRadius: "6px", border: `1px solid #DBDADE` }}>
                      <StyledTable aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            {columns.map((col, index) => (
                              <StyledTableCell key={index} width={col.width} align={col.align}>
                                {col.name}
                              </StyledTableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {trips?.map((co) => (
                            <StyledTableRow>
                              <StyledTableCell onClick={() => handleClickRow(co)} align="left">
                                <Typography fontWeight="400" color="#3890E2" fontSize="15px">
                                  #{co?.id}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell onClick={() => handleClickRow(co)} align="left">
                                {co?.title}
                              </StyledTableCell>
                              <StyledTableCell onClick={() => handleClickRow(co)} align="left">
                                <Box display="flex" flexDirection="column">
                                  <Typography>Height : {co?.available_space?.height}</Typography>
                                  <Typography>Length : {co?.available_space?.length}</Typography>
                                  <Typography>Width : {co?.available_space?.width}</Typography>
                                </Box>{" "}
                              </StyledTableCell>
                              <StyledTableCell onClick={() => handleClickRow(co)} align="left">
                                {co?.max_weight}
                              </StyledTableCell>
                              <StyledTableCell onClick={() => handleClickRow(co)} align="left">
                                {co?.departure_city}, {co?.departure_country}
                              </StyledTableCell>
                              <StyledTableCell onClick={() => handleClickRow(co)} align="left">
                                {moment(co?.departure_time).format("dddd, DD MMMM yyyy HH:mm")}
                              </StyledTableCell>
                              <StyledTableCell onClick={() => handleClickRow(co)} align="left">
                                {co?.destination_city}, {co?.destination_country}
                              </StyledTableCell>
                              <StyledTableCell onClick={() => handleClickRow(co)} align="left">
                                {moment(co?.arrival_time).format("dddd, DD MMMM yyyy HH:mm")}
                              </StyledTableCell>
                              <StyledTableCell onClick={() => handleClickRow(co)} align="center">
                                <StatusComponent name="pending" />
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <ButtonSquare onClick={() => handleClickRow(co)} action="show_outlined" />
                                <ButtonSquare action="delete" onClick={() => setOpenDelete({ open: true, id: co?.id })} />
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </StyledTable>
                    </TableContainer>
                  </Box>

                  {trips?.length < 1 && !loading && <Typography textAlign="center">{t("pages.errors.no_trips")}</Typography>}
                  {trips.length > 0 && (
                    <Box justifyContent="flex-end" display="flex" gap="4px">
                      {page > 1 && (
                        <ButtonSquare
                          onClick={() => {
                            setPage(page - 1);
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                          action="Previous"
                        />
                      )}
                      <Box display="flex" gap="4px" justifyContent="center">
                        {pagesList.map((p) => (
                          <ButtonSquare
                            onClick={() => {
                              setPage(p);
                              window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                              });
                            }}
                            action={p === page ? "number-active" : "number"}
                            number={p}
                          />
                        ))}
                      </Box>
                      {data?.pagination?.total - pageSize * page > 0 && (
                        <ButtonSquare
                          onClick={() => {
                            setPage(page + 1);
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                          action="Next"
                        />
                      )}
                    </Box>
                  )}
                </>
              )}
            </Box>
          </ContentBox>
          <Modal open={openCreateTrip} onClose={() => setOpenCreateTrip(false)}>
            <Box sx={modalStyleOverflow}>
              <Box position="absolute" top="2px" right="2px">
                <ButtonSquare action="close" onClick={() => setOpenCreateTrip(false)} />
              </Box>
              <Box>
                <Formik onSubmit={handleSubmitCreate} validationSchema={validationSchema} initialValues={initialValues} enableReinitialize={true}>
                  {({ errors, setFieldValue, values }) => (
                    <Form>
                      <Box marginBottom="26px">
                        <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                          {t("buttons.create_trip")}
                        </Typography>
                      </Box>
                      <Box display="flex" flexDirection="column" gap="16px">
                        <Box display="flex" sx={{ gap: { xs: "8px", sm: "8px", md: "16px", lg: "26px" } }}>
                          <Box flex={1}>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              {t("forms.type_pack")}
                            </Typography>
                            <Autocomplete
                              sx={{ minWidth: "200px" }}
                              value={values?.content_type_id}
                              options={contentTypes}
                              getOptionLabel={(e) => e?.type_name}
                              isOptionEqualToValue={(option, value) => option?.id === value?.id}
                              onChange={(event, value) => {
                                if (value !== null) {
                                  setFieldValue("content_type_id", value);
                                } else {
                                  setFieldValue("content_type_id", null);
                                }
                              }}
                              renderInput={(params) => <TextField {...params} name="autocomplete" placeholder={t("forms.type_pack")} />}
                            />{" "}
                            <ErrorMessage component={FormikErrorText} name="content_type_id.id" />
                          </Box>
                        </Box>

                        <Box display="flex" gap="16px" flexWrap="wrap" justifyContent="space-between">
                          <Box>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              {t("forms.height")}{" "}
                            </Typography>
                            <Field style={{ width: "100%" }} name="height" type="number" id="dimensions" as={TextField} autoComplete="off" placeholder="100" />
                            <ErrorMessage component={FormikErrorText} name="height" />
                          </Box>
                          <Box>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              {t("forms.length")}{" "}
                            </Typography>
                            <Field style={{ width: "100%" }} name="length" type="number" id="dimensions" as={TextField} autoComplete="off" placeholder="100" />
                            <ErrorMessage component={FormikErrorText} name="length" />
                          </Box>
                          <Box>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              {t("forms.width")}{" "}
                            </Typography>
                            <Field style={{ width: "100%" }} name="width" type="number" id="dimensions" as={TextField} autoComplete="off" placeholder="100" />
                            <ErrorMessage component={FormikErrorText} name="width" />
                          </Box>
                        </Box>
                        <Box>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            {t("forms.title")}{" "}
                          </Typography>
                          <Field style={{ width: "100%" }} name="title" type="input" id="title" as={TextField} autoComplete="off" placeholder="Title" />
                          <ErrorMessage component={FormikErrorText} name="title" />
                        </Box>
                        <Box>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            {t("forms.max_weight")}{" "}
                          </Typography>
                          <Field style={{ width: "100%" }} name="max_weight" type="input" id="max_weight" as={TextField} autoComplete="off" placeholder="2 kg" />
                          <ErrorMessage component={FormikErrorText} name="max_weight" />
                        </Box>
                        <Box>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            {t("forms.destination_country")}{" "}
                          </Typography>
                          <Field style={{ width: "100%" }} name="destination_country" type="input" id="destination_country" as={TextField} autoComplete="off" placeholder="Algeria" />
                          <ErrorMessage component={FormikErrorText} name="destination_country" />
                        </Box>
                        <Box>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            {t("forms.destination_city")}{" "}
                          </Typography>
                          <Field style={{ width: "100%" }} name="destination_city" type="input" id="destination_city" as={TextField} autoComplete="off" placeholder="Algiers" />
                          <ErrorMessage component={FormikErrorText} name="destination_city" />
                        </Box>
                        <Box>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            {t("forms.departure_country")}{" "}
                          </Typography>
                          <Field style={{ width: "100%" }} name="departure_country" type="input" id="departure_country" as={TextField} autoComplete="off" placeholder="Algeria" />
                          <ErrorMessage component={FormikErrorText} name="departure_country" />
                        </Box>
                        <Box>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            {t("forms.departure_city")}{" "}
                          </Typography>
                          <Field style={{ width: "100%" }} name="departure_city" type="input" id="departure_city" as={TextField} autoComplete="off" placeholder="Constantine" />
                          <ErrorMessage component={FormikErrorText} name="departure_city" />
                        </Box>
                        <Box>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            {t("forms.departure_time")}{" "}
                          </Typography>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DateTimePicker
                                value={values.departure_time}
                                minDate={new Date(new Date().setDate(new Date().getDate() + 2))}
                                maxDate={values?.arrival_time}
                                onChange={(value) => {
                                  setFieldValue("departure_time", value);
                                  console.log(value);
                                }}
                                ampm={false}
                                InputProps={{ sx: { "& .MuiSvgIcon-root": { width: "40px", height: "40px" }, "& .MuiButtonBase-root": { borderRadius: "15px", padding: 0 } } }}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          </Box>
                          <ErrorMessage component={FormikErrorText} name="departure_time" />
                        </Box>
                        <Box>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            {t("forms.arrival_time")}{" "}
                          </Typography>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DateTimePicker
                                value={values.arrival_time}
                                minDate={values?.departure_time}
                                onChange={(value) => {
                                  setFieldValue("arrival_time", value);
                                  console.log(value);
                                }}
                                ampm={false}
                                InputProps={{ sx: { "& .MuiSvgIcon-root": { width: "40px", height: "40px" }, "& .MuiButtonBase-root": { borderRadius: "15px", padding: 0 } } }}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          </Box>
                          <ErrorMessage component={FormikErrorText} name="arrival_time" />
                        </Box>
                        <Box sx={{ cursor: "pointer", minWidth: "200px" }} position="relative">
                          <input
                            multiple
                            value=""
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                            accept="image/jpeg , image/png , image/jpg"
                            onChange={(e) => {
                              console.log("choosing outside");
                              if (e.target?.files) {
                                console.log("choosing image");
                                setFieldValue("image", values?.image?.concat(e.target.files[0]));
                              }
                            }}
                            style={{ opacity: "0", zIndex: "0", height: "100%", width: "100%", position: "absolute" }}
                            id="avatar-input"
                            type="file"
                          />
                          <Box justifyContent="center" display="flex">
                            <Box sx={{ width: "100%", transition: "0.2s", background: `${drag ? "rgba(75, 70, 92, 0.1)" : "white"}` }} display="flex" flexDirection="column" alignItems="center" padding="40px 50px" border="1px dashed #DBDADE">
                              <img src={uploadIcon} alt="upload" />
                              <Typography textAlign="center" fontSize="22px" lineHeight="30px" fontWeight="500" color="#4B465C">
                                {values?.image?.length === 0 && t("forms.files")}
                                {values?.image?.length > 0 && (
                                  <Box display="flex" gap="10px">
                                    {values?.image?.map((im) => (
                                      <Typography>{im?.name}</Typography>
                                    ))}
                                  </Box>
                                )}
                              </Typography>
                            </Box>
                          </Box>
                          {errorValidate && <FormikErrorText>{errorValidate}</FormikErrorText>}
                        </Box>
                        {values?.image.length > 0 && (
                          <Box display="flex" flexDirection="row" flexWrap="wrap" gap="20px" margin="15px 0px">
                            {values?.image?.map((file, index) => (
                              <Box key={index} position="relative">
                                <Avatar variant="rounded" src={URL.createObjectURL(file)} sx={{ height: "100px", width: "100px" }} />
                                <Button
                                  variant="secondary_close"
                                  onClick={() => {
                                    URL.revokeObjectURL(file);

                                    const newArray = values.image;
                                    newArray.splice(index, 1);
                                    console.log(newArray, "new one");
                                    setFieldValue("image", newArray);
                                  }}
                                  sx={{ position: "absolute", top: -25, right: -10, width: "25px", height: "25px", padding: "5px" }}
                                >
                                  <CloseRoundedIcon htmlColor="gray" width="5px" />
                                </Button>
                              </Box>
                            ))}
                          </Box>
                        )}

                        <Box marginTop="10px" justifyContent="center" display="flex" gap="16px">
                          <Button disabled={progress !== 0 && true} type="submit" variant="primary">
                            {t("buttons.save")}
                          </Button>
                          {progress > 0 && (
                            <Box display="flex" alignItems="center" gap="10px">
                              <CircularProgress />
                              <Typography color={colors?.main} fontSize="18px" fontWeight="600">
                                {progress}%
                              </Typography>
                            </Box>
                          )}
                          <Button onClick={() => setOpenCreateTrip(false)} variant="primary_inactive">
                            {t("buttons.cancel")}
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Modal>
          <Modal open={openDocumentInfos?.open} onClose={() => setOpenDocumentInfo({ open: false, data: null })}>
            <Box sx={modalStyleOverflow}>
              <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                <Typography color="#000000" fontSize="24px" fontWeight="600">
                  {t("pages.titles.trip_infos")}
                </Typography>
                <Box onClick={() => setOpenDocumentInfo({ open: false, data: null })}>
                  <ButtonSquare action="close" />
                </Box>
              </Box>
              {loadingDoc && (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              )}
              {!loadingDoc && (
                <Box display="flex" flexDirection="column" gap="16px">
                  <Box>
                    <Typography variant="text_sm" color="rgba(0,0,0,0.5)">
                      <strong>{t("pages.data.first_last_name")}</strong>: {TripInfos?.user?.last_name} {TripInfos?.user?.first_name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="text_sm" color="rgba(0,0,0,0.5)">
                      <strong>{t("pages.data.package_type")}</strong>: {TripInfos?.content_type?.type_name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="text_sm" color="rgba(0,0,0,0.5)">
                      <strong>{t("pages.data.available_space")} : </strong>
                    </Typography>
                    <Typography variant="text_sm" color="rgba(0,0,0,0.5)">
                      <strong>{t("pages.data.height")}</strong>: {TripInfos?.available_space?.height},{"  "}
                    </Typography>
                    <Typography variant="text_sm" color="rgba(0,0,0,0.5)">
                      <strong>{t("pages.data.width")}</strong>: {TripInfos?.available_space?.width},{"  "}
                    </Typography>{" "}
                    <Typography variant="text_sm" color="rgba(0,0,0,0.5)">
                      <strong>{t("pages.data.length")}</strong>: {TripInfos?.available_space?.length}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="text_sm" color="rgba(0,0,0,0.5)">
                      <strong>{t("pages.data.max_weight")}</strong>: {TripInfos?.max_weight}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="text_sm" color="rgba(0,0,0,0.5)">
                      <strong>{t("pages.data.destination_country")}</strong>: {TripInfos?.weight}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="text_sm" color="rgba(0,0,0,0.5)">
                      <strong>{t("pages.data.departure_city")}</strong>: {TripInfos?.departure_city},{"  "}
                    </Typography>
                    <Typography variant="text_sm" color="rgba(0,0,0,0.5)">
                      <strong>{t("pages.data.departure_country")}</strong>: {TripInfos?.departure_country}
                      {"  "}
                    </Typography>{" "}
                  </Box>
                  <Box>
                    <Typography variant="text_sm" color="rgba(0,0,0,0.5)">
                      <strong>{t("pages.data.destination_country")}</strong>: {TripInfos?.destination_country},{"  "}
                    </Typography>
                    <Typography variant="text_sm" color="rgba(0,0,0,0.5)">
                      <strong>{t("pages.data.destination_city")}</strong>: {TripInfos?.destination_city}
                      {"  "}
                    </Typography>{" "}
                  </Box>

                  <Box>
                    <Typography variant="text_sm" color="rgba(0,0,0,0.5)">
                      <strong>{t("pages.data.departure_time")}</strong>: {moment(TripInfos?.departure_time).format("dddd, DD MMMM yyyy HH:mm")}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="text_sm" color="rgba(0,0,0,0.5)">
                      <strong>{t("pages.data.arrival_time")}</strong>: {moment(TripInfos?.arrival_time).format("dddd, DD MMMM yyyy HH:mm")}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="text_sm" color="rgba(0,0,0,0.5)">
                      <strong>Documents</strong>
                    </Typography>
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      {TripInfos?.file_urls?.map((doc, index) => (
                        <Grid id={index} key={index} item xs={12} lg={4} md={4} sm={6}>
                          <Box>
                            <img style={{ maxWidth: `100%` }} src={doc} alt="" />
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  <Box display="flex" justifyContent="flex-end">
                    <Button onClick={() => setOpenDelete({ open: true, id: TripInfos?.id })} variant="refuse">
                      {t("buttons.delete")}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Modal>
          {openDelete?.open && <DeleteComponent setUpdate={setUpdate} url={Urls?.DeleteTripByID(openDelete?.id)} open={openDelete} setOpen={setOpenDelete} />}{" "}
        </Box>
      </Box>
    </Box>
  );
};
export default Voyages;
