import { Box, Button, TextField, Typography } from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useEffect, useState } from "react";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { toast } from "react-toastify";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { t } from "i18next";
import FormikErrorText from "../../components/ui/FormikErrorText";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
const Settings = () => {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const { t } = useTranslation();
  const [change, setChange] = useState(false);
  const [settings, setSettings] = useState();
  const [initialValues, setIntialValues] = useState({
    contact_email: "",
    contact_phone: "",
    cut_percentage: "",
  });
  const [initialValuesEX, setIntialValuesEX] = useState({
    dzd: "",
    aed: "",
  });
  const validationSchema = yup.object().shape({
    contact_email: yup.string().email(t("validations.invalid_values")).required(t("validations.required")),
    contact_phone: yup.string().min(8, t("validations.8_char_min")).max(15, t("validations.15_char_max")),
    cut_percentage: yup.number().max(100, t("validation.invalid_values")).required(t("validations.required")),
  });
  const validationSchemaEX = yup.object().shape({
    aed: yup.number().required(t("validations.required")),
    dzd: yup.number().required(t("validations.required")),
  });
  useEffect(() => {
    Api.get(Urls.getSettings, {
      headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setSettings(res?.data.data);
        setIntialValues({
          contact_email: res?.data?.data?.contact_email,
          contact_phone: res?.data?.data?.contact_phone,
          cut_percentage: res?.data?.data?.cut_percentage,
        });
        setIntialValuesEX({
          dzd: res?.data?.data?.exchange_rates?.dzd,
          aed: res?.data?.data?.exchange_rates?.aed,
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, [change]);
  const updateExchangeRateDzd = (id, values) => {
    Api.patch(
      Urls.UpdateExchangeRate(id),
      {
        rate: values?.dzd,
      },
      {
        headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const updateExchangeRateAed = (id, values) => {
    Api.patch(
      Urls.UpdateExchangeRate(id),
      {
        rate: values?.aed,
      },
      {
        headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const updateInfos = (values) => {
    Api.patch(
      Urls.updateInfosSettings,
      {
        contact_email: values?.contact_email,
        contact_phone: values?.contact_phone,
        cut_percentage: values?.cut_percentage,
      },
      {
        headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <Box sx={{ maxWidth: "600px" }}>
      <Formik validationSchema={validationSchema} onSubmit={(values) => updateInfos(values)} /*validationSchema={validationSchema}*/ initialValues={initialValues} enableReinitialize={true}>
        {({ errors, setFieldValue, values }) => (
          <Form>
            <Box>
              <Typography sx={{ fontSize: "32px", color: "black" }}>{t("sharedLayout.navigations.settings")}</Typography>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <Box>
                <Box display="flex" flexDirection="column" gap="16px">
                  <Box>
                    <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                      {t("pages.data.contact_email")}
                    </Typography>
                    <Field style={{ width: "100%" }} name="contact_email" type="email" id="email" as={TextField} autoComplete="off" placeholder={t("pages.data.contact_email")} />
                    <ErrorMessage component={FormikErrorText} name="contact_email" />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box display="flex" flexDirection="column" gap="16px">
                  <Box>
                    <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                      {t("pages.data.contact_phone")}
                    </Typography>
                    <Field style={{ width: "100%" }} name="contact_phone" type="input" id="contact_phone" as={TextField} autoComplete="off" placeholder={t("pages.data.contact_phone")} />
                    <ErrorMessage component={FormikErrorText} name="contact_phone" />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box display="flex" flexDirection="column" gap="16px">
                  <Box>
                    <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                      {t("pages.data.cur_purc")}
                    </Typography>
                    <Field style={{ width: "100%" }} name="cut_percentage" type="number" id="cut_percentage" as={TextField} autoComplete="off" placeholder={t("pages.data.cur_purc")} />
                    <ErrorMessage component={FormikErrorText} name="cut_percentage" />
                  </Box>
                </Box>
              </Box>
              <Button type="submit" variant="primary">
                {t("buttons.save")}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <Formik
        validationSchema={validationSchemaEX}
        onSubmit={(values) => {
          updateExchangeRateAed(settings?.currencies?.filter((currency) => currency?.code === "AED")[0]?.id, values);
          updateExchangeRateDzd(settings?.currencies?.filter((currency) => currency?.code === "DZD")[0]?.id, values);
        }}
        /*validationSchema={validationSchema}*/ initialValues={initialValuesEX}
        enableReinitialize={true}
      >
        {({ errors, setFieldValue, values }) => (
          <Form>
            <Box sx={{ margin: "24px 0" }}>
              <Typography sx={{ fontSize: "24px", color: "black" }}>{t("pages.data.exchange_rates")}</Typography>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <Box>
                <Box display="flex" flexDirection="column" gap="16px">
                  <Box>
                    <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                      DZD
                    </Typography>
                    <Field style={{ width: "100%" }} name="dzd" type="number" id="dzd" as={TextField} autoComplete="off" placeholder="250" />
                    <ErrorMessage component={FormikErrorText} name="dzd" />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box display="flex" flexDirection="column" gap="16px">
                  <Box>
                    <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                      AED
                    </Typography>
                    <Field style={{ width: "100%" }} name="aed" type="number" id="aed" as={TextField} autoComplete="off" placeholder="4" />
                    <ErrorMessage component={FormikErrorText} name="aed" />
                  </Box>
                </Box>
              </Box>
              <Button type="submit" variant="primary">
                {t("buttons.save")}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default Settings;
