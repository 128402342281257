import { Box, IconButton, Modal } from "@mui/material";
import { modalStyleOverflowDetails } from "../../theme/ModalStyle.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faClose } from "@fortawesome/free-solid-svg-icons/faClose";

function ImagesCarousel({ open, setOpen }) {
  return (
    <Box>
      <Modal
        open={open}
        onClose={() =>
          setOpen({
            open: false,
            images: null,
            selectedImage: null,
          })
        }
      >
        <Box sx={modalStyleOverflowDetails}>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <IconButton
              onClick={() => {
                setOpen({
                  open: false,
                  images: null,
                  selectedImage: null,
                });
              }}
            >
              <FontAwesomeIcon icon={faClose} />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Box width={"50px"}>
              {open?.selectedImage > 0 && (
                <IconButton
                  onClick={() => {
                    setOpen({
                      open: true,
                      images: open?.images,
                      selectedImage: open?.selectedImage - 1,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faChevronLeft} size={"2x"} color={"black"} />
                </IconButton>
              )}
            </Box>
            <Box sx={{ maxWidth: "100%" }}>
              <img
                onClick={() => {}}
                src={open?.images[open?.selectedImage]}
                alt={open?.selectedImage}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  maxHeight: "60vh",
                }}
              />
            </Box>
            <Box width={"50px"}>
              {open?.selectedImage < open?.images?.length - 1 && (
                <IconButton
                  onClick={() => {
                    setOpen({
                      open: true,
                      images: open?.images,
                      selectedImage: open?.selectedImage + 1,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faChevronRight} size={"2x"} color={"black"} />
                </IconButton>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
export default ImagesCarousel;
