export const travel_columns_fr = [
  {
    name: "ID",
    width: "5%",
    align: "left",
  },
  {
    name: "TITRE",
    width: "10%",
    align: "left",
  },
  {
    name: "ESPACE DISPONIBLE",
    width: "10%",
    align: "left",
  },
  {
    name: "POID MAXIMUM",
    width: "10%",
    align: "left",
  },
  {
    name: "LIEU DE DÉPART",
    width: "10%",
    align: "left",
  },
  {
    name: "HEURE DE DÉPART",
    width: "10%",
    align: "left",
  },
  {
    name: "LIEU D'ARRIVÉE",
    width: "10%",
    align: "center",
  },
  {
    name: "HEURE D'ARRIVÉE",
    width: "10%",
    align: "center",
  },
  {
    name: "STATUT",
    width: "10%",
    align: "center",
  },
  {
    name: "ACTION",
    width: "15%",
    align: "center",
  },
];
export const travel_columns_en = [
  {
    name: "ID",
    width: "5%",
    align: "left",
  },
  {
    name: "TITLE",
    width: "10%",
    align: "left",
  },
  {
    name: "AVAILABLE SPACE",
    width: "10%",
    align: "left",
  },
  {
    name: "MAXIMUM WEIGHT",
    width: "10%",
    align: "left",
  },
  {
    name: "DEPARTURE LOCATION",
    width: "10%",
    align: "left",
  },
  {
    name: "DEPARTURE TIME",
    width: "10%",
    align: "left",
  },
  {
    name: "ARRIVAL LOCATION",
    width: "10%",
    align: "center",
  },
  {
    name: "ARRIVAL TIME",
    width: "10%",
    align: "center",
  },
  {
    name: "STATUS",
    width: "10%",
    align: "center",
  },
  {
    name: "ACTION",
    width: "15%",
    align: "center",
  },
];
