import styled from "@emotion/styled";
import { Autocomplete, Box, Table, TableBody, Modal, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, tableCellClasses, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import StatusComponent from "../../../components/ui/StatusComponent";
import { exp_columns_fr } from "../../../components/data/expColumns";
import { exp_columns_en } from "../../../components/data/expColumns";

import ContentBox from "../../../components/ui/ContentBox";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/colors";
import { modalStyleOverflow } from "../../../theme/ModalStyle";
import { toast } from "react-toastify";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    paddingTop: "8px",
    paddingBottom: "8px",
    letterSpacing: "1px",
    color: `rgba(75,70,92,0.8)`,
    fontSize: 15,
    fontWeight: 500,
  },
  [`&.${tableCellClasses.body}`]: {
    background: "white",
    fontSize: 15,
    color: `rgba(75,70,92,0.8)`,
    fontWeight: "500",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  ":hover": {
    filter: "brightness(0.95)",
  },
  cursor: "pointer",
}));

const StyledTable = styled(Table)({
  borderRadius: "6px",
});
const Expeditions = () => {
  const [statut, setStatut] = useState("");
  const [type, setType] = useState("");
  const { id } = useParams();
  const { user } = useAuthContext();
  const [expeditions, setExpeditions] = useState([]);
  const [pagesList, setPagesList] = useState([]);

  const access_token = user?.access_token;
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const pageSize = 20;
  const [page, setPage] = useState(1);
  const lang = localStorage.getItem("i18nextLng");
  const [columns, setColumns] = useState(lang === "fr" ? exp_columns_fr : exp_columns_en);
  useEffect(() => {
    Api.get(Urls.GetShipmentsByUser(id), {
      headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: pageSize,
        page: page,
      },
    })
      .then((res) => {
        setExpeditions(res?.data?.data);
        setData(res?.data);
        if (pagesList?.length === 0) {
          let result = res?.data?.pagination?.total / pageSize;
          let array = [];
          if (result <= 1) {
            setPagesList([1]);
          } else {
            if (Number?.isInteger(result)) {
              for (let index = 1; index <= result; index++) {
                array.push(index);
              }
            } else {
              for (let index = 0; index <= result; index++) {
                array.push(index + 1);
              }
            }
            setPagesList(array);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response.data?.message);
        setLoading(false);
      });
  }, [page]);
  return (
    <Box>
      <Box>
        <ContentBox>
          {loading && (
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          )}
          {!loading && (
            <Box display="flex" flexDirection="column" gap="16px">
              <Box>
                <Typography variant="text_18" color="rgba(75, 70, 92 , 0.9)">
                  {t("pages.titles.list_exp")}
                </Typography>
              </Box>

              <Box sx={{ maxWidth: "100%" }}>
                <TableContainer sx={{ borderRadius: "6px", border: `1px solid #DBDADE` }}>
                  <StyledTable aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        {columns.map((col, index) => (
                          <StyledTableCell key={index} width={col.width} align={col.align}>
                            {col.name}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {expeditions?.map((col) => (
                        <StyledTableRow key={col?.id}>
                          <StyledTableCell align="left">
                            <Typography fontWeight="400" color="#3890E2" fontSize="15px">
                              {col?.id}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {" "}
                            <Typography fontWeight="400" fontSize="15px">
                              {col?.sender?.first_name} {col?.sender?.last_name}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {" "}
                            <Typography fontWeight="400" fontSize="15px">
                              {col?.receiver?.first_name} {col?.receiver?.last_name}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {" "}
                            <Typography fontWeight="400" fontSize="15px">
                              {col?.trip?.title}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            H {col?.package?.dimensions?.height} CM x L {col?.package?.dimensions?.length} CM x W{col?.package?.dimensions?.width} CM
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {col?.trip?.pricing} {col?.trip?.currency?.toUpperCase()}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {" "}
                            <Typography fontWeight="400" fontSize="15px">
                              {col?.package?.title}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <StatusComponent text={col?.status_display} name={col?.status} />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </StyledTable>
                </TableContainer>
              </Box>
              {expeditions.length > 0 && (
                <Box justifyContent="flex-end" display="flex" gap="4px">
                  {page > 1 && (
                    <ButtonSquare
                      onClick={() => {
                        setPage(page - 1);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      action="Previous"
                    />
                  )}
                  <Box display="flex" gap="4px" justifyContent="center">
                    {pagesList.map((p) => (
                      <ButtonSquare
                        onClick={() => {
                          setPage(p);
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                        action={p === page ? "number-active" : "number"}
                        number={p}
                      />
                    ))}
                  </Box>
                  {data?.pagination?.total - pageSize * page > 0 && (
                    <ButtonSquare
                      onClick={() => {
                        setPage(page + 1);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      action="Next"
                    />
                  )}
                </Box>
              )}
            </Box>
          )}
        </ContentBox>
      </Box>
    </Box>
  );
};
export default Expeditions;
