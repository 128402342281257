export const types_columns_fr = [
  {
    name: "ID",
    width: "5%",
    align: "left",
  },
  {
    name: "TYPE DE PACKAGE",
    width: "60%",
    align: "left",
  },

  {
    name: "ACTION",
    width: "35%",
    align: "center",
  },
];
export const types_columns_en = [
  {
    name: "ID",
    width: "5%",
    align: "left",
  },
  {
    name: "PACKAGE TYPE NAME",
    width: "60%",
    align: "left",
  },

  {
    name: "ACTION",
    width: "35%",
    align: "center",
  },
];
