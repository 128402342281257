import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Outlet, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Api from "../api/Api";
import { Urls } from "../api/Urls";
import ListItemIcon from "@mui/material/ListItemIcon";
import { NavLink } from "react-router-dom";
import { colors } from "../theme/colors";
import PersonIcon from "../imgs/personIcon.svg";
import usersIcon from "../imgs/personsIcon.svg";
import arrowRightIcon from "../imgs/arrowRight.svg";
import notifIcon from "../imgs/notificationIcon.svg";
import tiquetsIcon from "../imgs/tiquetsIcon.svg";
import supportIcon from "../imgs/supportIcon.svg";
import travelIcon from "../imgs/travel.svg";
import paramIcon from "../imgs/parametreIcon.svg";
import expIcon from "../imgs/expeditionIcon.svg";
import kycIcon from "../imgs/kycIcon.svg";
import dashIcon from "../imgs/dashIcon.svg";
import "./styles/SharedLayout.css";
import circleIcon from "../imgs/circleIcon.svg";
import { useAuthContext } from "../hooks/useAuthContext";
import { useTranslation } from "react-i18next";
import colisIcon from "../imgs/collisIcon.svg";
import CustomLanguageSelector from "../components/ui/CustomLanguageSelector";

function AdminSharedLayout(props) {
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const { t } = useTranslation();
  // console.log("ACCESS_TOKEN", access_token);
  const drawerWidth = 300;
  const navigation = [
    { title: t("sharedLayout.navigations.dashboard"), path: "/admin/dashboard" },
    { title: t("sharedLayout.navigations.users"), path: "/admin/users" },
    { title: t("sharedLayout.navigations.transactions"), path: "/admin/transactions" },
    { title: t("sharedLayout.navigations.withdraw_requests"), path: "/admin/withdraw_requests" },
    { title: t("sharedLayout.navigations.kyc_docs"), path: "/admin/kyc" },
    { title: t("sharedLayout.navigations.shippings"), path: "/admin/shippings" },

    { title: t("sharedLayout.navigations.travel"), path: "/admin/voyages" },
    { title: t("sharedLayout.navigations.colis"), path: "/admin/colis" },
    { title: t("sharedLayout.navigations.package_type"), path: "/admin/type-colis" },
    { title: t("sharedLayout.navigations.settings"), path: "/admin/parametre" },
  ];

  const icons = {};
  icons[t("sharedLayout.navigations.dashboard")] = dashIcon;
  icons[t("sharedLayout.navigations.travel")] = travelIcon;
  icons[t("sharedLayout.navigations.shippings")] = travelIcon;

  icons[t("sharedLayout.navigations.transactions")] = expIcon;
  icons[t("sharedLayout.navigations.withdraw_requests")] = expIcon;
  icons[t("sharedLayout.navigations.users")] = usersIcon;
  icons[t("sharedLayout.navigations.kyc_docs")] = kycIcon;
  icons[t("sharedLayout.navigations.settings")] = paramIcon;
  icons[t("sharedLayout.navigations.colis")] = colisIcon;
  icons[t("sharedLayout.navigations.package_type")] = colisIcon;

  const imageSrc = null;

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    setAnchorElUser(null);

    Api.post(Urls.LOGOUT_ADMIN, null, {
      headers: { "accept-language": localStorage.getItem("i18nextLng"), "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        // console.log("Response Logout", response);
        if (response.status === 200) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log(error.response.data.message);
      });
  };

  const drawer = (
    <Box>
      <Toolbar sx={{ width: "100%" }}>
        <Box width="100%" display="flex" alignItems="end" justifyContent="space-between">
          <Box>
            <Typography variant="text_md">Sendyo Board</Typography>
          </Box>
          <Box>
            <img alt="" src={circleIcon} />
          </Box>
        </Box>
      </Toolbar>
      <Box>
        <List>
          <ListItem disablePadding>
            <NavLink style={{ textDecoration: "none" }} id="navlink" className={({ isActive }) => (isActive ? "active" : "inactive")} to={navigation[0].path}>
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <ListItemIcon sx={{ maxWidth: "20px" }}>
                    <img alt="" style={{ maxWidth: "20px", height: "20px", marginLeft: "20px" }} src={icons[`${navigation[0].title}`]} />
                  </ListItemIcon>
                  <ListItemText sx={{ color: "rgba(75, 70, 92 , 0.8)", fontSize: "15px" }} primary={navigation[0].title} />
                </Box>
                <Box marginRight="10px">
                  <img alt="" src={arrowRightIcon} />
                </Box>
              </Box>
            </NavLink>
          </ListItem>
        </List>
      </Box>
      <Box marginLeft="20px">
        <Typography variant="text_xs" sx={{ color: "rgba(75, 70, 92 , 0.5)" }}>
          Application
        </Typography>
      </Box>
      <List>
        {navigation.map((l, index) => (
          <ListItem key={index} disablePadding>
            {index !== 0 && (
              <NavLink style={{ textDecoration: "none" }} id="navlink" className={({ isActive }) => (isActive ? "active" : "inactive")} to={l.path}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <ListItemIcon>
                    <img alt="" style={{ width: "20px", marginLeft: "20px", height: "20px" }} src={icons[`${l.title}`]} />
                  </ListItemIcon>
                  <ListItemText sx={{ color: `rgba(75, 70, 92 , 0.8)`, fontSize: "15px" }} primary={l.title} />
                </Box>
              </NavLink>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
  //logout
  /*
  const handleLogout = () => {
    setAnchorElUser(null);

    Api.post(Urls.LOGOUT, null, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        // console.log("Response Logout", response);
        if (response.status === 200) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log(error.response.data.message);
      });
  };
  */
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { lg: `calc(100% - ${drawerWidth}px - 50px)`, md: `calc(100% - ${drawerWidth}px - 40px)`, sm: "100%", xs: `100%` },
          marginTop: { lg: "15px", md: "15px", sm: "0px", xs: "0px" },
          marginRight: { lg: "25px", md: "25px", sm: "0px", xs: "0px" },
          boxShadow: "0px 3px 5px rgba(0,0,0,0.08)",
        }}
      >
        <Toolbar sx={{ backgroundColor: `${colors.white}`, color: "white", display: "flex", justifyContent: "space-between" }}>
          <IconButton color="black" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: "none" } }}>
            <MenuIcon></MenuIcon>
          </IconButton>
          <Box></Box>
          <Box display="flex" gap="1.5rem" justifyContent="center" alignItems="center">
            <Box display="flex" gap="10px" alignItems="center">
              <IconButton>
                <img alt="" src={paramIcon} />
              </IconButton>
              <IconButton>
                <img alt="" src={notifIcon} />
              </IconButton>
              <IconButton onClick={handleOpenUserMenu}>
                <Avatar sx={{ width: "3rem", height: "3rem", backgroundColor: "transparent" }}>{imageSrc === null ? <img alt="" style={{ width: "3rem" }} src={PersonIcon} /> : <img alt="" style={{ width: "3rem" }} id="imgNavbar" src={imageSrc} />}</Avatar>
              </IconButton>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center">{t("buttons.disconnect")}</Typography>
                </MenuItem>
                <MenuItem>
                  <Typography onClick={() => navigate("/admin/mon-compte")} textAlign="center">
                    {t("buttons.profile")}
                  </Typography>
                </MenuItem>
              </Menu>
              <Box>
                <CustomLanguageSelector />
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />

        <Box maxWidth="100%" sx={{ padding: { md: "2rem", sm: "0.2rem" } }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

AdminSharedLayout.propTypes = {
  window: PropTypes.func,
};

export default AdminSharedLayout;
