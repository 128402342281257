export const user_columns_fr = [
  {
    name: "ID",
    width: "5%",
    align: "left",
  },
  {
    name: "UTILISATEUR",
    width: "20%",
    align: "left",
  },
  {
    name: "E-MAIL",
    width: "10%",
    align: "left",
  },
  {
    name: "TÉlÉPHONE",
    width: "15%",
    align: "center",
  },
  {
    name: "KYC LEVEL",
    width: "15%",
    align: "center",
  },
  {
    name: "ACTION",
    width: "35%",
    align: "center",
  },
];
export const user_columns_en = [
  {
    name: "ID",
    width: "5%",
    align: "left",
  },
  {
    name: "USER",
    width: "20%",
    align: "left",
  },
  {
    name: "EMAIL",
    width: "10%",
    align: "left",
  },
  {
    name: "PHONE",
    width: "15%",
    align: "center",
  },
  {
    name: "KYC LEVEL",
    width: "15%",
    align: "center",
  },
  {
    name: "ACTION",
    width: "35%",
    align: "center",
  },
];
