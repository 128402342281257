export const collis_columns_fr = [
  {
    name: "ID",
    width: "5%",
    align: "left",
  },
  {
    name: "TYPE",
    width: "10%",
    align: "left",
  },
  {
    name: "TITRE",
    width: "10%",
    align: "left",
  },
  {
    name: "DIMENSION",
    width: "20%",
    align: "left",
  },
  {
    name: "POID",
    width: "10%",
    align: "center",
  },
  {
    name: "STATUT",
    width: "15%",
    align: "center",
  },
  {
    name: "ACTION",
    width: "30%",
    align: "center",
  },
];
export const collis_columns_en = [
  {
    name: "ID",
    width: "5%",
    align: "left",
  },
  {
    name: "TYPE",
    width: "10%",
    align: "left",
  },
  {
    name: "TITLE",
    width: "10%",
    align: "left",
  },
  {
    name: "DIMENSION",
    width: "20%",
    align: "left",
  },
  {
    name: "WEIGHT",
    width: "10%",
    align: "center",
  },
  {
    name: "STATUS",
    width: "15%",
    align: "center",
  },
  {
    name: "ACTION",
    width: "30%",
    align: "center",
  },
];
