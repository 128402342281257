export const kyc_columns_fr = [
  {
    name: "REFERENCE",
    width: "5%",
    align: "left",
  },
  {
    name: "UTILISATEUR",
    width: "20%",
    align: "left",
  },
  {
    name: "NIVEAU",
    width: "10%",
    align: "left",
  },
  {
    name: "DATE",
    width: "15%",
    align: "center",
  },
  {
    name: "STATUT",
    width: "15%",
    align: "left",
  },
  {
    name: "ACTION",
    width: "35%",
    align: "center",
  },
];
export const kyc_columns_en = [
  {
    name: "REFERENCE",
    width: "5%",
    align: "left",
  },
  {
    name: "USER",
    width: "20%",
    align: "left",
  },
  {
    name: "LEVEL",
    width: "10%",
    align: "left",
  },
  {
    name: "DATE",
    width: "15%",
    align: "center",
  },
  {
    name: "STATUS",
    width: "15%",
    align: "left",
  },
  {
    name: "ACTION",
    width: "35%",
    align: "center",
  },
];
