import { Autocomplete, Box, Button, CircularProgress, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, tableCellClasses } from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import styled from "@emotion/styled";
import { types_columns_en, types_columns_fr } from "../../components/data/packageTypeColumns";
import { useEffect, useState } from "react";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { ToastContainer, toast } from "react-toastify";
import editIcon from "../../imgs/editIcon.svg";

import { useTranslation } from "react-i18next";
import ContentBox from "../../components/ui/ContentBox";
import ButtonSquare from "../../components/ui/ButtonSquare";
import CustomButton from "../../components/ui/CustomButton";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import { colors } from "../../theme/colors";
import FormikErrorText from "../../components/ui/FormikErrorText";
import { useParams } from "react-router-dom";
import { modalStyleOverflow } from "../../theme/ModalStyle";
const PackageType = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      paddingTop: "8px",
      paddingBottom: "8px",
      letterSpacing: "1px",
      color: `rgba(75,70,92,0.8)`,
      fontSize: 15,
      fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
      background: "white",
      fontSize: 15,
      color: `rgba(75,70,92,0.8)`,
      fontWeight: "500",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    ":hover": {
      filter: "brightness(0.95)",
    },
    cursor: "pointer",
  }));

  const StyledTable = styled(Table)({
    borderRadius: "6px",
  });
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const lang = localStorage.getItem("i18nextLng");
  const [page, setPage] = useState(1);
  const [openAddPackage, setOpenAddPackage] = useState(false);
  const { id } = useParams();
  const [contentTypes, setContentTypes] = useState([]);
  const [dataContent, setDataContent] = useState({});
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState({ open: false, id: null });
  const [columns, setColumns] = useState(lang === "fr" ? types_columns_fr : types_columns_en);
  const { t } = useTranslation();
  const [openEdit, setOpenEdit] = useState({ open: false, id: null });
  const initialValues = {
    package_type: "",
  };
  const [initialValuesUpdate, setIntialValuesUpdate] = useState({
    package_type: "",
  });
  const validationSchema = yup.object({
    package_type: yup.string().required(t("forms.validations.required")),
  });
  useEffect(() => {
    Api.get(Urls?.GET_CONTENT_TYPES + `?page=${id}`, {
      headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setDataContent(res?.data);
        setContentTypes(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  }, [update]);
  //handle Pagination
  const handlePagination = (link) => {
    //console.log("getting data");
    Api.get(`${link}`, {
      headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setDataContent(res?.data);
        setContentTypes(res.data?.data);
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
        setContentTypes([]);
      });
  };
  const handleSubmitCreate = (values) => {
    Api.post(
      Urls?.ADD_PACKAGE_TYPE,
      {
        type_name: values?.package_type,
      },
      {
        headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast?.success(res?.data?.message);
        setOpenAddPackage(false);
        setPage(1);
        setUpdate(!update);
      })
      .catch((err) => {
        toast?.error(err?.response.data?.message);
        setOpenAddPackage(false);
      });
  };
  const handleSubmitEdit = (values) => {
    Api.patch(
      Urls?.UpdatePackageByID(openEdit?.id),
      {
        type_name: values?.package_type,
      },
      {
        headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast?.success(res?.data?.message);
        setOpenEdit({ open: false, id: null });
        setPage(1);
        setUpdate(!update);
      })
      .catch((err) => {
        toast?.error(err?.response.data?.message);
        setOpenEdit({ open: false, id: null });
      });
  };
  const handleDeletePackage = () => {
    Api.delete(Urls?.DeletePackageByID(openDelete?.id), {
      headers: { "accept-language": localStorage.getItem("i18nextLng"), "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        toast?.success(res?.data?.message);
        setOpenDelete({ open: false, id: null });
        setPage(1);
        setUpdate(!update);
      })
      .catch((err) => {
        toast?.error(err?.response.data?.message);
        setOpenDelete({ open: false, id: null });
      });
  };
  const handleClickEdit = (obj) => {
    setIntialValuesUpdate({
      package_type: obj?.type_name,
    });
    setOpenEdit({ open: true, id: obj?.id });
  };
  return (
    <Box>
      <ToastContainer />
      <Box sx={{ maxWidth: "100%" }} display="flex" flexDirection="column" gap="24px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="text_md" color="rgba(0,0,0,0.5)">
            {t("sharedLayout.navigations.package_type")}
          </Typography>
          <CustomButton onClick={() => setOpenAddPackage(true)} startIcon={editIcon} text={t("buttons.create_package")} variant="primary" />
        </Box>
        <Box>
          <ContentBox>
            <Box display="flex" flexDirection="column" gap="16px">
              <Box>
                <Typography variant="text_18" color="rgba(75, 70, 92 , 0.9)">
                  {t("pages.titles.types_list")}
                </Typography>
              </Box>
              {loading && (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              )}
              {!loading && (
                <Box sx={{ overflow: "hidden", maxWidth: "100%" }}>
                  <TableContainer sx={{ borderRadius: "6px", border: `1px solid #DBDADE` }}>
                    <StyledTable aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          {columns.map((col, index) => (
                            <StyledTableCell key={index} width={col.width} align={col.align}>
                              {col.name}
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {contentTypes?.map((co) => (
                          <StyledTableRow>
                            <StyledTableCell align="left">
                              <Typography fontWeight="400" color="#3890E2" fontSize="15px">
                                #{co?.id}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">{co?.type_name}</StyledTableCell>
                            <StyledTableCell align="center">
                              <ButtonSquare onClick={() => handleClickEdit(co)} action="edit" />
                              <ButtonSquare onClick={() => setOpenDelete({ open: true, id: co?.id })} action="delete" />
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </StyledTable>
                  </TableContainer>
                </Box>
              )}
              {contentTypes?.length === 0 && !loading && <Typography textAlign="center">{t("pages.errors.no_packages_types")}</Typography>}
              {contentTypes.length > 0 && (
                <Box justifyContent="flex-end" display="flex" gap="4px">
                  {dataContent?.links?.prev && (
                    <ButtonSquare
                      onClick={() => {
                        handlePagination(dataContent?.links?.prev);
                        setPage(page - 1);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      action="Previous"
                    />
                  )}
                  <Box display="flex" gap="4px" justifyContent="center">
                    {dataContent?.meta?.links?.map((link) => (
                      <ButtonSquare
                        onClick={() => {
                          handlePagination(link.url);
                          setPage(link.label);
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                        action={link?.active ? "number-active" : "number"}
                        number={link.label}
                      />
                    ))}
                  </Box>
                  {dataContent?.links?.next && (
                    <ButtonSquare
                      onClick={() => {
                        handlePagination(dataContent?.links?.next);
                        setPage(page + 1);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      action="Next"
                    />
                  )}
                </Box>
              )}
            </Box>
          </ContentBox>
          <Modal open={openAddPackage} onClose={() => setOpenAddPackage(false)}>
            <Box sx={modalStyleOverflow}>
              <Box position="absolute" top="2px" right="2px">
                <ButtonSquare action="close" onClick={() => setOpenAddPackage(false)} />
              </Box>
              <Box>
                <Formik onSubmit={handleSubmitCreate} validationSchema={validationSchema} initialValues={initialValues} enableReinitialize={true}>
                  {({ errors, setFieldValue, values }) => (
                    <Form>
                      <Box marginBottom="26px">
                        <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                          {t("pages.titles.add_package")}
                        </Typography>
                      </Box>
                      <Box marginBottom="26px">
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          {t("forms.package_type")}{" "}
                        </Typography>
                        <Field style={{ width: "100%" }} name="package_type" type="input" id="package_type" as={TextField} autoComplete="off" placeholder={t("forms.package_type")} />
                        <ErrorMessage component={FormikErrorText} name="package_type" />
                      </Box>
                      <Box marginTop="10px" justifyContent="center" display="flex" gap="16px">
                        <Button type="submit" variant="primary">
                          {t("buttons.save")}
                        </Button>
                        <Button onClick={() => setOpenAddPackage(false)} variant="primary_inactive">
                          {t("buttons.cancel")}
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Modal>
          <Modal open={openEdit?.open} onClose={() => setOpenEdit({ open: false, id: null })}>
            <Box sx={modalStyleOverflow}>
              <Box position="absolute" top="2px" right="2px">
                <ButtonSquare action="close" onClick={() => setOpenEdit({ open: false, id: null })} />
              </Box>
              <Box>
                <Formik onSubmit={handleSubmitEdit} validationSchema={validationSchema} initialValues={initialValuesUpdate} enableReinitialize={true}>
                  {({ errors, setFieldValue, values }) => (
                    <Form>
                      <Box marginBottom="26px">
                        <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                          {t("pages.titles.edit_package")}
                        </Typography>
                      </Box>
                      <Box marginBottom="26px">
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          {t("forms.package_type")}{" "}
                        </Typography>
                        <Field style={{ width: "100%" }} name="package_type" type="input" id="package_type" as={TextField} autoComplete="off" placeholder={t("forms.package_type")} />
                        <ErrorMessage component={FormikErrorText} name="package_type" />
                      </Box>
                      <Box marginTop="10px" justifyContent="center" display="flex" gap="16px">
                        <Button type="submit" variant="primary">
                          {t("buttons.save")}
                        </Button>
                        <Button onClick={() => setOpenEdit({ open: false, id: null })} variant="primary_inactive">
                          {t("buttons.cancel")}
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Modal>
          <Modal open={openDelete?.open} onClose={() => setOpenDelete({ id: null, open: false })}>
            <Box sx={modalStyleOverflow}>
              <Box position="absolute" top="2px" right="2px">
                <ButtonSquare action="close" onClick={() => setOpenDelete({ id: null, open: false })} />
              </Box>
              <Box>
                <Box marginBottom="26px">
                  <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                    {t("pages.titles.delete_package")}
                  </Typography>
                </Box>
                <Box textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                  <Typography>{t("pages.messages.delete")}</Typography>
                </Box>
                <Box marginTop="10px" justifyContent="center" display="flex" gap="16px">
                  <Button type="submit" onClick={handleDeletePackage} variant="primary">
                    {t("buttons.save")}
                  </Button>
                  <Button onClick={() => setOpenDelete({ id: null, open: false })} variant="primary_inactive">
                    {t("buttons.cancel")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>
    </Box>
  );
};
export default PackageType;
