import React from "react";
import { Box, Typography } from "@mui/material";
import ContentBox from "../ui/ContentBox";
import personIcon from "../../imgs/personIconUser.svg";
import positionIcon from "../../imgs/positionIcon.svg";
import phoneIcon from "../../imgs/phoneIcon.svg";
import countryIcon from "../../imgs/countryIcon.svg";
import calendarIcon from "../../imgs/calendarIcon.svg";
import emailIcon from "../../imgs/emailIcon.svg";
import moment from "moment";
import { useTranslation } from "react-i18next";
function UserInfos({ userData }) {
  const { t } = useTranslation();
  return (
    <Box>
      <ContentBox>
        <Box display="flex" flexDirection="column" gap="27px">
          <Box display="flex" flexDirection="column" gap="17px">
            <Typography fontWeight="400" fontSize="13px" lineHeight="20px" color="#4B465C">
              {t("pages.titles.about")}
            </Typography>
            <Box display="flex" flexDirection="column" gap="14px">
              <Box display="flex" gap="6px" alignItems="start">
                <img src={personIcon} alt="position" />
                <Typography fontSize="15px" lineHeight="22px" fontWeight="600" color="#4B465C">
                  {t("pages.data.first_last_name")}
                </Typography>
                <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                  {userData?.profile?.first_name + " " + userData?.profile?.last_name}
                </Typography>
              </Box>
              <Box display="flex" gap="6px" alignItems="start">
                <img src={calendarIcon} alt="position" />
                <Typography fontSize="15px" lineHeight="22px" fontWeight="600" color="#4B465C">
                  {t("pages.data.birthday")}
                </Typography>
                <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                  {userData?.profile?.birthday ? moment(userData?.profile?.birthday).format("dddd, DD MMMM yyyy") : "-"}
                </Typography>
              </Box>
              <Box display="flex" gap="6px" alignItems="start">
                <img src={countryIcon} alt="position" />
                <Typography fontSize="15px" lineHeight="22px" fontWeight="600" color="#4B465C">
                  {t("pages.data.country")}
                </Typography>
                <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                  {userData?.profile?.country ? userData?.profile?.country : "-"}
                </Typography>
              </Box>
              <Box display="flex" gap="6px" alignItems="start">
                <img src={positionIcon} alt="position" />
                <Typography fontSize="15px" lineHeight="22px" fontWeight="600" color="#4B465C">
                  {t("pages.data.adress")}
                </Typography>
                <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                  {userData?.profile?.adress ? userData?.profile?.adress : "-"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap="17px">
            <Typography fontWeight="400" fontSize="13px" lineHeight="20px" color="#4B465C">
              {t("pages.titles.contacts")}
            </Typography>
            <Box display="flex" flexDirection="column" gap="14px">
              <Box display="flex" gap="6px" alignItems="start">
                <img src={phoneIcon} alt="position" />
                <Typography fontSize="15px" lineHeight="22px" fontWeight="600" color="#4B465C">
                  {t("pages.data.contact")}
                </Typography>
                <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                  {userData?.profile?.phone_number ? userData?.profile?.phone_number : "-"}
                </Typography>
              </Box>
              <Box display="flex" gap="6px" alignItems="start">
                <img src={emailIcon} alt="position" />
                <Typography fontSize="15px" lineHeight="22px" fontWeight="600" color="#4B465C">
                  {t("pages.data.email")}
                </Typography>
                <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                  {userData?.email ? userData?.email : "-"}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </ContentBox>
    </Box>
  );
}

export default UserInfos;
