import { Autocomplete, Box, CircularProgress, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { colors } from "../../theme/colors";
import ContentBox from "../../components/ui/ContentBox";
import dollarIcon from "../../imgs/dollarIcon.svg";
import Statistics from "../../components/cards/Statistics";
import TripsInformation from "../../components/cards/TripsInformation";
import Chart from "../../components/chart/Chart";
import Colis from "../colis/Colis";
import Voyages from "../voyages/Voyages";
import { useAuthContext } from "../../hooks/useAuthContext";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Shippings from "../shippings/Shippings";

const Dashboard = () => {
  const [currency, setCurrency] = useState(null);
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [chartData, setChartData] = useState(null);
  useEffect(() => {
    Api.get(Urls.GetCurrencies, {
      headers: { "accept-language": localStorage.getItem("i18nextLng"), "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setCurrencies(res?.data?.data);
        setCurrency(res?.data?.data[0]);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, []);
  useEffect(() => {
    if (currency?.id) {
      setLoading(true);
      Api.get(Urls.GetDashboardChart, {
        headers: { "accept-language": localStorage.getItem("i18nextLng"), "content-type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          currencyId: currency?.id,
          fromDate: startDate,
          toDate: endDate,
        },
      })
        .then((res) => {
          setChartData(res?.data?.data);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          setLoading(false);
        });
    }
  }, [currency, startDate, endDate]);
  useEffect(() => {
    Api.get(
      Urls.TestRouteGetDashboardData,

      {
        headers: { "accept-language": localStorage.getItem("i18nextLng"), "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setData(res?.data?.data);
        setChartData(res?.data?.chart);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, []);
  function formatNumber(num) {
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + "k";
    }
    return num.toString();
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <ToastContainer />
      <Box>
        <Typography sx={{ fontSize: "36px", fontWeight: "700", color: "black" }}>{t("pages.titles.dashboard")}</Typography>
        <Typography sx={{ fontSize: "24px", fontWeight: "500", color: "black" }}>{t("pages.titles.global_stats")}</Typography>
      </Box>
      <Box sx={{ display: "flex", gap: "24px", flexWrap: "wrap" }}>
        <Box sx={{ flex: 1 }}>
          <ContentBox width={"100%"}>
            <Box display="flex" flexDirection="column" gap="17px">
              <Typography fontWeight="400" fontSize="13px" lineHeight="20px" color="#4B465C">
                {t("pages.titles.wallet_informations")}
              </Typography>
              <Box sx={{ display: "flex", gap: "24px", flexWrap: "wrap" }}>
                <Box display="flex" flexDirection="column" gap="14px">
                  {data?.wallets?.map((data) => (
                    <Box display="flex" gap="6px" alignItems="start">
                      <Typography fontSize="15px" lineHeight="22px" fontWeight="600" color="#4B465C">
                        {t("pages.data.total_wallet")} ({data?.currency?.code?.toUpperCase()}):
                      </Typography>
                      <Typography fontSize="15px" lineHeight="22px" fontWeight="600" color={colors.main}>
                        {formatNumber(data?.sum)} {data?.currency?.code?.toUpperCase()}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </ContentBox>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Statistics packages={data?.packages?.total} trips={data?.trips?.total} users={data?.users?.total} />
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: "24px", flexWrap: "wrap" }}>
        <Box flex={"1.5"}>
          <TripsInformation data={data?.shippings} />
        </Box>
        <Box flex={"3"}>
          <ContentBox>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <Box sx={{ width: "100%", display: "flex", gap: "16px", flexWrap: "wrap" }}>
                '
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Box sx={{ maxWidth: "200px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        value={startDate}
                        maxDate={endDate ? endDate : new Date()}
                        onChange={(value) => {
                          setStartDate(new Date(value));
                        }}
                        views={["month", "year"]}
                        InputProps={{ sx: { "& .MuiSvgIcon-root": { width: "40px", height: "40px" }, "& .MuiButtonBase-root": { borderRadius: "15px", padding: 0 } } }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box sx={{ maxWidth: "200px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        value={endDate}
                        minDate={startDate}
                        onChange={(value) => {
                          let date = new Date(value);
                          date?.setDate(date?.getDate() + 1);
                          setEndDate(new Date(date));
                        }}
                        views={["month", "year"]}
                        InputProps={{ sx: { "& .MuiSvgIcon-root": { width: "40px", height: "40px" }, "& .MuiButtonBase-root": { borderRadius: "15px", padding: 0 } } }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
                <Autocomplete
                  sx={{ minWidth: "200px" }}
                  value={currency}
                  options={currencies}
                  disableClearable
                  getOptionLabel={(e) => e?.code}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  onChange={(event, value) => {
                    if (value !== null) {
                      setCurrency(value);
                    } else {
                      setCurrency(null);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} name="autocomplete" placeholder={t("forms.currency")} />}
                />{" "}
              </Box>
              {loading && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              )}
              {chartData && !loading && <Chart data={chartData} />}
            </Box>
          </ContentBox>
        </Box>
      </Box>
      <Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Shippings dashboard={true} />
        </Box>
      </Box>
    </Box>
  );
};
export default Dashboard;
