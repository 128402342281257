import { Avatar, Box, Button, IconButton, Modal, Rating, Typography } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useEffect, useMemo, useState } from "react";
import Api from "../../api/Api.js";
import { Urls } from "../../api/Urls.js";
import { toast } from "react-toastify";
import ReceptionRequestsComponent from "../../components/cards/ReceptionRequestsComponent.js";
import { modalStyleOverflowDetails } from "../../theme/ModalStyle.js";
import moment from "moment";
import { colors } from "../../theme/colors.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons/faClose";
import CircularProgress from "@mui/material/CircularProgress";
import ImagesCarousel from "../../components/imagesCarousel/ImagesCarousel.js";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../hooks/useAuthContext.js";
import ButtonSquare from "../../components/ui/ButtonSquare.js";
import { Close } from "@mui/icons-material";
import Shippings from "../shippings/Shippings.js";
function ReceptionRequests({ id }) {
  const pageSize = 4;
  const { user } = useAuthContext();
  const [loadingPackage, setLoadingPackage] = useState(false);
  const [loading, setLoading] = useState(true);

  const [openViewShippings, setOpenViewShippings] = useState({
    open: false,
    data: null,
  });
  const [openViewImages, setOpenViewImages] = useState({
    open: false,
    selectedImage: null,
    images: null,
  });
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const access_token = user?.access_token;
  const [openViewDetails, setOpenViewDetails] = useState({
    open: false,
    id: null,
    data: null,
  });
  const { t } = useTranslation();
  const [receptionRequests, setReceptionRequests] = useState([]);
  const [receptionRequestsDetails, setReceptionRequestsDetails] = useState({});
  const [pagesList, setPagesList] = useState([]);

  useEffect(() => {
    Api.get(Urls.GetAllReceptionRequestsByPackageID(id), {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${access_token}`,
        "accept-language": localStorage.getItem("i18nextLng"),
      },
      params: {
        pageSize: pageSize,
        page: page,
      },
    })
      .then((res) => {
        setReceptionRequests(res?.data?.data);
        setLoading(false);
        setData(res?.data);
        if (pagesList?.length === 0) {
          let result = res?.data?.pagination?.total / pageSize;
          let array = [];
          if (result <= 1) {
            setPagesList([1]);
          } else {
            if (Number?.isInteger(result)) {
              for (let index = 1; index <= result; index++) {
                array.push(index);
              }
            } else {
              for (let index = 0; index <= result; index++) {
                array.push(index + 1);
              }
            }
            setPagesList(array);
          }
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  }, [page]);
  useEffect(() => {
    if (openViewDetails?.id) {
      setReceptionRequestsDetails(openViewDetails?.data);
      setLoadingPackage(false);
    }
  }, [openViewDetails?.id]);
  return (
    <>
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            {receptionRequests?.length === 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  onClick={() =>
                    setOpenViewShippings({
                      open: true,
                      data: openViewDetails?.data,
                    })
                  }
                  sx={{ fontSize: "46px", fontWeight: "700", color: "black" }}
                >
                  {t("pages.reception_requests.nothing")}
                </Typography>
              </Box>
            )}
            {receptionRequests?.map((request, index) => (
              <Box
                key={index}
                sx={{
                  boxShadow: "0px 4px 18px 0px rgba(75, 70, 92, 0.25)",
                  borderRadius: "12px",
                }}
              >
                <Box sx={{ margin: "10px", display: "flex", justifyContent: "end" }}>
                  <Typography
                    onClick={() =>
                      setOpenViewShippings({
                        open: true,
                        data: request,
                      })
                    }
                    sx={{ fontSize: "18px", textDecoration: "underline", cursor: "pointer" }}
                  >
                    {t("pages.titles.shipping_requests")}
                  </Typography>
                </Box>

                {request?.status === "refused" && (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "end",
                      margin: "5px 15px",
                    }}
                  >
                    <Button variant={"primary_red_decline"}>{request?.status_display}</Button>
                  </Box>
                )}
                {request?.status === "canceled" && (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "end",
                      margin: "5px 15px",
                    }}
                  >
                    <Button variant={"primary_red_decline"}>{request?.status_display}</Button>
                  </Box>
                )}
                {request?.status === "accepted" && (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "end",
                      margin: "5px 15px",
                    }}
                  >
                    <Button variant={"primary_green"}>{request?.status_display}</Button>
                  </Box>
                )}
                <Box
                  onClick={() => {
                    setLoadingPackage(true);
                    setOpenViewDetails({
                      open: true,
                      id: request?.id,
                      data: request,
                    });
                  }}
                >
                  <ReceptionRequestsComponent kyc_verified={request?.sender_id?.identity_kyc_status === "verified" && true} image_url={request?.sender_id?.file_url} first_name={request?.sender_id?.first_name} last_name={request?.sender_id?.last_name} package_type={request.package_id?.content_type?.type_name} package_width={request.package_id?.dimensions?.width} package_height={request.package_id?.dimensions?.height} package_description={request.package_id?.description} package_length={request.package_id?.dimensions?.length} package_weight={request.package_id?.weight} note={request?.sender_id?.rating === 0 && request?.sender_id?.number_of_ratings === 0 ? "No rating" : request?.sender_id?.rating} />
                </Box>
              </Box>
            ))}
          </Box>
          <Modal
            open={openViewDetails?.open}
            onClose={() => {
              setOpenViewDetails({
                open: false,
                id: null,
                data: null,
              });
            }}
          >
            <Box sx={modalStyleOverflowDetails} minHeight={"50vh"}>
              {!loadingPackage && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    minHeight: "50vh",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <IconButton
                      onClick={() => {
                        setOpenViewDetails({
                          open: false,
                          id: null,
                          data: null,
                        });
                      }}
                      sx={{ padding: "5px !important" }}
                    >
                      <FontAwesomeIcon icon={faClose} size={"1x"} color={"black"} />
                    </IconButton>
                  </Box>
                  <Box sx={{ display: "flex", gap: "30px", flexWrap: "wrap" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      {" "}
                      <Box sx={{ position: "relative" }}>
                        {receptionRequestsDetails?.sender_id?.identity_kyc_status === "verified" && (
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: "0px",
                              zIndex: "999",
                              left: {
                                xs: "145px",
                                sm: "145px",
                                md: "145px",
                                lg: "145px",
                              },
                            }}
                          >
                            <VerifiedIcon
                              sx={{
                                color: colors.primary,
                                fontSize: "40px",
                              }}
                            />
                          </Box>
                        )}
                        <Avatar
                          src={receptionRequestsDetails?.sender_id?.file_url}
                          variant={"circular"}
                          sx={{
                            height: "180px",
                            width: "180px",
                            border: "4px solid " + colors.primary,
                          }}
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          textAlign: "center",
                          fontWeight: "400",
                        }}
                      >
                        {t("pages.reception_requests.nember_since")} {moment(receptionRequestsDetails?.created_at).format("MMMM, YYYY")}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: "24px", fontWeight: "700" }}>
                        {receptionRequestsDetails?.sender_id?.first_name} {receptionRequestsDetails?.sender_id?.last_name}
                      </Typography>
                      {receptionRequestsDetails?.sender_id?.rating === 0 && receptionRequestsDetails?.sender_id?.number_of_ratings === 0 ? <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>{t("pages.reception_requests.no_rating")}</Typography> : <Rating name="size-large" defaultValue={receptionRequestsDetails?.sender_id?.rating} readOnly size="large" />}
                      <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>{receptionRequestsDetails?.sender_id?.bio ? receptionRequestsDetails?.sender_id?.bio : "-"}</Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "70px",
                      border: "2px solid " + colors?.primary,
                    }}
                  ></Box>
                  <Box>
                    <Typography sx={{ fontSize: "24px", fontWeight: "700" }}>{t("pages.reception_requests.description")}</Typography>
                    <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>{receptionRequestsDetails?.package_id?.description ? receptionRequestsDetails?.package_id?.description : "-"}</Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "70px",
                      border: "2px solid " + colors?.primary,
                    }}
                  ></Box>
                  <Box>
                    <Typography sx={{ fontSize: "24px", fontWeight: "700" }}>{t("pages.reception_requests.details")}</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#A7A9B7",
                        }}
                      >
                        {receptionRequestsDetails?.package_id?.content_type?.type_name}
                      </Typography>
                      <Box sx={{ height: "20px", borderLeft: "2px solid #A7A9B7" }}></Box>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#A7A9B7",
                        }}
                      >
                        {receptionRequestsDetails?.package_id?.weight} Kg
                      </Typography>{" "}
                      <Box sx={{ height: "20px", borderLeft: "2px solid #A7A9B7" }}></Box>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#A7A9B7",
                        }}
                      >
                        W {receptionRequestsDetails?.package_id?.dimensions?.width} cm /
                      </Typography>{" "}
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#A7A9B7",
                        }}
                      >
                        L {receptionRequestsDetails?.package_id?.dimensions?.length} cm /
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#A7A9B7",
                        }}
                      >
                        H {receptionRequestsDetails?.package_id?.dimensions?.height} cm
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                    {receptionRequestsDetails?.package_id?.file_urls?.map((file, index) => (
                      <Box key={index}>
                        <img
                          onClick={() => {
                            setOpenViewImages({
                              open: true,
                              images: receptionRequestsDetails?.package_id?.file_urls,
                              selectedImage: index,
                            });
                          }}
                          src={file}
                          alt={"package"}
                          style={{
                            height: "200px",
                            width: "150px",
                            objectFit: "cover",
                            borderRadius: "18px",
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </Modal>
          {receptionRequests?.length > 0 && (
            <Box justifyContent="flex-end" display="flex" gap="4px">
              {page > 1 && (
                <ButtonSquare
                  onClick={() => {
                    setPage(page - 1);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  action="Previous"
                />
              )}
              <Box display="flex" gap="4px" justifyContent="center">
                {pagesList.map((p) => (
                  <ButtonSquare
                    onClick={() => {
                      setPage(p);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    action={p === page ? "number-active" : "number"}
                    number={p}
                  />
                ))}
              </Box>
              {data?.pagination?.total - pageSize * page > 0 && (
                <ButtonSquare
                  onClick={() => {
                    setPage(page + 1);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  action="Next"
                />
              )}
            </Box>
          )}
        </Box>
      )}
      <Modal open={openViewShippings?.open} onClose={() => setOpenViewShippings({ open: false, data: null })}>
        <Box sx={modalStyleOverflowDetails}>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <IconButton
              onClick={() => {
                setOpenViewShippings({ open: false, data: null });
              }}
            >
              <Close />
            </IconButton>
          </Box>
          <Box sx={{ margin: "10px 0px" }}>
            <Shippings reception_request_id={openViewShippings?.data?.id} />
          </Box>
        </Box>
      </Modal>
      {openViewImages?.open && <ImagesCarousel open={openViewImages} setOpen={setOpenViewImages} />}
    </>
  );
}
export default ReceptionRequests;
