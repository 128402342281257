import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlaneDeparture } from "@fortawesome/free-solid-svg-icons/faPlaneDeparture";
import { Avatar, Box, Typography } from "@mui/material";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { colors } from "../../theme/colors.js";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useTranslation } from "react-i18next";
function ReceptionRequestsComponent({ image_url, note, first_name, last_name, package_type, package_description, package_width, package_height, kyc_verified, package_length, package_weight }) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "12px",
        backgroundColor: "white",
        gap: "20px",
        cursor: "pointer",
        ":hover": {
          filter: "brightness(0.95)",
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: "#52C1FF",
          display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
          justifyContent: "center",
          gap: "10px",
          flexWrap: "wrap",
          padding: "16px",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
        }}
      >
        <Typography sx={{ fontSize: "18px", fontWeight: "600", color: "white" }}>{package_type}</Typography>
        <Box sx={{ height: "20px", borderLeft: "2px solid white" }}></Box>
        <Typography sx={{ fontSize: "18px", fontWeight: "600", color: "white" }}>{package_weight} Kg</Typography>{" "}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Box sx={{ height: "20px", borderLeft: "2px solid white" }}></Box>
          <Typography sx={{ fontSize: "18px", fontWeight: "600", color: "white" }}>W {package_width}cm /</Typography> <Typography sx={{ fontSize: "18px", fontWeight: "600", color: "white" }}>L {package_length}cm /</Typography>
          <Typography sx={{ fontSize: "18px", fontWeight: "600", color: "white" }}>H {package_height}cm</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: { xs: "12px", sm: "12px", md: "80px", lg: "80px" },
          padding: "15px 30px 0px 20px",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: "30px" }}>
          <Box sx={{ flex: 1 }}>
            <Box sx={{ position: "relative" }}>
              {kyc_verified && (
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "-5px",
                    zIndex: "999",
                    left: {
                      xs: "83px",
                      sm: "83px",
                      md: "75px",
                      lg: "75px",
                    },
                  }}
                >
                  <VerifiedIcon
                    sx={{
                      color: colors.primary,
                      fontSize: "30px",
                    }}
                  />
                </Box>
              )}
              <Avatar
                sx={{
                  border: "4px solid " + colors.primary,

                  height: { xs: "102px", sm: "102px", md: "92px", lg: "92px" },
                  width: { xs: "102px", sm: "102px", md: "92px", lg: "92px" },
                }}
                src={image_url}
                variant={"circular"}
              />
            </Box>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "20px", fontWeight: "700", color: "black" }}>{t("pages.reception_requests.description")}:</Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "400", maxWidth: "1000px" }}>{package_description ? package_description : t("pages.reception_requests.no_description")}</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: "20px",
          gap: "24px",
          borderTop: "1px solid #D9D9D9",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            minWidth: "165px",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <FontAwesomeIcon icon={faPlaneDeparture} color={"#1A2E35"} size={"1x"} />
          <Typography sx={{ fontSize: "15px", fontWeight: "700", color: "#1A2E35" }}>
            {first_name} {last_name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "2px",
              flexWrap: "wrap",
            }}
          >
            <Typography sx={{ color: "#1A2E35" }}>{note}</Typography>
            {note !== "No rating" && (
              <Box sx={{ marginBottom: "4px" }}>
                <FontAwesomeIcon icon={faStar} color={"#1A2E35"} size={"1x"} />
              </Box>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            padding: "10px",
            backgroundColor: "#52C1FF",
            borderBottomRightRadius: "12px",
            display: { xs: "none", sm: "none", lg: "flex", md: "flex" },
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "white" }}>{package_type}</Typography>
          <Box sx={{ height: "20px", borderLeft: "2px solid white" }}></Box>
          <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "white" }}>{package_weight} Kg</Typography> <Box sx={{ height: "20px", borderLeft: "2px solid white" }}></Box>
          <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "white" }}>W {package_width}cm /</Typography> <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "white" }}>L {package_length}cm /</Typography>
          <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "white" }}>H {package_height}cm</Typography>
        </Box>
      </Box>
    </Box>
  );
}
export default ReceptionRequestsComponent;
