import React from "react";
import { Button, Typography } from "@mui/material";
import { colors } from "../../theme/colors";
import xIcon from "../../imgs/xIcon.svg";
import eyeIcon from "../../imgs/eye.svg";
import editIcon from "../../imgs/editIcon.svg";
import { useTranslation } from "react-i18next";
import deleteIcon from "../../imgs/delete.svg";
function ButtonSquare({ text, number, action, ...props }) {
  const { t } = useTranslation();
  switch (action) {
    case "close":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ width: "35px", height: "30px", borderRadius: "6px", "&:hover": { backgroundColor: `#f0f0f0` }, textTransform: "none" }}>
          <img src={xIcon} alt="close-icon" />
        </Button>
      );
    case "number-active":
      return (
        <Button {...props} onClick={props.onClick} sx={{ display: `${number === "Next &raquo;" || number === "&laquo; Previous" ? "none" : "inherit"}`, height: "35px", width: "40px", borderRadius: "6px", padding: "0", backgroundColor: `${colors.main}`, "&:hover": { backgroundColor: colors.main } }}>
          <Typography color="#4B465C">{number}</Typography>
        </Button>
      );
    case "number":
      return (
        <Button {...props} onClick={props.onClick} sx={{ color: "#4B465C", display: `${number === "Next &raquo;" || number === "&laquo; Previous" ? "none" : "inherit"}`, height: "35px", width: "40px", borderRadius: "6px", padding: "0", backgroundColor: `rgba(75, 70, 92, 0.08)`, "&:hover": { color: "white", backgroundColor: colors.main } }}>
          <Typography>{number}</Typography>
        </Button>
      );
    case "Next":
      return (
        <Button {...props} onClick={props.onClick} sx={{ color: "#4B465C", padding: "0px 10px", borderRadius: "6px", backgroundColor: `rgba(75, 70, 92, 0.08)`, "&:hover": { color: "white", backgroundColor: colors.main } }}>
          <Typography fontSize="15px" fontWeight="500" lineHeight="18px">
            {t("pages.pagination.next")}
          </Typography>
        </Button>
      );
    case "Previous":
      return (
        <Button {...props} onClick={props.onClick} sx={{ color: "#4B465C", padding: "0px 10px", borderRadius: "6px", backgroundColor: `rgba(75, 70, 92, 0.08)`, "&:hover": { color: "white", backgroundColor: colors.main } }}>
          <Typography fontSize="15px" fontWeight="500" lineHeight="18px">
            {t("pages.pagination.previous")}
          </Typography>
        </Button>
      );
    case "show_outlined":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ border: "transparent", width: "35px", height: "35px", borderRadius: "6px", "&:hover": { backgroundColor: `#f0f0f0` }, textTransform: "none" }}>
          <img src={eyeIcon} alt="modify-icon" />
        </Button>
      );
    case "edit":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ border: "transparent", width: "35px", height: "35px", borderRadius: "6px", "&:hover": { backgroundColor: `#f0f0f0` }, textTransform: "none" }}>
          <img src={editIcon} alt="modify-icon" />
        </Button>
      );
    case "delete":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ border: "transparent", width: "35px", height: "35px", borderRadius: "6px", "&:hover": { backgroundColor: `#f0f0f0` }, textTransform: "none" }}>
          <img src={deleteIcon} alt="modify-icon" />
        </Button>
      );
    case "canceled_shipping":
      return (
        <Button
          {...props}
          onClick={props.onClick}
          sx={{
            color: "white",

            minHeight: "35px",
            minWidth: "100px",
            borderRadius: "4px",
            padding: "0px 10px",
            border: "1px solid #B00020",
            fontWeight: "500",
            fontSize: "18px",
            backgroundColor: `#B00020`,
            "&:hover": {
              color: "white",
              border: "1px solid #B00020",
              backgroundColor: "#B00020",
              fontWeight: "500",
            },
          }}
        >
          {text}
        </Button>
      );
    case "completed_shipping":
      return (
        <Button
          {...props}
          onClick={props.onClick}
          sx={{
            color: "white",

            minHeight: "35px",
            minWidth: "100px",
            borderRadius: "4px",
            padding: "0px 10px",
            border: "1px solid #00C21F",
            fontWeight: "500",
            fontSize: "18px",
            backgroundColor: `#00C21F`,
            "&:hover": {
              color: "white",
              border: "1px solid #00C21F",
              backgroundColor: "#00C21F",
              fontWeight: "500",
            },
          }}
        >
          {text}
        </Button>
      );
    case "rejected_shipping":
      return (
        <Button
          {...props}
          onClick={props.onClick}
          sx={{
            color: colors?.primary,

            minHeight: "35px",
            minWidth: "100px",
            borderRadius: "4px",
            padding: "0px 10px",
            border: "1px solid #B00020",
            fontWeight: "500",
            fontSize: "18px",
            backgroundColor: `#B00020`,
            "&:hover": {
              color: "white",
              border: "1px solid #B00020",
              backgroundColor: "#B00020",
              fontWeight: "500",
            },
          }}
        >
          {text}
        </Button>
      );
    case "accepted_shipping":
      return (
        <Button
          {...props}
          onClick={props.onClick}
          sx={{
            color: "white",

            minHeight: "35px",
            minWidth: "100px",
            borderRadius: "4px",
            padding: "0px 10px",
            border: "1px solid #52C1FF",
            fontWeight: "500",
            fontSize: "18px",
            backgroundColor: `#52C1FF`,
            "&:hover": {
              color: "white",
              border: "1px solid #52C1FF",
              backgroundColor: "#52C1FF",
              fontWeight: "500",
            },
          }}
        >
          {text}
        </Button>
      );
    case "pending_shipping":
      return (
        <Button
          {...props}
          onClick={props.onClick}
          sx={{
            color: "black",

            minHeight: "35px",
            minWidth: "100px",
            borderRadius: "4px",
            padding: "0px 10px",
            border: "1px solid #FFFF00",
            fontWeight: "500",
            fontSize: "18px",
            backgroundColor: `#FFFF00`,
            "&:hover": {
              color: "black",
              border: "1px solid #FFFF00",
              backgroundColor: "#FFFF00",
              fontWeight: "500",
            },
          }}
        >
          {text}
        </Button>
      );
    case "pending":
      return (
        <Button
          {...props}
          onClick={props.onClick}
          sx={{
            color: "black",

            minHeight: "35px",
            minWidth: "100px",
            borderRadius: "4px",
            padding: "0px 10px",
            border: "1px solid #FFFF00",
            fontWeight: "500",
            fontSize: "18px",
            backgroundColor: `#FFFF00`,
            "&:hover": {
              color: "black",
              border: "1px solid #FFFF00",
              backgroundColor: "#FFFF00",
              fontWeight: "500",
            },
          }}
        >
          {text}
        </Button>
      );
    case "picked_up_shipping":
      return (
        <Button
          {...props}
          onClick={props.onClick}
          sx={{
            color: "white",

            minHeight: "35px",
            minWidth: "100px",
            borderRadius: "4px",
            padding: "0px 10px",
            border: "1px solid " + colors?.primary,
            fontWeight: "500",
            fontSize: "18px",
            backgroundColor: colors?.primary,
            "&:hover": {
              color: "white",
              border: "1px solid " + colors?.primary,
              backgroundColor: colors?.primary,
              fontWeight: "500",
            },
          }}
        >
          {text}
        </Button>
      );
    case "delivered_shipping":
      return (
        <Button
          {...props}
          onClick={props.onClick}
          sx={{
            color: "white",
            minHeight: "35px",
            minWidth: "100px",
            borderRadius: "4px",
            padding: "0px 10px !important",
            border: "1px solid #00C21F",
            fontWeight: "500",
            fontSize: "18px",
            backgroundColor: `#00C21F`,
            "&:hover": {
              color: "white",
              border: "1px solid #00C21F",
              backgroundColor: "#00C21F",
              fontWeight: "500",
            },
          }}
        >
          {text}
        </Button>
      );
  }
}

export default ButtonSquare;
