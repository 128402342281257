export const exp_columns_fr = [
  {
    name: "ID",
    width: "5%",
    align: "left",
  },
  {
    name: "EXPÉDITEUR",
    width: "10%",
    align: "left",
  },
  {
    name: "DESTINATAIRE",
    width: "10%",
    align: "left",
  },
  {
    name: "VOYAGE",
    width: "15%",
    align: "center",
  },

  {
    name: "DIMENSIONS DU COLIS",
    width: "10%",
    align: "center",
  },
  {
    name: "MONTANT",
    width: "10%",
    align: "center",
  },
  {
    name: "COLIS",
    width: "10%",
    align: "center",
  },
  {
    name: "STATUT",
    width: "10%",
    align: "center",
  },
  {
    name: "ACTION",
    width: "5%",
    align: "center",
  },
];
export const exp_columns_en = [
  {
    name: "ID",
    width: "5%",
    align: "left",
  },
  {
    name: "Sender", // Translated "EXPÉDITEUR"
    width: "10%",
    align: "left",
  },
  {
    name: "Recipient", // Translated "DESTINATAIRE"
    width: "10%",
    align: "left",
  },
  {
    name: "Shipment", // Translated "VOYAGE"
    width: "10%",
    align: "center",
  },

  {
    name: "Package Dimensions", // Translated "DIMENSIONS DU COLIS"
    width: "15%",
    align: "center",
  },
  {
    name: "Amount", // Translated "MONTANT"
    width: "10%",
    align: "center",
  },
  {
    name: "Package", // Translated "COLIS"
    width: "10%",
    align: "center",
  },
  {
    name: "Status", // Translated "STATUT"
    width: "10%",
    align: "center",
  },
  {
    name: "Action", // Translated "ACTION"
    width: "5%",
    align: "center",
  },
];
