import "./App.css";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import AdminSharedLayout from "./pages/AdminSharedLayout";
import Login from "./pages/authentification/Login";
import { useAuthContext } from "./hooks/useAuthContext";
import "react-toastify/dist/ReactToastify.css";
import ResetPass from "./pages/authentification/ResetPass";
import EnterOtpResetPassword from "./pages/authentification/EnterOtpResetPassword";
import KycDocuments from "./pages/kyc/KycDocumetns";
import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import ListeUtilisateur from "./pages/users/ListeUtilisateurs";
import UserDetails from "./pages/users/details/UserDetails";
import Tiquets from "./pages/Tiquets";
import Transactions from "./pages/transactions/Transactions";
import Colis from "./pages/colis/Colis";
import PackageType from "./pages/packageType/PackageType";
import Voyages from "./pages/voyages/Voyages";
import WithdrawMethods from "./pages/withdrawRequests/WithdrawRequests";
import Settings from "./pages/settings/Settings";
import Shippings from "./pages/shippings/Shippings";

function App() {
  const [loadingUser, setLoadingUser] = useState(true);
  const { user, loading } = useAuthContext();
  useEffect(() => {
    if (loading === false) {
      setLoadingUser(false);
    }
  }, [user]);
  return (
    <>
      {loadingUser && (
        <Box minHeight="100vh" width="100%" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
      {!loadingUser && (
        <>
          <Routes>
            <Route exact path="/" element={<Navigate to={"/login"} />} />
            <Route path="" element={!user && <Navigate to="/login" />} />
            <Route path="mot-de-passe-oublier" element={user?.access_token ? <Navigate to="/admin" replace /> : <ResetPass />} />
            <Route path="otp-mot-de-passe-oublier" element={user?.access_token ? <Navigate to="/admin" replace /> : <EnterOtpResetPassword />} />
            <Route path="login" element={user ? <Navigate to="/admin/dashboard" replace /> : <Login />} />
            <Route path="/" element={<Outlet />} />
            <Route path="/admin" element={user ? <AdminSharedLayout /> : <Login />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="users">
                <Route index element={<ListeUtilisateur />} />
                <Route path="/admin/users/:id" element={<UserDetails />} />
              </Route>
              <Route path="transactions">
                <Route index element={<Transactions />} />
              </Route>
              <Route path="withdraw_requests">
                <Route index element={<WithdrawMethods />} />
              </Route>
              <Route path="colis">
                <Route index element={<Colis showColis={true} />} />
              </Route>
              <Route path="type-colis">
                <Route index element={<PackageType />} />
              </Route>
              <Route path="shippings">
                <Route index element={<Shippings />} />
              </Route>
              <Route path="voyages" element={<Voyages showTitle={true} />} />
              <Route path="parametre" element={<Settings />} />
              <Route path="tiquets" element={<Tiquets />} />
              <Route path="support" element={<div>support</div>} />
              <Route path="kyc" element={<KycDocuments />} />
              <Route path="notifications" element={<div>notifications</div>} />
              <Route path="expeditions" element={<div>expeditions</div>} />
            </Route>
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
