import React, { useEffect, useState } from "react";
import { Box, Avatar, Typography, Chip, CircularProgress } from "@mui/material";
import CustomButton from "../../../components/ui/CustomButton";
import BreadcrumComp from "../../../components/ui/BreadcrumComp";
import UserCard from "../../../components/profileComponents/UserCard";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import infosIcon from "../../../imgs/attentionIcon.svg";
import collisIcon from "../../../imgs/collisIcon.svg";
import expIcon from "../../../imgs/expeditionIcon.svg";
import walletIcon from "../../../imgs/walletIcon.svg";
import { ToastContainer } from "react-toastify";
import travelIcon from "../../../imgs/travel.svg";
import UserInfos from "../../../components/profileComponents/UserInfos";
import Wallet from "./Wallet";
import Collis from "./Collis";
import { useTranslation } from "react-i18next";
import Expeditions from "./Expeditions";
import Voyages from "./Voyages";
import WithdrawRequests from "./WithdrawRequests";
function UserDetails() {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const content = [t("buttons.informations"), t("buttons.wallet"), t("buttons.colis"), t("buttons.travel"), t("buttons.expeditions"), t("sharedLayout.navigations.withdraw_requests")];
  const [selectedContent, setSelectedContent] = useState(content[0]);
  const [updateInfos, setUpdateInfos] = useState(false);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const contentSelector = (content) => {
    switch (content) {
      case t("buttons.informations"):
        return <UserInfos userData={userData} />;
      case t("buttons.wallet"):
        return <Wallet />;
      case t("buttons.colis"):
        return <Collis />;
      case t("buttons.travel"):
        return <Voyages />;
      case t("buttons.expeditions"):
        return <Expeditions />;
      case t("sharedLayout.navigations.withdraw_requests"):
        return <WithdrawRequests />;
      default:
        return <UserInfos userData={userData} />;
    }
  };
  const icons = {
    Informations: infosIcon,
    Expéditions: expIcon,
    Colis: collisIcon,
    wallet: walletIcon,
    Voyages: travelIcon,
    Information: infosIcon,
    Shipments: expIcon,
    Package: collisIcon,
    Wallet: walletIcon,
    Trips: travelIcon,
  };
  useEffect(() => {
    Api.get(Urls?.GetUser(id), {
      headers: { "accept-language": localStorage.getItem("i18nextLng"), "content-type": "application", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setUserData(res?.data?.data);
        setLoading(false);
        console.log(res?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [updateInfos]);

  return (
    <>
      {!loading && (
        <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
          <ToastContainer />
          <Box>
            <BreadcrumComp first={t("sharedLayout.navigations.users")} second={userData?.profile?.first_name + " " + userData?.profile?.last_name} />
          </Box>
          {/* content selector */}
          <Box display="flex" alignItems="center" flexWrap="wrap">
            {content.map((c, index) => (
              <CustomButton key={index} active={selectedContent === c ? true : false} onClick={() => setSelectedContent(c)} variant={selectedContent === c ? "primary" : "primary_inactive"} text={c} startIcon={icons[c]} />
            ))}
          </Box>
          <UserCard updateInfos={updateInfos} setUpdateInfos={setUpdateInfos} userData={userData} />

          {/* content */}
          {contentSelector(selectedContent)}
        </Box>
      )}
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

export default UserDetails;
